define('msa/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].RESTSerializer.extend({
        primaryKey: '_id',
        modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
            //console.log(1, payloadKey);
            if (payloadKey === 'tta') {
                return 'tta';
            }
            if (payloadKey === 'ttagroup') {
                return 'ttaGroup';
            }
            if (payloadKey === 'commonAssists') {
                return 'common-assist';
            }
            if (payloadKey === 'playeramplua') {
                return 'playerAmplua';
            }
            if (payloadKey === 'seasontype') {
                return 'seasonType';
            }
            if (payloadKey === 'playertta') {
                return 'playerTTA';
            }
            if (payloadKey === 'refereetta') {
                return 'refereeTTA';
            }
            if (payloadKey === 'refereettagroup') {
                return 'refereeTtaGroup';
            }
            if (payloadKey === 'refereettaoverview') {
                return 'refereeTtaOverview';
            }
            if (payloadKey === 'playerttagroup') {
                return 'playerTtaGroup';
            }
            if (payloadKey === 'teamtta') {
                return 'teamTta';
            }
            if (payloadKey === 'teamttagroup') {
                return 'teamTtaGroup';
            }

            return this._super(payloadKey);
        },
        serializeAttribute: function serializeAttribute(record, json, key, attribute) {
            if (!attribute.options.readOnly) {
                return this._super(record, json, key, attribute);
            }
        }
    });
});