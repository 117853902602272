define('msa/components/compare-attributes', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        maxWidthTable: { 4: 25.4, 6: 31, 7: 34, 8: 36, 9: 40, 10: 44, 5: 27, 3: 20, 2: 18, 1: 17 },
        width_rm: _ember['default'].computed('rm', 'mrm', function () {
            var minOffset = 19;
            var offsetOnChar = 2.2;
            var suffix = this.get('suffix') || '';
            var r = Math.abs(Math.round(this.get('rm') * 10) / 10) || 0;
            r += suffix;
            var minWidth = 100;
            var maxWidth = this.get('maxWidthTable')[r.toString().length]; // 14max
            // 100 - 26
            var realPerc = this.get('rm') * 100 / this.get('mrm');
            var oneOffsetPerc = (minWidth - maxWidth) / 100;
            var diff = oneOffsetPerc * realPerc; // 100 - 25 / 100 * ()
            var width = Math.abs(100 - diff) || 100; //width must be more than max width
            return _ember['default'].String.htmlSafe("width: " + width + "%");
        }),
        //16 end with 1
        //26 with 4
        //28 with 5
        /*
            l 2463 25.4 88.20921747479596 11.790782525204037 // tw - 26
        */
        width_lm: _ember['default'].computed('lm', 'mlm', function () {
            var minOffset = 19;
            var offsetOnChar = 2.2;
            var suffix = this.get('suffix') || '';
            var l = Math.abs(Math.round(this.get('lm') * 10) / 10) || 0;
            l += suffix;
            var minWidth = 100;
            var maxWidth = this.get('maxWidthTable')[l.toString().length]; // 14max
            var realPerc = this.get('lm') * 100 / this.get('mlm');
            var oneOffsetPerc = (minWidth - maxWidth) / 100;
            var diff = oneOffsetPerc * realPerc; // 100 - 25 / 100 * ()
            var width = Math.abs(100 - diff) || 100;
            return _ember['default'].String.htmlSafe("width: " + width + "%");
        }),

        didInsertElement: function didInsertElement() {
            var suffix = this.get('suffix') || '';
            var r = Math.abs(Math.round(this.get('rm') * 10) / 10) || 0;
            var l = Math.abs(Math.round(this.get('lm') * 10) / 10) || 0;
            r += suffix;
            l += suffix;
            this.set('r', r);
            this.set('l', l);
            console.log("this.get('rm')", this.get('rm'));
            console.log("this.get('lm')", this.get('lm'));
            if (this.get('rm') > this.get('lm')) {
                this.set('rightMore', true);
            } else {
                this.set('rightMore', false);
            }
        }
    });
});