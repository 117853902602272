define('msa/controllers/players/compare', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Controller.extend({
        //наблюдаем за sqads
        currentTab: 'overview',

        chartParams: null,

        overviewAttributes: _ember['default'].A([{ name: "Forward", active: true }, { name: "Right forward", active: true }, { name: "Right", active: true }, { name: "Right back", active: true }, { name: "Back", active: true }, { name: "Left back", active: true }, { name: "Left", active: true }, { name: "Left forward", active: true }]),

        compareTabList: _ember['default'].observer('ttaToSearch.[]', function () {
            this.send('getsAttributes');
        }),
        //
        //#3e6e62

        seasons: _ember['default'].A([{
            name: 2018,
            startDate: "2018-01-01T00:00:00.000Z",
            endDate: "2018-12-31T00:00:00.000Z"
        }, {
            name: 2019,
            startDate: "2019-01-01T00:00:00.000Z",
            endDate: "2019-12-31T00:00:00.000Z"
        }, {
            name: 2020,
            startDate: "2020-01-01T00:00:00.000Z",
            endDate: "2020-12-31T00:00:00.000Z"
        }]),

        ajax: _ember['default'].inject.service(),

        actions: {
            changeCurrentTab: function changeCurrentTab(tabname) {
                this.set('currentTab', tabname);
                this.send('findDataByDate');
            },
            changeRightSeason: function changeRightSeason(m) {
                var that = this;
                $('.right-season').removeClass('active');
                $('.right-season' + m.name).addClass('active');
                this.set('rightStartDate', m.startDate);
                this.set('rightEndDate', m.endDate);
                this.set('rightStart', '');
                this.set('rightEnd', '');
                this.send('findDataByDate');
            },

            changeLeftSeason: function changeLeftSeason(m) {
                var that = this;
                $('.left-season').removeClass('active');
                $('.left-season' + m.name).addClass('active');
                this.set('leftStartDate', m.startDate);
                this.set('leftEndDate', m.endDate);
                this.set('leftStart', '');
                this.set('leftEnd', '');
                this.send('findDataByDate');
            },

            findCustomRightDates: function findCustomRightDates() {
                $('.right-season').removeClass('active');
                var s = moment(this.get('rightStart')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(this.get('rightEnd')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                this.set('rightStartDate', s);
                this.set('rightEndDate', e);
                this.send('findDataByDate');
            },

            findCustomLeftDates: function findCustomLeftDates() {
                $('.left-season').removeClass('active');
                var s = moment(this.get('leftStart')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(this.get('leftEnd')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                this.set('leftStartDate', s);
                this.set('leftEndDate', e);
                this.send('findDataByDate');
            },

            findDataByDate: function findDataByDate() {
                var currentTab = this.get('currentTab');
                switch (currentTab) {
                    case 'statistics':
                        this.send('getsAttributes');
                        break;
                    case 'positions':
                        this.send('getPositions', 'rm');
                        this.send('getPositions', 'lm');
                        break;
                    case 'overview':
                        this.send('getOverview');
                        break;
                    default:
                        break;
                }
            },

            addRight: function addRight(m) {
                var currentTab = this.get('currentTab');
                this.set('rm', m);

                switch (currentTab) {
                    case 'statistics':
                        this.send('getsAttributes');
                        break;
                    case 'positions':
                        this.send('getPositions', 'rm');
                        break;
                    case 'overview':
                        this.send('getOverview');
                        break;
                    default:
                        break;
                }
            },

            addLeft: function addLeft(m) {
                var currentTab = this.get('currentTab');
                this.set('lm', m);
                switch (currentTab) {
                    case 'statistics':
                        this.send('getsAttributes');
                        break;
                    case 'positions':
                        this.send('getPositions', 'lm');
                        break;
                    case 'overview':
                        this.send('getOverview');
                        break;
                    default:
                        break;
                }
            },

            getOverview: function getOverview() {
                var that = this;
                var rm = this.get('rm.id');
                var lm = this.get('lm.id');
                var overviewAttributes = this.get('overviewAttributes');
                var labesls = [];
                var excluded = [];
                var lq = '&startDate=' + that.get('leftStartDate') + '&endDate=' + that.get('leftEndDate');
                var rq = '&startDate=' + that.get('rightStartDate') + '&endDate=' + that.get('rightEndDate');
                overviewAttributes.forEach(function (item, index) {
                    if (item.active) {
                        labesls.push(item.name);
                    } else {
                        excluded.push(index);
                    }
                });

                if (!rm || !lm) return;

                that.get('ajax').request('/playerRadar?player=' + lm + lq).then(function (lmResult) {
                    that.get('ajax').request('/playerRadar?player=' + rm + rq).then(function (rmResult) {
                        excluded.forEach(function (item) {
                            lmResult.splice(item, 1);
                            rmResult.splice(item, 1);
                        });
                        var chartData = {
                            labels: labesls,
                            datasets: [{
                                borderColor: "#b9cb69",
                                pointRadius: 0,
                                data: lmResult,
                                fill: false
                            }, {
                                borderColor: "#3e6e62",
                                pointRadius: 0,
                                data: rmResult,
                                fill: false
                            }]
                        };
                        var chartParams = {
                            type: "radar",
                            data: chartData,
                            options: {
                                scale: {
                                    ticks: {
                                        display: false
                                    }
                                },
                                legend: { display: false },
                                responsive: false,
                                width: 500,
                                height: 500
                            }
                        };
                        that.set('chartParams', chartParams);
                    });
                });
            },
            getPositions: function getPositions(m) {
                var that = this;
                var rm = this.get(m + '.id');
                var q = '';
                if (m == 'rm') {
                    q = '&startDate=' + that.get('rightStartDate') + '&endDate=' + that.get('rightEndDate');
                } else {
                    q = '&startDate=' + that.get('leftStartDate') + '&endDate=' + that.get('leftEndDate');
                }
                if (!rm) return;
                $('.positionsLoading' + m).removeClass('none');
                that.get('ajax').request('/fieldPlayerPositions?player=' + rm + q).then(function (rmp) {
                    var arr = _ember['default'].A();
                    var r = rmp[0];
                    if (!r) {
                        that.set(m + 'Pos', []);
                    } else {
                        //that.set('noData.positions', null);
                        for (var ps = 0; ps < r.positions.length; ps++) {
                            var name = r.positions[ps].amplua.name === 'Goalkeeper' ? r.positions[ps].amplua.name : r.positions[ps].position.name + ' ' + r.positions[ps].amplua.name;
                            arr.pushObject({
                                name: name,
                                total: r.positions[ps].total,
                                x: r.positions[ps].pos.amp * 100 / (r.matrix.ampluas - 1),
                                y: r.positions[ps].pos.pos * 100 / (r.matrix.positions - 1)
                            });
                        }
                        that.set(m + 'Pos', arr);
                    }

                    $('.positionsLoading' + m).addClass('none');
                });
            },

            addTta: function addTta(t) {
                var l = this.get('ttaToSearch');
                if (l.includes(t)) {
                    l.removeObject(t);
                    t.set('inQuery', false);
                } else {
                    l.addObject(t);
                    t.set('inQuery', true);
                }
            },

            addOverviewAttribute: function addOverviewAttribute(oA) {
                var overviewAttributes = this.get('overviewAttributes');
                var elemIndex = overviewAttributes.indexOf(oA);
                if (oA.active) {
                    _ember['default'].set(overviewAttributes[elemIndex], 'active', false);
                } else {
                    _ember['default'].set(overviewAttributes[elemIndex], 'active', true);
                }
                this.set('overviewAttributes', overviewAttributes);
                this.send('getOverview');
            },

            //получаем Performance
            getsAttributes: function getsAttributes() {
                $('.squadLoading').removeClass('none');
                var that = this;
                var rm = this.get('rm.id');
                var lm = this.get('lm.id');

                if (!rm || !lm) return;

                var arr = this.get('ttaToSearch').mapBy('id');
                var lq = '&startDate=' + that.get('leftStartDate') + '&endDate=' + that.get('leftEndDate');
                var rq = '&startDate=' + that.get('rightStartDate') + '&endDate=' + that.get('rightEndDate');

                for (var i = 0; i < arr.length; i++) {
                    lq += '&playerTTA=' + arr[i];
                    rq += '&playerTTA=' + arr[i];
                }

                that.get('ajax').request('/playerPerformancev2?player=' + rm + rq).then(function (rmo) {
                    that.get('ajax').request('/playerPerformancev2?player=' + lm + lq).then(function (lmo) {
                        var actions = [];
                        var iteratedArray = null;
                        var searchedArray = null;
                        var iteratedType = null;
                        if (rmo.length > lmo.length) {
                            iteratedArray = rmo;
                            searchedArray = lmo;
                            iteratedType = 'rmo';
                        } else {
                            iteratedArray = lmo;
                            searchedArray = rmo;
                            iteratedType = 'lmo';
                        }
                        var emptyElement = {
                            playerTTA: {
                                has_success: false,
                                has_accurate: false,
                                has_won: false,
                                has_on_target: false
                            },
                            actions: 0,
                            success_actions: 0,
                            max_actions: 0,
                            max_success_actions: 0,
                            league_average: 0,
                            success_average: 0,
                            average: 0,
                            max_average: 0
                        };

                        var _loop = function (i) {
                            var iteratedItem = iteratedArray[i];
                            var fittedElement = _npmLodash['default'].find(searchedArray, function (item) {
                                return item.playerTTA.plural == iteratedItem.playerTTA.plural;
                            });
                            if (typeof fittedElement == 'undefined') {
                                fittedElement = emptyElement;
                                fittedElement.playerTTA.plural = iteratedItem.playerTTA.plural;
                            }
                            var newAction = { name: iteratedItem.playerTTA.plural };
                            if (iteratedType == 'lmo') {
                                newAction.lmo = iteratedItem;
                                newAction.rmo = fittedElement;
                            } else {
                                newAction.rmo = iteratedItem;
                                newAction.lmo = fittedElement;
                            }
                            if (newAction.rmo.success_actions) {
                                newAction.rmo.success_actions_calculated = (newAction.rmo.success_actions || 0) / (newAction.rmo.actions || 1) * 100;
                            }
                            if (newAction.lmo.success_actions) {
                                newAction.lmo.success_actions_calculated = (newAction.lmo.success_actions || 0) / (newAction.lmo.actions || 1) * 100;
                            }
                            newAction.showPlayerSucess = newAction.rmo.success_actions_calculated && newAction.rmo.success_actions_calculated > 0 || newAction.lmo.success_actions_calculated && newAction.lmo.success_actions_calculated > 0 ? true : false;
                            newAction.showPlayerAverageSucess = newAction.rmo.success_average && newAction.rmo.success_average > 0 || newAction.lmo.success_average && newAction.lmo.success_average > 0 ? true : false;
                            actions.push(newAction);
                        };

                        for (var i = 0; i < iteratedArray.length; i++) {
                            _loop(i);
                        }
                        that.set('playerActions', actions);
                        $('.squadLoading').addClass('none');
                    });
                });
            }
        }
    });
});