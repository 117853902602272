define('msa/controllers/post-match-report/attacks/with-shots', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),
        actions: {
            openAttackPlayer: function openAttackPlayer(actionId) {
                this.set('playingActionId', actionId);
                this.set('showVideoPlayer', true);
            }
        }
    });
});