define('msa/controllers/login', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service(),
        currentUser: _ember['default'].inject.service(),

        actions: {
            authenticate: function authenticate(email, password, cbl) {
                console.log('got action');
                var that = this;
                this.get('session').authenticate('authenticator:oauth2', email, password).then(function () {
                    that.get('currentUser').load().then(function () {
                        var user = that.get('currentUser.user');
                        console.log('user_info1', user.get('home_competition_page'));
                        if (user.get('avatar')) {
                            that.transitionToRoute('players.show.overview', user.get('avatar'));
                        } else if (user.get('home_competition_page')) {
                            that.transitionToRoute('leagues.show.overview', user.get('home_competition_page'));
                        } else {
                            that.transitionToRoute('index');
                        }
                    });
                })['catch'](function (reason) {

                    console.log('catch error messa');
                    cbl(reason.message);
                    _ember['default'].run.later(function () {
                        cbl('');
                    }, 5000);
                });
            }
        }
    });
});