define('msa/routes/post-match-report/attacks', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            return this.store.findRecord('game', params.m_id);
        },

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', model);
            var teamTtas = ["Yellow cards", "Red cards"];
            var homeTeamId = model.get('home_team.id');
            var awayTeamId = model.get('away_team.id');
            that.get('ajax').request('/stadium/' + model.get("stadium.id")).then(function (stadium) {
                that.store.findAll('teamTTA').then(function (pTtas) {
                    var filteredPlayersTtas = pTtas.filter(function (item) {
                        return teamTtas.indexOf(item.get('plural')) > -1;
                    });
                    var ttaPlayersListQuery = '';
                    filteredPlayersTtas.forEach(function (tta, index) {
                        ttaPlayersListQuery += '&teamTTA=' + tta.get('id');
                    });
                    var homePlayersListQuery = ttaPlayersListQuery + '&team=' + homeTeamId;
                    that.get('ajax').request('/gameReportActions?game=' + model.get('id') + homePlayersListQuery).then(function (json) {
                        var groupedByTeam = _npmLodash['default'].groupBy(json, 'team');
                        if (groupedByTeam[homeTeamId]) {
                            var homeCardsData = that.get('postMatchService').preparePlayerCardsList(groupedByTeam[homeTeamId]);
                            controller.set('homePlayerCardsData', homeCardsData);
                        }
                        if (groupedByTeam[awayTeamId]) {
                            var awayCardsData = that.get('postMatchService').preparePlayerCardsList(groupedByTeam[awayTeamId]);
                            controller.set('awayPlayerCardsData', awayCardsData);
                        }
                    });
                });
            });
        }
    });
});