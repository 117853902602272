define('msa/controllers/teams/show/squad', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        getSq: _ember['default'].observer('endDate', 'model.id', 'currentCompetition', function () {
            this.send('getFormation');
        }),

        ajax: _ember['default'].inject.service(),
        teamService: _ember['default'].inject.service(),
        ctrl: _ember['default'].inject.controller('teams.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),
        formation: [],
        currentCompetition: _ember['default'].computed.alias('ctrl.currentCompetition'),

        actions: {
            getFormation: function getFormation() {
                var q = {
                    team: this.get("model.id"),
                    startDate: this.get('startDate'),
                    endDate: this.get('endDate')
                };
                var c = this.get('currentCompetition');
                if (!q.startDate || !q.endDate || !c) return; //отмена если одно из значений остутствует
                $('.getFormationLoading').removeClass('none');

                var qStr = '?team=' + q.team + '&startDate=' + q.startDate + '&endDate=' + q.endDate;
                if (c.get("name") !== 'OVERALL') qStr += '&competition=' + q.competition;

                var that = this;
                that.get('ajax').request('/teamFormation' + qStr).then(function (json) {
                    var arr = that.get('teamService').prepareSquadData(json);
                    that.set('formation', arr);
                    $('.getFormationLoading').addClass('none');
                });
            }
        }
    });
});