define('msa/models/game', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		home_team: _emberData['default'].belongsTo('team', { async: true }),
		competition: _emberData['default'].belongsTo('competition', { async: true }),
		stadium: _emberData['default'].belongsTo('stadium', { async: true }),
		away_team: _emberData['default'].belongsTo('team', { async: true }),
		date: _emberData['default'].attr('string'),
		team_away_score: _emberData['default'].attr('string'),
		//team_away_number_color: DS.attr('string'),
		//round_number: DS.attr('string'),
		team_home_score: _emberData['default'].attr('string'),
		//team_home_number_color: DS.attr('string'),
		//team_home_shirt_color: DS.attr('string'),
		formatDate: (function () {
			return moment(this.get('date')).format('L');
		}).property('date')
	});
});