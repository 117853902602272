define('msa/routes/select-player', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        currentUser: service(),
        ajax: service(),

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', width: '25%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', query: 'country', width: '25%', title: 'select league', step: 2 }), _ember['default'].Object.create({ model: 'team', query: 'competition', width: '25%', title: 'select team', step: 3 }), _ember['default'].Object.create({ model: 'player', query: 'team', width: '25%', title: 'select player', step: 4 })]);
        },

        setupController: function setupController(controller, model) {
            controller.set('st', model);
            var step = !!controller.get('step') ? controller.get('step') : 1;
            controller.setProperties({
                step: 1,
                country: false,
                team: false,
                competition: false
            });
        },

        actions: {
            renderStep: function renderStep(modelName, query) {
                var that = this;
                if (!query) {
                    that.get('ajax').request('/selectCountry').then(function (json) {
                        that.render('outlets/select/country', {
                            into: 'select-player',
                            outlet: 'steps',
                            model: json.country
                        });
                    });
                    return true;
                }

                if (modelName === 'competition') {
                    that.get('ajax').request('/selectCompetition?country=' + query.country).then(function (json) {
                        console.log(json);
                        that.render('outlets/select/competition', {
                            into: 'select-player',
                            outlet: 'steps',
                            model: json.team
                        });
                    });
                    return true;
                }

                this.render('outlets/select/' + modelName, {
                    into: 'select-player',
                    outlet: 'steps',
                    model: query ? that.store.query(modelName, query) : that.store.findAll('country'),
                    controller: 'outlets/select/' + modelName
                });
            },

            setModel: function setModel(mn, m) {
                var controller = this.controllerFor('select-player');
                controller.set(mn, m);
                this.send('nextSteps');
            },

            nextSteps: function nextSteps() {
                var controller = this.controllerFor('select-player');
                var step = controller.get('step');
                controller.set('step', ++step);
                var steps = controller.get('st');
                if (step >= steps.length + 1) {
                    this.transitionTo('players.show', controller.get('player'));
                } else if (step === 1) {
                    this.send('renderStep', 'country', false);
                } else {
                    var newModelName = steps[step - 1]['model'];
                    var query = {};
                    var model = controller.get(steps[step - 2]['model']);
                    query[steps[step - 1]['query']] = model._id ? model._id : model.get('id');
                    this.send('renderStep', newModelName, query);
                }
            }
        }

    });
});

//import LoadingIndicator       from 'uti-ui/mixins/loading-indicator';