define('msa/controllers/teams/show', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),

        //добавлена ли комнда в избранные
        isFavorite: _ember['default'].computed('currentUser.user.fav_teams.@each', 'model', function () {
            if (this.get('currentUser.user.fav_teams').includes(this.get('model'))) return true;
        }),

        actions: {

            /*//добавляем к сравнениям
            addToCompare() {
                this.get('currentUser.user.fav_teams').removeObject(this.get('model'));
                this.get('currentUser.user.fav_teams').pushObject(this.get('model'));
                this.transitionToRoute('teams.compare');
            },*/

            //меняем сезон
            changeSeason: function changeSeason(m) {
                var that = this;
                var all = this.get('all');
                m.get('competitions').pushObject(all);
                this.set('currentSeason', m);
                this.send('getStatistica');
                this.set('currentCompetition', all);
                this.set('start', false);
                this.set('end', false);
                this.set('startDate', m.get('startDate'));
                this.set('endDate', m.get('endDate'));

                $('.currentSeasonId').addClass('active');
                $('.datasLoadingsp').removeClass('none');
                this.send('getStatistica');
            },

            //получаем сезонную статистику
            getStatistica: function getStatistica() {
                $('.statisticLoading').removeClass('none');
                var that = this;
                var q = '?team=' + that.get('model.id') + '&startDate=' + that.get('currentSeason.startDate') + '&endDate=' + that.get('currentSeason.endDate');
                if (that.get("currentCompetition.name") !== 'OVERALL') q += '&competition=' + that.get("currentCompetition.id");
                that.get('ajax').request('/seasonStat' + q).then(function (json) {
                    json.seasonStat.possession = (json.seasonStat.possession * 100).toFixed(1);
                    that.set('statistic', json.seasonStat);
                    $('.statisticLoading').addClass('none');
                });
            },

            findCustomDates: function findCustomDates() {
                $('.currentSeasonId').removeClass('active');
                var s = moment(this.get('start')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(this.get('end')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                this.set('startDate', s);
                this.set('endDate', e);
            },

            queryData: function queryData(l) {
                this.set('currentCompetition', l);
                this.send('getStatistica');
            }

        }
    });
});