define('msa/routes/reports/game', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        beforeModel: function beforeModel(transition) {
            //if(!("countryId" in transition.queryParams)) {
            //    console.log("no property");
            //}
            var that = this;
            that.set('playersIds', transition.queryParams.playerId.split(','));
            that.set('teamId', transition.queryParams.teamId);
        },

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', t: 'country', width: '16.6%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', t: 'competition', query: 'country', width: '16.6%', title: 'select league', step: 2 }), _ember['default'].Object.create({ model: 'team', t: 'team', query: 'competition', width: '16.6%', title: 'select team', step: 3 }), _ember['default'].Object.create({ model: 'players', t: 'chose-player', query: 'team', width: '16.6%', title: 'select player', step: 4 }), _ember['default'].Object.create({ model: 'games', t: 'game', query: 'player', width: '16.6%', title: 'select games', step: 5 }), _ember['default'].Object.create({ model: '', t: 'episode', query: '', width: '16.6%', title: 'select episodes', step: 6 })]);
        },

        setupController: function setupController(controller, model) {
            controller.set('definedSteps', model);
            var that = this;
            var teamId = this.get('teamId');
            var playersIds = that.get('playersIds');
            that.get('ajax').request('/reportPlayers?teams=' + teamId).then(function (playersData) {
                var players = playersData.players.filter(function (player) {
                    return playersIds.indexOf(player._id) !== -1;
                });
                controller.set('players', players);
                that.get('ajax').request('/team/' + teamId).then(function (teamData) {
                    controller.set('team', teamData.team);
                    that.get('ajax').request('/competition/' + teamData.team.competition).then(function (competitionData) {
                        controller.set('competition', competitionData.competition);
                        that.get('ajax').request('/country/' + competitionData.competition.country).then(function (countryData) {
                            controller.set('country', countryData.country);
                        });
                    });
                });
            });
            controller.setProperties({
                step: 5,
                team: false,
                country: false,
                competition: false,
                games: _ember['default'].A(),
                players: _ember['default'].A()
            });
        },

        actions: {
            renderStep: function renderStep(modelName, q, t) {
                var that = this;
                var teamId = that.get('teamId');
                var controller = that.controllerFor('reports/game');
                var players = that.get('playersIds');
                var query = '?teams=' + teamId;
                for (var i = 0; i < players.length; i++) {
                    query += '&players=' + players[i];
                }
                that.get('ajax').request('/reportGames' + query).then(function (json) {
                    that.render('outlets/select/game', {
                        into: 'reports/game',
                        outlet: 'steps',
                        model: json.games,
                        controller: 'outlets/select/game'
                    });
                });
                return true;
            },

            setModelG: function setModelG(m, modelName) {
                var that = this;
                var controller = this.controllerFor('reports/game');
                if (controller.get(modelName).includes(m)) {
                    controller.get(modelName).removeObject(m);
                } else {
                    controller.get(modelName).addObject(m);
                }
            },
            goToEpisodesStep: function goToEpisodesStep() {
                var controller = this.controllerFor('reports/game');
                var playersIds = this.get('playersIds');
                var games = controller.get('games');
                var gamesIds = [];
                games.forEach(function (item) {
                    gamesIds.push(item._id);
                });
                this.controllerFor("reports/game").transitionToRoute('reports.episode', { queryParams: { teamId: controller.get('teamId'), playersIds: playersIds, gamesIds: gamesIds } });
            },
            backStep: function backStep(step) {
                var controller = this.controllerFor('reports/game');
                var country = controller.get('country');
                var team = controller.get('team');
                var competition = controller.get('competition');
                if (step.model === "country") {
                    controller.transitionToRoute('reports.country');
                } else if (step.model === "competition") {
                    controller.transitionToRoute('reports.competition', { queryParams: { countryId: country._id } });
                } else if (step.model === "team") {
                    controller.transitionToRoute('reports.team', { queryParams: { competitionId: competition._id } });
                } else if (step.model === "players") {
                    controller.transitionToRoute('reports.player', { queryParams: { teamId: team._id } });
                }
            }

        }

    });
});