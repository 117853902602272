define('msa/models/team', ['exports', 'ember-data', 'msa/libs/type-shims'], function (exports, _emberData, _msaLibsTypeShims) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _msaLibsTypeShims.string,
		name_short: _msaLibsTypeShims.string,
		shirt_color: _msaLibsTypeShims.string,
		number_color: _msaLibsTypeShims.string,
		logo: _msaLibsTypeShims.string,

		country: (0, _msaLibsTypeShims.belongsTo)('country', { async: true }),
		competition: (0, _msaLibsTypeShims.belongsTo)('competition', { async: true })

	});
});