define('msa/app', ['exports', 'ember', 'msa/resolver', 'ember-load-initializers', 'msa/config/environment'], function (exports, _ember, _msaResolver, _emberLoadInitializers, _msaConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _msaConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _msaConfigEnvironment['default'].podModulePrefix,
    Resolver: _msaResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _msaConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});