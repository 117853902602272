define('msa/routes/teams/show/dynamics', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', model);
            that.store.findAll('teamTta').then(function (ttas) {
                var newObj = _ember['default'].A();
                ttas.forEach(function (tta, index) {
                    ttas.objectAt(index).set('inQuery', false);
                });
                ttas = ttas.filterBy('visible', true).sortBy('plural');
                controller.set('tta', ttas);
                for (var i = 0; i < 8; i++) {
                    ttas.objectAt(i).set('inQuery', true);
                }
                var teamTTAs = [5, 2, 1, 39, 36];
                var objects = [];
                ttas.forEach(function (obj) {
                    if (teamTTAs.includes(obj.data.orderIndex)) objects.push(obj);
                });
                newObj.pushObjects(objects);
                controller.set('ttaToSearch', newObj);
                controller.set('rm', model.get('fav_teams.firstObject'));
                controller.set('lm', model.get('fav_teams.lastObject'));
                controller.set('rightStartDate', "2020-01-01T00:00:00.000Z");
                controller.set('rightEndDate', "2020-12-31T00:00:00.000Z");
                controller.set('leftStartDate', "2020-01-01T00:00:00.000Z");
                controller.set('leftEndDate', "2020-12-31T00:00:00.000Z");
            });
        }
    });
});