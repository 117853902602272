define('msa/controllers/players/show', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),

        isFavorite: _ember['default'].computed('currentUser.user.fav_players.@each', 'model', function () {
            if (this.get('currentUser.user.fav_players').includes(this.get('model'))) return true;
        }),

        passesSelect: _ember['default'].A(['Passes received', 'Passes made']),

        //следим за датами
        getCustomDates: _ember['default'].observer('endDate', function () {
            this.send('getStat');
            this.send('getRight');
            this.send('getRadar');
        }),

        //следим за типами пасов
        getrightType: _ember['default'].observer('rightType', function () {
            this.send('getRight');
        }),

        rightType: 'Passes received',

        actions: {

            addToCompare: function addToCompare() {
                this.get('currentUser.user.fav_players').removeObject(this.get('model'));
                this.get('currentUser.user.fav_players').pushObject(this.get('model'));
                this.transitionToRoute('players.compare');
            },

            changeSeason: function changeSeason(m) {
                var that = this;
                this.set('currentSeason', m);
                this.set('start', false);
                this.set('end', false);
                this.set('startDate', m.startDate);
                this.set('endDate', m.endDate);
                $('.currentSeasonId').addClass('active');
            },

            setPlayerInRightSide: function setPlayerInRightSide(m) {
                this.set('currentPlayer', m);
            },

            findCustomDates: function findCustomDates() {
                var that = this;
                $('.currentSeasonId').removeClass('active');
                var s = moment(that.get('start')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(that.get('end')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                that.set('startDate', s);
                that.set('endDate', e);
            },

            getRadar: function getRadar() {
                var that = this;
                that.get('ajax').request('/playerRadar?player=' + that.get('model.id') + '&startDate=' + that.get('startDate') + '&endDate=' + that.get('endDate')).then(function (result) {
                    var chartData = {
                        labels: ["Forward", " ", "Right", " ", "Back", " ", "Left", " "],
                        datasets: [{
                            backgroundColor: '#b2c93a',
                            borderColor: '#b2c93a',
                            pointRadius: 0,
                            data: result,
                            fill: true
                        }]
                    };
                    var chartParams = {
                        type: "radar",
                        data: chartData,
                        options: {
                            legend: { display: false },
                            scale: {
                                ticks: {
                                    display: false
                                }
                            }
                        }
                    };
                    that.set('chartParams', chartParams);
                });
            },

            getStat: function getStat() {
                $('.getStatLoading').removeClass('none');

                var that = this,
                    qo = {
                    player: that.get('model.id'),
                    startDate: that.get('startDate'),
                    endDate: that.get('endDate')
                };

                if (!qo.player || !qo.startDate || !qo.endDate) return;
                var q = '?player=' + qo.player + '&startDate=' + qo.startDate + '&endDate=' + qo.endDate;

                that.get('ajax').request('/playerStatisticsv2' + q).then(function (json) {
                    console.log(json);
                    that.set('statistic', {
                        matches: json.matches,
                        goals: json.goals,
                        goalsP: Math.floor(json.averageGoals * 100) / 100,
                        red: json.redCards,
                        yellow: json.yellowCards,
                        shotsP: Math.floor(json.averageShots * 100) / 100,
                        minutes: Math.floor(json.playTime / 60000),
                        crossesP: Math.floor(json.averageCrosses * 100) / 100,
                        challengesP: Math.floor(json.averageChallenges * 100) / 100,
                        keyP: Math.floor(json.averageKeyPasses * 100) / 100,
                        challengesPr: Math.floor(json.challengesSuccess * 100),
                        passesP: Math.floor(json.averagePasses * 100) / 100,
                        passesPr: Math.floor(json.passesSuccess * 100),
                        dribblesP: Math.floor(json.averageDribbles * 100) / 100,
                        tacklesP: Math.floor(json.averageTackles * 100) / 100,
                        plusMinus: json.plus - json.minus
                    });
                    $('.getStatLoading').addClass('none');
                });
            },

            getRight: function getRight() {
                var that = this,
                    q = this.get('rightType'),
                    qv;
                if (!q) return;
                if (q === 'Passes received') qv = 'fromPartners';
                if (q === 'Passes made') qv = 'toPartners';
                that.get('ajax').request('/' + qv + '?player=' + that.get('model.id') + '&startDate=' + that.get('startDate') + '&endDate=' + that.get('endDate')).then(function (json) {
                    var arr = [];
                    for (var i = 0; i < json.length; i++) {

                        var five = 0,
                            total = 0;

                        for (var b = 0; b < json[i].values.length; b++) {
                            if (b < 5) five += json[i].values[b];
                            total += json[i].values[b];
                        }
                        arr.push({
                            _id: json[i]._id._id,
                            name: json[i]._id.first_name + ' ' + json[i]._id.last_name,
                            photo: json[i]._id.photo,
                            last: json[i].values[0] || 0,
                            five: five,
                            total: total,
                            avg: (total / json[i].values.length).toFixed(2)
                        });
                    }

                    that.set('rightPasses', arr);
                    that.set('currentPlayer', arr[0]);
                });
            }
        }
    });
});