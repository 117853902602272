define('msa/initializers/inject-config', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    ['route', 'controller', 'component'].forEach(function (injectionTarget) {
      application.inject(injectionTarget, 'config', 'service:config');
    });
  }

  exports['default'] = {
    name: 'inject-config',
    initialize: initialize
  };
});