define('msa/components/table-td', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        attributeBindings: ['data-x', 'data-y', 'data-zone'],
        click: function click(event) {
            $(event.target).toggleClass('active');

            var zone = $(event.target).attr('data-zone');
            var arr = $.map($('*[data-zone=' + zone + '].active'), function (d) {
                return {
                    x: $(d).attr('data-x'),
                    y: $(d).attr('data-y')
                };
            });
            this.set('coords', arr);
        }
    });
});