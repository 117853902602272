define('msa/routes/league-statistics/country', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', width: '50%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', query: 'country', width: '50%', title: 'select league', step: 2 })]);
        },

        setupController: function setupController(controller, model) {
            controller.set('definedSteps', model);
            controller.setProperties({
                step: 1,
                country: false,
                competition: false,
                team: false
            });
        },

        actions: {
            renderStep: function renderStep(modelName, query, t) {
                var that = this;
                that.get('ajax').request('/selectCountry').then(function (json) {
                    that.render('outlets/select/country', {
                        into: 'league-statistics/country',
                        outlet: 'steps',
                        model: json.country
                    });
                });
                return true;
            },

            setModel: function setModel(mn, m) {
                this.controllerFor("league-statistics/country").transitionToRoute('league-statistics.competition', { queryParams: { countryId: m._id } });
            }

        }

    });
});