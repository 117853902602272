define('msa/components/js-carusel', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            var width = $('body').width(),
                s = 3;
            if (width < 1100) s = 2;
            if (width >= 1100) s = 3;
            if (width >= 1500) s = 4;
            if (width >= 1800) s = 5;
            if (width >= 2100) s = 6;
            _ember['default'].run.later(function () {
                $('.slick').not('.slick-initialized').slick({
                    infinite: true,
                    slidesToShow: s,
                    slidesToScroll: 1
                });
            }, 500);
        }
    });
});