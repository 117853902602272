define('msa/components/key-moments-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        alreadyRendered: false,
        didRender: function didRender() {
            console.log('didInsertElement');
            if (this.get('alreadyRendered')) {
                this.$().slick('unslick');
            }
            var that = this;
            that.$().slick({
                arrows: true,
                infinite: false,
                slidesToShow: 2
            });
            that.set('alreadyRendered', true);
        },
        actions: {
            openParentActionPlayer: function openParentActionPlayer(actionId) {
                this.sendAction('openAttackPlayer', actionId);
            }
        }
    });
});