define('msa/services/post-match-service', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Service.extend({
        prepareCountBarsData: function prepareCountBarsData(json, homeTeamId, awayTeamId) {
            var challengesActions = _ember['default'].A();
            json.forEach(function (item) {
                var homeTeamActions = _npmLodash['default'].find(item.row, { 'team': homeTeamId });
                var awayTeamActions = _npmLodash['default'].find(item.row, { 'team': awayTeamId });
                // currentTtaItem.data = [];
                /*if(!homeTeamActions){
                    homeTeamActions = {team: homeTeamId, actions: 0};
                }
                if(!awayTeamActions){
                    awayTeamActions = {team: awayTeamId, actions: 0};
                } */

                if (homeTeamActions || awayTeamActions) {
                    if (homeTeamActions && (homeTeamActions.actions || homeTeamActions.value) || awayTeamActions && (awayTeamActions.actions || awayTeamActions.value)) {
                        var homeSucessActions = homeTeamActions ? homeTeamActions.success_actions || 0 : 0;
                        var awaySucessActions = awayTeamActions ? awayTeamActions.success_actions || 0 : 0;
                        var homeActions = homeTeamActions ? homeTeamActions.actions || homeTeamActions.value : 0;
                        var awayActions = awayTeamActions ? awayTeamActions.actions || awayTeamActions.value : 0;
                        var totalActions = homeActions > awayActions ? homeActions : awayActions;

                        var homeActs = homeTeamActions && homeTeamActions.hasOwnProperty('acts') ? homeTeamActions.acts : [];
                        var homeSucessActs = homeTeamActions && homeTeamActions.hasOwnProperty('success_acts') ? homeTeamActions.success_acts : [];
                        var awayActs = awayTeamActions && awayTeamActions.hasOwnProperty('acts') ? awayTeamActions.acts : [];
                        var awaySucessActs = awayTeamActions && awayTeamActions.hasOwnProperty('success_acts') ? awayTeamActions.success_acts : [];
                        var hasHomeActs = homeActs.length > 0 ? true : false;
                        var hasHomeSucessActs = homeSucessActs.length > 0 ? true : false;
                        var hasAwayActs = awayActs.length > 0 ? true : false;
                        var hasAwaySucessActs = awaySucessActs.length > 0 ? true : false;

                        challengesActions.push({
                            homeTeamActions: homeActions,
                            homeSucessActions: homeSucessActions,
                            awaySucessActions: awaySucessActions,
                            awayTeamActions: awayActions,
                            maxTotal: totalActions,
                            ttaName: item.teamTTA.plural,
                            homeActs: homeActs,
                            homeSucessActs: homeSucessActs,
                            awayActs: awayActs,
                            awaySucessActs: awaySucessActs,
                            hasHomeActs: hasHomeActs,
                            hasHomeSucessActs: hasHomeSucessActs,
                            hasAwayActs: hasAwayActs,
                            hasAwaySucessActs: hasAwaySucessActs
                        });
                    }
                }
            });

            return challengesActions;
        },
        getScaleValues: function getScaleValues(json) {
            if (json.length == 0) return;
            var mergedValues = json[0].row;
            if (json.length > 1) mergedValues = json[0].row.concat(json[1].row);

            var maxNumber = _npmLodash['default'].max(mergedValues);
            maxNumber = maxNumber + Math.round(maxNumber * 0.1);
            maxNumber = Math.ceil(maxNumber / 10) * 10;
            var singleScaleValue = maxNumber / 4;
            var scaleValues = [];
            for (var i = 0; i < 5; i++) {
                var scaleValue = null;
                if (i == 0) {
                    scaleValue = 0;
                } else {
                    scaleValue = scaleValues[i - 1] + singleScaleValue;
                }
                scaleValues.push(scaleValue);
            }
            scaleValues = scaleValues.reverse();
            return scaleValues;
        },
        getMaxNumber: function getMaxNumber(json) {
            if (json.length == 0) return;
            var mergedValues = json[0].row;
            if (json.length > 1) mergedValues = json[0].row.concat(json[1].row);
            var maxNumber = _npmLodash['default'].max(mergedValues);
            maxNumber = maxNumber + Math.round(maxNumber * 0.1);
            maxNumber = Math.ceil(maxNumber / 10) * 10;
            return maxNumber;
        },

        prepareActionsByZones: function prepareActionsByZones(json) {
            // Продолжу чью-то хуёовую практику, забью размеры ручками
            // но по хорошему размеры таблицы надо передавать в параметрах
            var yArray = [0, 1, 2];
            var xArray = [0, 1, 2, 3, 4, 5];
            var changedArr = [];
            for (var i = 0; i < json.length; i++) {
                if (json[i] && json[i].hasOwnProperty("loc_y") && json[i].hasOwnProperty("loc_x")) {
                    var hasActs = false;
                    if (json[i].acts.length > 0) {
                        hasActs = true;
                    }
                    changedArr.push({
                        loc_x: json[i].loc_x,
                        count: json[i].count,
                        acts: json[i].acts,
                        hasActs: hasActs,
                        loc_y: 2 - json[i].loc_y
                    });
                }
            }

            yArray.forEach(function (yItem) {
                xArray.forEach(function (xItem) {
                    var coordsItem = _npmLodash['default'].find(changedArr, { 'loc_x': xItem, 'loc_y': yItem });
                    if (!coordsItem) {
                        changedArr.push({ loc_x: xItem, loc_y: yItem, count: 0, acts: [] });
                    }
                });
            });
            var sortedArr = _npmLodash['default'].orderBy(changedArr, ['loc_y', 'loc_x'], ['desc', 'asc']);
            var groupedArr = _npmLodash['default'].groupBy(sortedArr, 'loc_y');
            return groupedArr;
        },
        preparePlayerGoalsList: function preparePlayerGoalsList(json) {
            var filteredDataForPlayerList = [];
            json = _npmLodash['default'].sortBy(json, ['half', 'game_time']);
            json.forEach(function (item) {
                filteredDataForPlayerList.push({
                    game_time: moment.utc(item.game_time).format('mm:ss'),
                    playerId: item.player._id,
                    isOwnGoal: item.isOwnGoal,
                    playerFirstName: item.player.first_name,
                    playerLastName: item.player.last_name,
                    playerPhoto: item.player.photo,
                    ttaId: item.tta._id,
                    ttaName: item.tta.name,
                    score: item.homeScore + ":" + item.awayScore,
                    actionId: item._id,
                    half: item.half,
                    oldGameTime: item.game_time
                });
            });

            return filteredDataForPlayerList;
        },
        prepareDataForPlayerActionsByTime: function prepareDataForPlayerActionsByTime(json) {
            return json;
        },

        prepareDataForOwnGoals: function prepareDataForOwnGoals(json, homeTeamId, awayTeamId, stadium) {
            var editedJson = [];
            json.forEach(function (item) {
                item.isOwnGoal = false;
                if (item.tta.orderIndex === 22) {
                    item.isOwnGoal = true;
                    item.team = item.team == homeTeamId ? awayTeamId : homeTeamId;
                    item.loc_x = stadium.length - item.loc_x;
                    item.target_x = stadium.length - item.target_x;
                }
                editedJson.push(item);
            });
            return editedJson;
        },
        prepareDataForPlayerActionsOverview: function prepareDataForPlayerActionsOverview(json) {
            json = json.table;
            var editedJson = [];
            json.forEach(function (item) {
                var editedItem = {};
                editedItem.groupName = item.group.name;
                editedItem.groupNameUppercase = item.group.name.toUpperCase();
                editedItem.groupId = item.group._id;
                editedItem.orderIndex = item.group.orderIndex;
                editedItem.subGroups = [];
                item.elems.forEach(function (elem) {
                    var subGroupItem = {};
                    subGroupItem.groupName = elem.tta.plural;
                    subGroupItem.groupId = elem.tta._id;
                    subGroupItem.firstHalfActions = elem.values[0].hasOwnProperty('actions') ? elem.values[0].actions : [];
                    subGroupItem.firstHalfSuccessActions = elem.values[0].hasOwnProperty('success_actions') ? elem.values[0].success_actions : [];
                    subGroupItem.firstHalfPercents = subGroupItem.firstHalfActions.length > 0 && subGroupItem.firstHalfSuccessActions.length > 0 ? Math.round(subGroupItem.firstHalfSuccessActions.length / subGroupItem.firstHalfActions.length * 100) : 0;
                    subGroupItem.hasFirstHalfActions = subGroupItem.firstHalfActions.length > 0 ? true : false;
                    subGroupItem.hasFirstHalfSuccessActions = subGroupItem.firstHalfSuccessActions.length > 0 ? true : false;
                    subGroupItem.secondHalfActions = elem.values[1].hasOwnProperty('actions') ? elem.values[1].actions : [];
                    subGroupItem.secondHalfSuccessActions = elem.values[1].hasOwnProperty('success_actions') ? elem.values[1].success_actions : [];
                    subGroupItem.secondHalfPercents = subGroupItem.secondHalfActions.length > 0 && subGroupItem.secondHalfSuccessActions.length > 0 ? Math.round(subGroupItem.secondHalfSuccessActions.length / subGroupItem.secondHalfActions.length * 100) : 0;

                    subGroupItem.hasSecondHalfActions = subGroupItem.secondHalfActions.length > 0 ? true : false;
                    subGroupItem.hasSecondHalfSuccessActions = subGroupItem.secondHalfSuccessActions.length > 0 ? true : false;

                    subGroupItem.perMatchActions = elem.values[2].hasOwnProperty('actions') ? elem.values[2].actions : [];
                    subGroupItem.perMatchSuccessActions = elem.values[2].hasOwnProperty('success_actions') ? elem.values[2].success_actions : [];
                    subGroupItem.perMatchPercents = subGroupItem.perMatchActions.length > 0 && subGroupItem.perMatchSuccessActions.length > 0 ? Math.round(subGroupItem.perMatchSuccessActions.length / subGroupItem.perMatchActions.length * 100) : 0;

                    subGroupItem.hasPerMatchActions = subGroupItem.perMatchActions.length > 0 ? true : false;
                    subGroupItem.hasPerMatchSuccessActions = subGroupItem.perMatchSuccessActions.length > 0 ? true : false;
                    editedItem.subGroups.push(subGroupItem);
                });

                editedJson.push(editedItem);
            });
            return editedJson;
        },
        prepareDataForRefereeActionsOverview: function prepareDataForRefereeActionsOverview(json) {
            json = json.table;
            var editedJson = [];
            json.forEach(function (item) {
                var editedItem = {};
                editedItem.groupName = item.group.name;
                editedItem.groupNameUppercase = item.group.name.toUpperCase();
                editedItem.groupId = item.group._id;
                editedItem.orderIndex = item.group.orderIndex;
                editedItem.subGroups = [];
                item.elems.forEach(function (elem) {
                    var subGroupItem = {};
                    subGroupItem.groupName = elem.tta.plural;
                    subGroupItem.groupId = elem.tta._id;
                    subGroupItem.firstHalfActions = elem.values[0].hasOwnProperty('actions') ? elem.values[0].actions : [];
                    subGroupItem.hasFirstHalfActions = subGroupItem.firstHalfActions.length > 0 && elem.tta.actions ? true : false;
                    subGroupItem.hasTotalValues = !elem.tta.actions ? true : false;
                    subGroupItem.firstHalfTotal = elem.values[0].hasOwnProperty('total') ? Math.round(elem.values[0].total * 10) / 10 : '-';
                    if (elem.tta.sum) {
                        subGroupItem.firstHalfTotal = elem.values[0].hasOwnProperty('average') ? Math.round(elem.values[0].average * 10) / 10 : '-';
                        subGroupItem.hasTotalValues = true;
                        subGroupItem.firstHalfActions = subGroupItem.firstHalfTotal;
                    }

                    subGroupItem.secondHalfActions = elem.values[1].hasOwnProperty('actions') ? elem.values[1].actions : [];
                    subGroupItem.hasSecondHalfActions = subGroupItem.secondHalfActions.length > 0 && elem.tta.actions ? true : false;

                    subGroupItem.secondHalfTotal = elem.values[1].hasOwnProperty('total') ? Math.round(elem.values[1].total * 10) / 10 : '-';
                    if (elem.tta.sum) {
                        subGroupItem.secondHalfTotal = elem.values[1].hasOwnProperty('average') ? Math.round(elem.values[1].average * 10) / 10 : '-';
                        subGroupItem.hasTotalValues = true;
                        subGroupItem.secondHalfActions = subGroupItem.secondHalfTotal;
                    }

                    subGroupItem.perMatchActions = elem.values[2].hasOwnProperty('actions') ? elem.values[2].actions : [];
                    subGroupItem.hasPerMatchActions = subGroupItem.perMatchActions.length > 0 && elem.tta.actions ? true : false;
                    subGroupItem.perMatchTotal = elem.values[2].hasOwnProperty('total') ? Math.round(elem.values[2].total * 10) / 10 : '-';
                    if (elem.tta.sum) {
                        subGroupItem.perMatchTotal = elem.values[2].hasOwnProperty('average') ? Math.round(elem.values[2].average * 10) / 10 : '-';
                        subGroupItem.hasTotalValues = true;
                        subGroupItem.perMatchActions = subGroupItem.perMatchTotal;
                    }

                    editedItem.subGroups.push(subGroupItem);
                });

                editedJson.push(editedItem);
            });
            return editedJson;
        },
        preparePlayerCardsList: function preparePlayerCardsList(json) {
            var filteredDataForPlayerList = [];
            json.forEach(function (item) {
                filteredDataForPlayerList.push({
                    game_time: item.game_time,
                    playerId: item.player._id,
                    playerFirstName: item.player.first_name,
                    playerLastName: item.player.last_name,
                    playerPhoto: item.player.photo,
                    ttaId: item.tta._id,
                    ttaName: item.tta.name,
                    actionId: item._id,
                    half: item.half,
                    ttaCount: 1,
                    game_time: moment.utc(item.game_time).format('mm:ss'),
                    oldGameTime: item.game_time

                });
            });
            return filteredDataForPlayerList;
        },
        prepareRefereeRuns: function prepareRefereeRuns(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            json = _npmLodash['default'].sortBy(json, ['half', 'game_time']);
            var positions = json.map(function (item) {
                filteredDataForPlayerList.push({
                    loc_x: (item.loc_x + stadiumWidth * 0.5) / stadiumWidth * 100 * 0.90 + 5,
                    loc_y: item.loc_y / stadiumHeight * 100 * 0.90 + 5,
                    target_x: (item.target_x + stadiumWidth * 0.5) / stadiumWidth * 100 * 0.90 + 5,
                    target_y: item.target_y / stadiumHeight * 100 * 0.90 + 5,
                    half: item.half,
                    sprint: item.sprint
                });
            });
            return filteredDataForPlayerList;
        },

        preparePlayerPositionsFieldsData: function preparePlayerPositionsFieldsData(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            var filteredData = [];
            var halfs = [];
            json.forEach(function (half) {
                half.values.forEach(function (item) {
                    var cardWidth = item.loc_y;
                    var cardHeight = item.loc_x;
                    var cardWidthPercents = cardWidth / stadiumWidth * 100 * 0.90 + 5;
                    var cardHeightPercents = cardHeight / stadiumHeight * 100 * 0.90 + 5;
                    filteredData.push({
                        half: half.half,
                        top: cardWidthPercents,
                        left: cardHeightPercents,
                        number: item.number,
                        playerId: item.player._id,
                        playerFirstName: item.player.first_name,
                        playerLastName: item.player.last_name
                    });
                });
            });

            for (var i = 1; i < 3; i++) {
                halfs.push({
                    name: i + ' Half ',
                    half: i,
                    delimeter: '|'
                });
            }
            var groupedByPlayer = _npmLodash['default'].groupBy(filteredData, 'playerId');
            for (var item in groupedByPlayer) {
                var currentArr = groupedByPlayer[item];
                var playerAverageTop = 0;
                var playerAverageLeft = 0;
                currentArr.forEach(function (elem) {
                    playerAverageTop += elem.top;
                    playerAverageLeft += elem.left;
                });
                playerAverageTop = playerAverageTop / currentArr.length;
                playerAverageLeft = playerAverageLeft / currentArr.length;
                filteredData.push({
                    half: 'total',
                    top: playerAverageTop,
                    left: playerAverageLeft,
                    number: currentArr[0].number,
                    playerId: currentArr[0].playerId,
                    playerFirstName: currentArr[0].playerFirstName,
                    playerLastName: currentArr[0].playerLastName
                });
            }
            halfs.push({
                name: 'Total',
                half: 'total',
                delimeter: ''
            });
            return { filteredData: filteredData, halfs: halfs };
        },
        prepareDataForPlayerGameReportPasses: function prepareDataForPlayerGameReportPasses(json, stadiumWidth, stadiumHeight) {
            json.forEach(function (item, index) {
                json[index].playerTtaId = item.playerTTA._id;
            });
            var filteredDataForPlayerList = [];
            var filteredData = [];

            json.forEach(function (item) {
                var sucessActions = item.success_actions;
                item.actions.forEach(function (acttItem) {
                    var isSucessful = sucessActions.indexOf(acttItem._id) != -1;
                    var cardWidth = item.loc_y;
                    var cardHeight = item.loc_x;
                    var loc_x = acttItem.loc_x / stadiumHeight * 90 + 5;
                    var loc_y = acttItem.loc_y / stadiumWidth * 90 + 5;
                    var target_x = acttItem.target_x / stadiumHeight * 90 + 5;
                    var target_y = acttItem.target_y / stadiumWidth * 90 + 5;
                    filteredData.push({
                        loc_x: loc_x,
                        loc_y: loc_y,
                        top: loc_y,
                        left: loc_x,
                        isSucessful: isSucessful,
                        half: acttItem.half,
                        target_x: target_x,
                        target_y: target_y,
                        action: acttItem._id,
                        actionId: acttItem._id
                    });
                });
            });
            var groupedJson = _npmLodash['default'].groupBy(json, 'playerTtaId');
            var sucessTable = {};

            for (var propt in groupedJson) {
                var currentArr = groupedJson[propt];
                var ttaId = currentArr[0].playerTTA._id;
                var ttaName = currentArr[0].playerTTA.plural;
                var totalActionsLength = 0;
                var totalSucessActionsLength = 0;
                currentArr.forEach(function (item) {
                    totalActionsLength += item.actions.length;
                    totalSucessActionsLength += item.success_actions.length;
                });
                var percentSucess = Math.round(totalSucessActionsLength / totalActionsLength * 100);
                sucessTable[ttaName.split(" ").splice(-1)] = {
                    percentSucess: percentSucess,
                    ttaId: ttaId,
                    ttaName: ttaName,
                    totalActionsLength: totalActionsLength,
                    totalSucessActionsLength: totalSucessActionsLength
                };
            }
            return { data: filteredData, sucessTable: sucessTable };
        },
        prepareDataForplayerGameReportPassesDistribution: function prepareDataForplayerGameReportPassesDistribution(json, stadiumWidth, stadiumHeight, ownPlayerId) {
            //_.filter(json.gave, {'half': position.half, 'playerId': item.player._id });
            json.recieved.forEach(function (item, index) {
                json.recieved[index].playerId = item.player._id;
            });
            json.gave.forEach(function (item, index) {
                json.gave[index].playerId = item.target_player._id;
            });
            var positions = [];
            var lines = [];
            var positionsTable = [];
            json.positions.forEach(function (position) {
                position.array.forEach(function (item, index) {
                    var cardWidth = item.loc_y;
                    var cardHeight = item.loc_x;
                    var loc_x = item.loc_x / stadiumHeight * 90 + 5;
                    var loc_y = item.loc_y / stadiumWidth * 90 + 5;
                    positions.push({
                        top: loc_y,
                        left: loc_x,
                        half: position.half,
                        number: item.number,
                        player: item.player,
                        playerId: item.player._id
                    });
                });
            });
            json.recieved.forEach(function (item) {
                var player = _npmLodash['default'].find(positions, { 'half': item.half, 'playerId': item.player._id });
                var targetPlayer = _npmLodash['default'].find(positions, { 'half': item.half, 'playerId': item.target_player });
                if (player && targetPlayer) {
                    lines.push({
                        loc_y: player.top,
                        loc_x: player.left,
                        target_loc_y: targetPlayer.top,
                        target_loc_x: targetPlayer.left,
                        half: item.half,
                        isReceived: 1
                    });
                }
            });
            json.gave.forEach(function (item) {
                var player = _npmLodash['default'].find(positions, { 'half': item.half, 'playerId': item.player });
                var targetPlayer = _npmLodash['default'].find(positions, { 'half': item.half, 'playerId': item.target_player._id });
                if (player && targetPlayer) {
                    lines.push({
                        loc_y: player.top - 0.5,
                        loc_x: player.left - 0.5,
                        target_loc_y: targetPlayer.top - 0.5,
                        target_loc_x: targetPlayer.left - 0.5,
                        half: item.half,
                        isReceived: 0
                    });
                }
            });

            json.positions.forEach(function (position) {
                var halfTable = {};
                halfTable.half = position.half;
                halfTable.playerResults = [];
                position.array.forEach(function (item, index) {
                    var gaveTotal = 0;
                    var receivedTotal = 0;
                    var receivedIds = [];
                    var gavedIds = [];
                    var playerGaves = _npmLodash['default'].filter(json.gave, { 'half': position.half, 'playerId': item.player._id });
                    var playerReceived = _npmLodash['default'].filter(json.recieved, { 'half': position.half, 'playerId': item.player._id });
                    playerGaves.forEach(function (item) {
                        gavedIds.push(item._id);
                    });
                    playerReceived.forEach(function (item) {
                        receivedIds.push(item._id);
                    });
                    var totalIds = gavedIds.concat(receivedIds);
                    //homeActs.length > 0 ? true : false;
                    halfTable.playerResults.push({
                        player: item.player,
                        gave: playerGaves.length,
                        received: playerReceived.length,
                        total: playerGaves.length + playerReceived.length,
                        gavedIds: gavedIds,
                        receivedIds: receivedIds,
                        totalIds: totalIds,
                        hasGavedIds: gavedIds.length > 0 ? true : false,
                        hasReceivedIds: receivedIds.length > 0 ? true : false,
                        hasTotalIds: totalIds.length > 0 ? true : false
                    });
                });

                var totalGave = 0;
                var totalReceived = 0;
                halfTable.playerResults.forEach(function (item) {
                    totalGave += item.gave;
                    totalReceived += item.received;
                });
                halfTable.totalGave = totalGave;
                halfTable.totalReceived = totalReceived;
                halfTable.totalOverall = totalGave + totalReceived;
                positionsTable.push(halfTable);
            });

            //let totalTable = positionsTable[0].playerResults.concat(positionsTable[1].playerResults);
            var totalTable = [];
            for (var i = 0; i < positionsTable[0].playerResults.length; i++) {
                var elem = positionsTable[0].playerResults[i];
                for (var j = 0; j < positionsTable[1].playerResults.length; j++) {
                    var elem2 = positionsTable[1].playerResults[j];
                    if (elem.player._id == elem2.player._id) {
                        elem.gave += elem2.gave;
                        elem.received += elem2.received;
                    }
                }
                totalTable.push(elem);
            }
            for (var i = 0; i < positionsTable[1].playerResults.length; i++) {
                var elem = positionsTable[1].playerResults[i];
                var hasThis = false;
                for (var j = 0; j < totalTable.length; j++) {
                    var elem2 = totalTable[j];
                    if (elem.player._id == elem2.player._id) {
                        hasThis = true;
                    }
                }
                if (!hasThis) totalTable.push(elem);
            }

            var completeTable = [];
            totalTable.forEach(function (item, index) {
                totalTable[index].playerId = item.player._id;
            });
            totalTable.forEach(function (item, index) {
                var totalGave = 0;
                var totalReceived = 0;
                totalTable.forEach(function (elem, i) {
                    if (item.playerId == elem.playerId) {
                        totalGave += elem.gave;
                        totalReceived += elem.received;
                    }
                });
                var totalIds = item.gavedIds.concat(item.receivedIds);
                completeTable.push({
                    player: item.player,
                    playerId: item.playerId,
                    gave: totalGave,
                    received: totalReceived,
                    total: totalGave + totalReceived,
                    gavedIds: item.gavedIds,
                    receivedIds: item.receivedIds,
                    totalIds: totalIds,
                    hasGavedIds: item.gavedIds.length > 0 ? true : false,
                    hasReceivedIds: item.receivedIds.length > 0 ? true : false,
                    hasTotalIds: totalIds.length > 0 ? true : false
                });
            });
            var uniqueCompleteTable = _npmLodash['default'].uniqWith(completeTable, _npmLodash['default'].isEqual);
            var fullHalfTable = {};
            fullHalfTable.half = 3;
            fullHalfTable.playerResults = uniqueCompleteTable;
            var totalFullHalfGave = 0;
            var totalFullHalfReceived = 0;
            fullHalfTable.playerResults.forEach(function (item) {
                totalFullHalfGave += item.gave;
                totalFullHalfReceived += item.received;
            });
            fullHalfTable.totalGave = totalFullHalfGave;
            fullHalfTable.totalReceived = totalFullHalfReceived;
            fullHalfTable.totalOverall = totalFullHalfGave + totalFullHalfReceived;
            positionsTable.push(fullHalfTable);
            var groupedByPlayer = _npmLodash['default'].groupBy(positions, 'playerId');

            for (var item in groupedByPlayer) {
                var playerPositions = groupedByPlayer[item];
                var playerAverageTop = 0;
                var playerAverageLeft = 0;
                playerPositions.forEach(function (elem) {
                    playerAverageTop += elem.top;
                    playerAverageLeft += elem.left;
                });
                playerAverageTop = playerAverageTop / playerPositions.length;
                playerAverageLeft = playerAverageLeft / playerPositions.length;
                positions.push({
                    top: playerAverageTop,
                    left: playerAverageLeft,
                    half: 3,
                    number: playerPositions[0].number,
                    playerId: playerPositions[0].player._id,
                    player: playerPositions[0].player
                });
            }

            json.recieved.forEach(function (item) {
                var player = _npmLodash['default'].find(positions, { 'half': 3, 'playerId': item.player._id });
                var targetPlayer = _npmLodash['default'].find(positions, { 'half': 3, 'playerId': item.target_player });
                lines.push({
                    loc_y: player.top,
                    loc_x: player.left,
                    target_loc_y: targetPlayer.top,
                    target_loc_x: targetPlayer.left,
                    half: 3,
                    isReceived: 1
                });
            });
            json.gave.forEach(function (item) {
                var player = _npmLodash['default'].find(positions, { 'half': 3, 'playerId': item.player });
                var targetPlayer = _npmLodash['default'].find(positions, { 'half': 3, 'playerId': item.target_player._id });
                lines.push({
                    loc_y: player.top - 0.5,
                    loc_x: player.left - 0.5,
                    target_loc_y: targetPlayer.top - 0.5,
                    target_loc_x: targetPlayer.left - 0.5,
                    half: 3,
                    isReceived: 0
                });
            });
            return { positions: positions, lines: lines, positionsTable: positionsTable };
        },
        prepareDataForPlayerGameReportChallenges: function prepareDataForPlayerGameReportChallenges(json, stadiumWidth, stadiumHeight) {
            json.forEach(function (item, index) {
                json[index].playerId = item.target_player._id;
            });
            var positions = [];
            var lines = [];
            var positionsTable = [];
            json.forEach(function (item) {
                var cardWidth = item.loc_y;
                var cardHeight = item.loc_x;
                var loc_x = item.loc_x / stadiumHeight * 90 + 5;
                var loc_y = item.loc_y / stadiumWidth * 90 + 5;
                positions.push({
                    _id: item._id,
                    actionId: item._id,
                    top: loc_y,
                    left: loc_x,
                    half: item.half,
                    success: item.success,
                    player: item.target_player,
                    playerId: item.playerId
                });
            });
            for (var i = 1; i < 3; i++) {
                var halfTable = {};
                halfTable.half = i;
                halfTable.playerResults = [];
                var _currentPositions = _npmLodash['default'].filter(positions, { 'half': i });
                var _groupedByPlayer = _npmLodash['default'].groupBy(_currentPositions, 'playerId');

                var _loop = function (item) {
                    var playerPositions = _groupedByPlayer[item];
                    var playerWins = 0;
                    var playerLooses = 0;
                    var player = null;
                    var playerWinsActs = [];
                    var playerLoosesActs = [];
                    playerPositions.forEach(function (elem) {
                        if (elem.success) {
                            playerWins += 1;
                            playerWinsActs.push(elem._id);
                        } else {
                            playerLooses += 1;
                            playerLoosesActs.push(elem._id);
                        }
                    });
                    halfTable.playerResults.push({
                        playerWins: playerWins,
                        playerLooses: playerLooses,
                        player: playerPositions[0].player,
                        playerWinsActs: playerWinsActs,
                        playerLoosesActs: playerLoosesActs,
                        hasPlayerWinsActs: playerWinsActs.length > 0 ? true : false,
                        hasPlayerLoosesActs: playerLoosesActs.length > 0 ? true : false
                    });
                };

                for (var item in _groupedByPlayer) {
                    _loop(item);
                }
                var _totalWins = 0;
                var _totalLooses = 0;
                halfTable.playerResults.forEach(function (item) {
                    _totalWins += item.playerWins;
                    _totalLooses += item.playerLooses;
                });
                halfTable.totalWins = _totalWins;
                halfTable.totalLooses = _totalLooses;
                positionsTable.push(halfTable);
            }

            var halfFullTable = {};
            halfFullTable.half = 3;
            halfFullTable.playerResults = [];
            var currentPositions = positions;
            var groupedByPlayer = _npmLodash['default'].groupBy(currentPositions, 'playerId');

            var _loop2 = function (item) {
                var playerPositions = groupedByPlayer[item];
                var playerWins = 0;
                var playerLooses = 0;
                var player = null;
                var playerWinsActs = [];
                var playerLoosesActs = [];
                playerPositions.forEach(function (elem) {
                    if (elem.success) {
                        playerWins += 1;
                        playerWinsActs.push(elem._id);
                    } else {
                        playerLooses += 1;
                        playerLoosesActs.push(elem._id);
                    }
                });
                halfFullTable.playerResults.push({
                    playerWins: playerWins,
                    playerLooses: playerLooses,
                    player: playerPositions[0].player,
                    playerWinsActs: playerWinsActs,
                    playerLoosesActs: playerLoosesActs,
                    hasPlayerWinsActs: playerWinsActs.length > 0 ? true : false,
                    hasPlayerLoosesActs: playerLoosesActs.length > 0 ? true : false
                });
            };

            for (var item in groupedByPlayer) {
                _loop2(item);
            }
            var totalWins = 0;
            var totalLooses = 0;
            halfFullTable.playerResults.forEach(function (item) {
                totalWins += item.playerWins;
                totalLooses += item.playerLooses;
            });
            halfFullTable.totalWins = totalWins;
            halfFullTable.totalLooses = totalLooses;
            positionsTable.push(halfFullTable);
            return { positions: positions, positionsTable: positionsTable };
        },
        prepareDataForPlayerGameReportTotalActions: function prepareDataForPlayerGameReportTotalActions(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            var filteredData = [];
            json.non_success.forEach(function (item, index) {
                json.non_success[index].type = 0;
                json.non_success[index].success = false;
            });

            json.success.forEach(function (item, index) {
                json.success[index].type = 1;
                json.success[index].success = true;
            });
            //let editedJson = json.success;
            var editedJson = json.success.concat(json.non_success);

            editedJson.forEach(function (item) {
                var cardWidth = item.loc_y;
                var cardHeight = item.loc_x;
                var cardWidthPercents = cardWidth / stadiumWidth * 100 * 0.90 + 5;
                var cardHeightPercents = cardHeight / stadiumHeight * 100 * 0.90 + 5;
                filteredData.push({
                    _id: item._id,
                    type: item.type,
                    success: item.success,
                    half: item.half,
                    top: cardWidthPercents,
                    left: cardHeightPercents
                });
            });
            return filteredData;
        },
        preparePlayerCardsFieldsData: function preparePlayerCardsFieldsData(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            var filteredData = [];
            json.forEach(function (item) {
                var minuteCount = moment.utc(item.game_time).format('mm:ss');
                var cardWidth = item.loc_y;
                var cardHeight = item.loc_x;
                if (cardWidth === 0) {
                    cardWidth = Math.floor(Math.random() * (stadiumWidth - 0 + 1) + 0);
                }
                if (cardHeight === 0) {
                    cardHeight = Math.floor(Math.random() * (stadiumHeight - 0 + 1) + 0);
                }
                var splittedTime = minuteCount.split(':');
                var decimalTime = parseFloat(parseInt(splittedTime[0], 10) + '.' + parseInt(splittedTime[1] / 6 * 10, 10));
                var percentsTime = decimalTime / 45 * 100;
                var cardWidthPercents = cardWidth / stadiumWidth * 100 * 0.90 + 5;
                var cardHeightPercents = cardHeight / stadiumHeight * 100 * 0.90 + 5;
                var timelineOnePercent = (89 - 3) / 100;
                var resultTimelineOffset = 2.8 + percentsTime * timelineOnePercent;
                filteredData.push({
                    minuteCount: minuteCount.substring(0, minuteCount.length - 3),
                    playerFirstName: item.player.first_name,
                    playerLastName: item.player.last_name,
                    ttaName: item.tta.name,
                    half: item.half,
                    top: cardWidthPercents,
                    left: cardHeightPercents,
                    timelineOffset: resultTimelineOffset,
                    actionId: item._id
                });
            });

            return filteredData;
        },

        prepareBreakdownsHeatmapData: function prepareBreakdownsHeatmapData(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            var filteredData = [];
            json.forEach(function (item) {
                var cardWidth = item.loc_y / stadiumWidth;;
                var cardHeight = item.loc_x / stadiumHeight;
                filteredData.push({
                    loc_y: cardWidth,
                    loc_x: cardHeight
                });
            });

            return filteredData;
        },
        prepareAttacksFieldsData: function prepareAttacksFieldsData(json, stadiumWidth, stadiumHeight) {

            var preparedList = [];
            json.forEach(function (item, index) {
                var preparedChain = [];
                item.chain.forEach(function (target, stepIndex) {
                    var loc_x = target.loc_x / stadiumHeight * 90 + 5;
                    var loc_y = target.loc_y / stadiumWidth * 90 + 5;
                    var target_x = target.target_x / stadiumHeight * 90 + 5;
                    var target_y = target.target_y / stadiumWidth * 90 + 5;
                    preparedChain.push({
                        loc_x: loc_x,
                        loc_y: loc_y,
                        target_x: target_x,
                        target_y: target_y,
                        number: target.player,
                        action: target.action,
                        actionId: target.action
                    });
                });
                preparedList.push(preparedChain);
            });
            return preparedList;
        },
        prepareTouches: function prepareTouches(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            var filteredData = [];
            //stadiumWidth - o y
            //stadiumHeight - o x
            json.forEach(function (item, index) {
                if (item.hasOwnProperty('loc_y') && item.hasOwnProperty('loc_x') && item.hasOwnProperty('target_y') && item.hasOwnProperty('target_x')) {
                    //
                    var playerWidth = item.loc_y;
                    var playerHeight = item.loc_x;
                    var xPosition = stadiumWidth / 2 - playerWidth + 20.15;
                    var playerWidthPercents = 100 - (xPosition / 40.3 * 100 * 0.90 + 5);
                    var playerHeightPercents = 100 - ((stadiumHeight - playerHeight) / 16.5 * 100 * 0.90 + 5);

                    filteredData.push({
                        left: playerWidthPercents,
                        top: playerHeightPercents,
                        actionId: item._id,
                        number: item.number
                    });
                }
            });
            return filteredData;
        },
        prepareReportActionsByPlayers: function prepareReportActionsByPlayers(json) {
            var preparedData = [];
            json.values.forEach(function (item, index) {
                var preparedValues = [];
                var acts = [];
                var hasActs = false;
                item.value.forEach(function (valueItem) {
                    if (typeof valueItem === 'object') {
                        if (!valueItem.success_actions) valueItem.success_actions = 0;
                        preparedValues.push(valueItem.actions);
                        if (valueItem.actions > 0) {
                            acts = acts.concat(valueItem.acts);
                            hasActs = true;
                        }
                    } else {
                        preparedValues.push(valueItem);
                    }
                });
                acts = _npmLodash['default'].uniq(acts);
                preparedData.push({ player: item.player, data: preparedValues, acts: acts, hasActs: hasActs });
            });
            return { players: preparedData, header: json.header };
        },
        prepareReportActionsByPlayersForPasses: function prepareReportActionsByPlayersForPasses(json) {

            var preparedData = [];
            json.values.forEach(function (item, index) {
                var preparedValues = [];
                var acts = [];
                var hasActs = false;
                item.value.forEach(function (valueItem) {
                    if (typeof valueItem === 'object') {
                        if (!valueItem.success_actions) valueItem.success_actions = 0;
                        if (!valueItem.actions) return;
                        preparedValues.push(valueItem.actions);
                        preparedValues.push(valueItem.success_actions);
                        preparedValues.push(Math.round(item.value[0].success_actions / item.value[0].actions * 100) || '0');
                        acts = acts.concat(valueItem.acts);
                        hasActs = true;
                    } else {
                        preparedValues.push(valueItem);
                        preparedValues.push(0);
                        preparedValues.push(0);
                    }
                });
                if (!preparedValues.length) return;
                var player = {
                    first_name: item.player.first_name && item.player.first_name.length ? item.player.first_name[0] : ' ',
                    last_name: item.player.last_name,
                    _id: item.player._id,
                    photo: item.player.photo
                };
                preparedData.push({ player: player, data: preparedValues, acts: acts, hasActs: hasActs });
            });
            return { players: preparedData, header: json.header };
        },
        prepareSetPiecesFieldsData: function prepareSetPiecesFieldsData(json, stadiumWidth, stadiumHeight) {
            var filteredDataForPlayerList = [];
            var filteredData = [];
            json.forEach(function (item, index) {
                if (item.hasOwnProperty('loc_y') && item.hasOwnProperty('loc_x') && item.hasOwnProperty('target_y') && item.hasOwnProperty('target_x')) {
                    //
                    var playerWidth = item.loc_y;
                    var playerHeight = item.loc_x;
                    var targetWidth = item.target_y;
                    var targetHeight = item.target_x;

                    var playerWidthPercents = playerWidth / stadiumWidth * 100 * 0.90 + 5;
                    var playerHeightPercents = playerHeight / stadiumHeight * 100 * 0.90 + 5;
                    var targetWidthPercents = targetWidth / stadiumWidth * 100 * 0.90 + 5;
                    var targetHeightPercents = targetHeight / stadiumHeight * 100 * 0.90 + 5;
                    var minuteCount = moment.utc(item.game_time).format('mm:ss');
                    var splittedTime = minuteCount.split(':');
                    var decimalTime = parseFloat(parseInt(splittedTime[0], 10) + '.' + parseInt(splittedTime[1] / 6 * 10, 10));
                    var percentsTime = decimalTime / 45 * 100;
                    var timelineOnePercent = (89 - 3) / 100;
                    var resultTimelineOffset = 2.8 + percentsTime * timelineOnePercent;

                    var a = playerHeightPercents - targetHeightPercents,
                        b = playerWidthPercents - targetWidthPercents,
                        c = Math.sqrt(a * a + b * b);

                    var sx = (playerHeightPercents + targetHeightPercents) / 2,
                        sy = (playerWidthPercents + targetWidthPercents) / 2;

                    var lineHeight = sx - c / 2,
                        lineWidth = sy;
                    if (playerWidthPercents > targetWidthPercents) a = -a;

                    filteredData.push({
                        resultPlayerTop: playerWidthPercents,
                        resultPlayerLeft: playerHeightPercents,
                        resultTargetTop: targetWidthPercents,
                        resultTargetLeft: targetHeightPercents,
                        loc_y: item.loc_y,
                        loc_x: item.loc_x,
                        time: minuteCount,
                        target_y: item.target_y,
                        target_x: item.target_x,
                        half: item.half,
                        minuteCount: minuteCount.substring(0, minuteCount.length - 3),
                        timelineOffset: resultTimelineOffset,
                        playerFirstName: item.player.first_name,
                        playerLastName: item.player.last_name,
                        actionId: item._id,
                        number: item.number
                    });
                }
            });
            return filteredData;
        },
        calculateReportCompareWidth: function calculateReportCompareWidth(value, maxValue, additionalSuffix) {
            var maxWidthTable = { 4: 25.4, 6: 31, 7: 34, 8: 36, 9: 40, 10: 44, 5: 27, 3: 20, 2: 18, 1: 17 };
            var minOffset = 19;
            var offsetOnChar = 2.2;
            var suffix = additionalSuffix || '';
            var l = Math.abs(Math.round(value * 10) / 10) || 0;
            l += suffix;
            var minWidth = 100;
            var maxWidth = maxWidthTable[l.toString().length]; // 14max
            var realPerc = value * 100 / maxValue;
            var oneOffsetPerc = (minWidth - maxWidth) / 100;
            var diff = oneOffsetPerc * realPerc; // 100 - 25 / 100 * ()
            var width = Math.abs(100 - diff) || 100;
            return _ember['default'].String.htmlSafe("width: " + width + "%");
        },
        changeHeaderAttacks: function changeHeaderAttacks(header) {
            header.map(function (item, index) {
                item.plural = item.plural.replace('Participation in ', '');
            });
            return header;
        }
    });
});