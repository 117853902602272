define('msa/controllers/players/show/overview', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),

        //наблюдаем за sqads
        playerPerformance: _ember['default'].observer('ttaToSearch.[]', function () {
            this.send('getsAttributes');
        }),

        changeDate: _ember['default'].observer('endDate', 'model.id', function () {

            var that = this,
                qo = {
                player: that.get('model.id'),
                startDate: that.get('startDate'),
                endDate: that.get('endDate')
            };

            if (!qo.player || !qo.startDate || !qo.endDate) return;
            $('.datasLoadingsp').removeClass('none');

            this.send('getsAttributes');
            this.send('getActionMap');

            var q = '?player=' + qo.player + '&startDate=' + qo.startDate + '&endDate=' + qo.endDate;
            this.send('getPositions', q);

            that.get('ajax').request('/playerActionMapTypes?player=' + that.get("model.id")).then(function (p) {
                that.set('playerActionMapTypes', p.playerActionMapTypes);
                that.set('currentAction', p.playerActionMapTypes[0]);
            });
        }),

        ctrl: _ember['default'].inject.controller('players.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),

        actions: {
            //добавляем tta в попап
            addTta: function addTta(t) {
                var l = this.get('ttaToSearch');
                if (l.includes(t)) {
                    l.removeObject(t);
                    t.set('inQuery', false);
                } else {
                    l.addObject(t);
                    t.set('inQuery', true);
                }
            },

            //получаем карту с экшенами
            getsAttributes: function getsAttributes() {
                $('.squadLoading').removeClass('none');
                var that = this;
                var st = this.get('startDate');
                var end = this.get('endDate');

                if (!st || !end) return;
                if (!this.get('ttaToSearch')) return;
                var arr = this.get('ttaToSearch').mapBy('id');
                var q = '';
                for (var i = 0; i < arr.length; i++) {
                    q += '&playerTTA=' + arr[i];
                }

                that.get('ajax').request('/playerPerformancev2?player=' + this.get("model.id") + q + '&startDate=' + this.get("startDate") + '&endDate=' + this.get("endDate")).then(function (json) {
                    if (!json) return;
                    for (var _i = 0; _i < json.length; _i++) {
                        if (json[_i].hasOwnProperty('success_actions')) json[_i].success_actions_calculated = (json[_i].success_actions || 0) / (json[_i].actions || 1) * 100;
                        if (json[_i].playerTTA && json[_i].playerTTA.has_success) json[_i].success_word = "Success";
                        if (json[_i].playerTTA && json[_i].playerTTA.has_won) json[_i].success_word = "Won";
                        if (json[_i].playerTTA && json[_i].playerTTA.has_on_target) json[_i].success_word = "On Target";
                        if (json[_i].playerTTA && json[_i].playerTTA.has_accurate) json[_i].success_word = "Accurate";
                    }
                    that.set('attributes', json);
                    $('.squadLoading').addClass('none');
                });
            },

            getActionMap: function getActionMap() {
                var cm = this.get('currentAction');
                var that = this,
                    p = that.get('model.id'),
                    s = that.get('startDate'),
                    e = that.get('endDate');
                if (!p || !s || !e || !cm) return;
                $('.actionLoading').removeClass('none');
                var q = '?player=' + p + '&startDate=' + s + '&endDate=' + e;
                if (cm.tta) q += '&tta=' + cm.tta;
                if (cm.ttaGroup) q += '&ttaGroup=' + cm.ttaGroup;

                that.get('ajax').request('/playerActionMaps' + q).then(function (json) {
                    console.log('json', json);
                    that.set('playerActionMaps', json.playerActionMaps);
                    $('.actionLoading').addClass('none');
                });
            },

            //устанавливаем параметры для поиска экшенов на карте
            setActionMap: function setActionMap(m) {
                this.set('currentAction', m);
                this.send('getActionMap');
            },

            getPositions: function getPositions(q) {
                var that = this;
                $('.getPositionsLoading').removeClass('none');
                that.get('ajax').request('/fieldPlayerPositions' + q).then(function (json) {
                    var arr = _ember['default'].A();
                    var ob = json[0];
                    if (json.length) {
                        for (var ps = 0; ps < ob.positions.length; ps++) {
                            var name = ob.positions[ps].amplua.name === 'Goalkeeper' ? ob.positions[ps].amplua.name : ob.positions[ps].position.name + ' ' + ob.positions[ps].amplua.name;
                            arr.pushObject({
                                name: name,
                                total: ob.positions[ps].total,
                                x: ob.positions[ps].pos.amp * 100 / (ob.matrix.ampluas - 1),
                                y: ob.positions[ps].pos.pos * 100 / (ob.matrix.positions - 1)
                            });
                        }
                    }

                    that.set('fieldPlayerPositions', arr);
                    $('.getPositionsLoading').addClass('none');
                });
            }
        }
    });
});