define('msa/routes/post-match-report/summary/actions', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service()

    });
});
/*setupController: function(controller, model) {
    const that = this;
    controller.set('model', that.controllerFor('players.show').get("model"));
      that.get('ajax').request(`/playerActionMapTypes?player=${that.controllerFor('players.show').get("model.id")}`).then(function(p) {
        controller.set('playerActionMapTypes', p.playerActionMapTypes);
        controller.set('currentAction', p.playerActionMapTypes[0]);
    });
      that.store.findAll('playerTTA').then((ttas) => {
        var newObj = Ember.A();
        ttas.forEach((tta, index) => {
            ttas.objectAt(index).set('inQuery', false);
        });
        ttas = ttas.filterBy('parameter', true).sortBy('plural');
        controller.set('tta', ttas);
        for (let i = 0; i < 8; i++){
           ttas.objectAt(i).set('inQuery', true);
        }
        newObj.pushObjects(ttas.objectsAt([0, 1, 2, 3, 4, 5, 6, 7]));
        controller.set('ttaToSearch', newObj);
    });
},
  resetController(controller, isExiting, transition) {
    controller.setProperties({
        playerActionMapTypes: null,
        currentAction: null
    });
} */