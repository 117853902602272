define('msa/routes/league-statistics/competition', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        beforeModel: function beforeModel(transition) {
            //if(!("countryId" in transition.queryParams)) {
            //    console.log("no property");
            //}
            var that = this;
            that.set('countryId', transition.queryParams.countryId);
        },

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', width: '50%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', query: 'country', width: '50%', title: 'select league', step: 2 })]);
        },

        setupController: function setupController(controller, model) {
            var countryId = this.get('countryId');
            controller.set('definedSteps', model);
            this.get('ajax').request('/country/' + countryId).then(function (countryData) {
                controller.set('country', countryData.country);
            });
            controller.setProperties({
                step: 2,
                team: false,
                competition: false
            });
        },

        actions: {
            renderStep: function renderStep(modelName, query, t) {
                var that = this;
                var country = that.get('countryId');
                that.get('ajax').request('/selectCompetition?country=' + country).then(function (json) {
                    that.render('outlets/select/competition', {
                        into: 'league-statistics/competition',
                        outlet: 'steps',
                        model: json.team
                    });
                });
                return true;
            },

            setModel: function setModel(modelName, model) {
                this.transitionTo('leagues.show', model._id);
            },

            backStep: function backStep(step) {
                console.log('backStep');
                console.log(step);
                var controller = this.controllerFor('league-statistics/competition');
                if (step.model === "country") {
                    controller.transitionToRoute('league-statistics.country');
                }
            }
        }

    });
});