define('msa/components/match-form', ['exports', 'ember', 'npm:lodash', 'msa/config/environment'], function (exports, _ember, _npmLodash, _msaConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        store: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        currentUser: _ember['default'].inject.service(),

        //следим за изменениями даты
        getData: _ember['default'].observer('endDate', 'currentCompetition', function () {
            var st = this.get('startDate');
            var end = this.get('endDate');

            if (!st || !end) return;
            this.send('getMatches');
        }),

        didInsertElement: function didInsertElement() {
            this.send('getMatches');
        },

        actions: {
            goToReportPage: function goToReportPage(modelId, gameId) {
                console.log("gameId", gameId);
                console.log("modelId", modelId);
                console.log(this.get('playerReport'), this.get('refereeReport'));
                if (this.get('playerReport')) {
                    this.get('router').transitionTo('post-match-report.player', modelId, gameId);
                } else {
                    if (this.get('refereeReport')) {
                        this.get('router').transitionTo('post-match-report.referee', modelId, gameId);
                    } else {
                        this.get('router').transitionTo('post-match-report.summary.overview', gameId);
                    }
                }
            },
            showMatchInfo: function showMatchInfo(m) {
                var that = this;
                $('.gameLoading').removeClass('none');
                that.get('ajax').request('/matchStat2?game=' + m.get('id')).then(function (json) {
                    if (json.error) {
                        m.set('noData', 'No information about this game');
                        return;
                    }
                    var ht = m.get('home_team.id');
                    var at = m.get('away_team.id');
                    var actions = [],
                        hd = [],
                        ad = [];
                    for (var key in json) {
                        console.log(key);
                        actions.push(key);
                        var hdObj = {};
                        if (json[key][ht]) hdObj.val = json[key][ht].actions || 0;else hdObj.val = 0;
                        if (key === 'challenges') hdObj.val = json[key][ht].success_actions;
                        if (json[key][ht] && json[key][ht].hasOwnProperty('success_actions') && json[key][ht].actions) {
                            hdObj.per = Math.ceil(json[key][ht].success_actions * 100 / json[key][ht].actions);

                            hdObj.pref = '%';
                        } else {
                            hdObj.per = '';
                            hdObj.pref = '';
                        }
                        if (key === "possession") {
                            hdObj.per = (json[key][ht].value / (json[key][ht].value + json[key][at].value) * 100).toFixed();
                            hdObj.pref = '%';
                            hdObj.val = '';
                        }
                        hd.push(hdObj);

                        var adObj = {};
                        if (json[key][at]) adObj.val = json[key][at].actions;else adObj.val = 0;
                        if (key === 'challenges') adObj.val = json[key][at].success_actions;
                        if (json[key][at] && json[key][at].hasOwnProperty('success_actions') && json[key][at].actions) {
                            adObj.per = Math.floor(json[key][at].success_actions * 100 / json[key][at].actions);
                            adObj.pref = '%';
                        } else {
                            adObj.per = '';
                            adObj.pref = '';
                        }
                        if (key === "possession") {
                            adObj.per = (json[key][at].value / (json[key][ht].value + json[key][at].value) * 100).toFixed();
                            adObj.pref = '%';
                            adObj.val = '';
                        }
                        ad.push(adObj);
                    }
                    m.set('actions', actions);
                    m.set('ad', ad);
                    m.set('hd', hd);
                    m.set('xls_url', _msaConfigEnvironment['default'].host + '/ember/excelReport/' + m.get('id') + '.xlsx');
                    $('.gameLoading').addClass('none');
                });
            },

            loadVideo: function loadVideo(m) {
                var that = this;
                var newArr = [];
                that.get('ajax').request('/watchFullMatch?match=' + m.get('id')).then(function (ar) {
                    for (var i = 0; i < ar.length; i++) {
                        newArr.push({
                            i: newArr.length,
                            gameId: ar[i].game._id,
                            half: ar[i].half,
                            video: ar[i].server.url + ar[i].url,
                            away: ar[i].game.away_team.name,
                            awayId: ar[i].game.away_team._id,
                            home: ar[i].game.home_team.name,
                            homeId: ar[i].game.home_team._id,
                            date: moment.utc(ar[i].game.date).format('L')
                        });
                    }
                    that.set('simpleList', newArr);
                    that.set('showSimplePlayer', true);
                });
            },

            loadHighlight: function loadHighlight(m) {
                var that = this;
                var newArr = [];
                that.get('ajax').request('/watchHighlights?match=' + m.get('id')).then(function (arr) {
                    var ar = arr.array;
                    for (var i = 0; i < ar.length; i++) {
                        for (var b = 0; b < ar[i].actions.length; b++) {
                            newArr.push({
                                i: newArr.length,
                                action: ar[i].actions[b].tta.name,
                                actionId: ar[i].actions[b].action,
                                time: moment.utc(ar[i].actions[b].time).format('HH:mm:ss'),
                                timeTech: ar[i].actions[b].time,
                                gameId: ar[i].game._id,
                                half: ar[i].half,
                                video: ar[i].server.url + ar[i].video,
                                away: ar[i].game.away_team.name,
                                awayId: ar[i].game.away_team._id,
                                home: ar[i].game.home_team.name,
                                homeId: ar[i].game.home_team._id,
                                date: moment.utc(ar[i].game.date).format('L')
                            });
                        }
                    }
                    var sortedArr = _npmLodash['default'].orderBy(newArr, ['half', 'time'], ['asc', 'asc']);
                    that.set('simpleList', sortedArr);
                    that.set('showSimplePlayer', true);
                });
            },

            getMatches: function getMatches() {
                $('.matchLoading').removeClass('none');
                var that = this;
                var store = this.get('store');

                var q = {
                    startDate: this.get('startDate'),
                    endDate: this.get('endDate')
                };

                q[that.get('modelName')] = this.get("model.id");
                var c = this.get('currentCompetition');
                if (!q.startDate || !q.endDate) return;

                if (c && c.get("name") !== 'OVERALL') q.competition = c.get("id");

                store.query(that.get('modelQuery'), q).then(function (json) {
                    that.set('games', json);
                    $('.matchLoading').addClass('none');
                });
            },
            downloadJson: function downloadJson(modelId) {
                var that = this;
                that.get('ajax').request('/excelReport/' + modelId + '.xlsx').then(function (json) {
                    var dataStr = json;

                    var dlAnchorElem = document.getElementById('downloadAnchorElem');
                    dlAnchorElem.setAttribute("href", dataStr);
                    dlAnchorElem.setAttribute("download", modelId + ".xlsx");
                    dlAnchorElem.click();
                });
            }
        }
    });
});