define('msa/routes/reports/competition', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        beforeModel: function beforeModel(transition) {
            //if(!("countryId" in transition.queryParams)) {
            //    console.log("no property");
            //}
            var that = this;
            that.set('countryId', transition.queryParams.countryId);
        },

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', t: 'country', width: '16.6%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', t: 'competition', query: 'country', width: '16.6%', title: 'select league', step: 2 }), _ember['default'].Object.create({ model: 'team', t: 'team', query: 'competition', width: '16.6%', title: 'select team', step: 3 }), _ember['default'].Object.create({ model: 'players', t: 'chose-player', query: 'team', width: '16.6%', title: 'select player', step: 4 }), _ember['default'].Object.create({ model: 'games', t: 'game', query: 'player', width: '16.6%', title: 'select games', step: 5 }), _ember['default'].Object.create({ model: '', t: 'episode', query: '', width: '16.6%', title: 'select episodes', step: 6 })]);
        },

        setupController: function setupController(controller, model) {
            var countryId = this.get('countryId');
            controller.set('definedSteps', model);
            this.get('ajax').request('/country/' + countryId).then(function (countryData) {
                controller.set('country', countryData.country);
            });
            controller.setProperties({
                step: 2,
                team: false,
                competition: false,
                games: _ember['default'].A(),
                players: _ember['default'].A()
            });
        },

        actions: {
            renderStep: function renderStep(modelName, query, t) {
                var that = this;
                var country = that.get('countryId');
                that.get('ajax').request('/selectCompetition?country=' + country).then(function (json) {
                    that.render('outlets/select/competition', {
                        into: 'reports/competition',
                        outlet: 'steps',
                        model: json.team
                    });
                });
                return true;
            },

            setModel: function setModel(mn, m) {
                this.controllerFor("reports/competition").transitionToRoute('reports.team', { queryParams: { competitionId: m._id } });
            },

            backStep: function backStep(step) {
                var controller = this.controllerFor('reports/competition');
                if (step.model === "country") {
                    controller.transitionToRoute('reports.country');
                }
            }
        }

    });
});