define('msa/components/player-goals-list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        didInsertElement: function didInsertElement() {
            console.log("this.get('dataGoals')", this.get('dataGoals'));
        },
        actions: {
            openPlayer: function openPlayer(element) {
                this.sendAction('openActionPlayer', element.actionId);
            }
        }
    });
});