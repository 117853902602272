define('msa/mixins/is-avatar-mixin', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Mixin.create({

        session: service('session'),

        currentUser: service(),

        beforeModel: function beforeModel(transition) {
            console.log('transition.targetName', transition.targetName);
            var user = this.get('currentUser.user');
            var allowedRoutes = ['players.show.overview', 'players.show.full-matches', 'players.show.episodes-search', 'videolab'];
            if (user.get('avatar')) {
                if (allowedRoutes.indexOf(transition.targetName) === -1) {
                    //transition.abort();
                    this.transitionTo('players.show.overview', user.get('avatar'));
                }
            }
            return this._super.apply(this, arguments);
        },
        afterModel: function afterModel(resolvedModel, transition) {
            var that = this; // keep a reference for usage in then()
            var user = this.get('currentUser.user');
            transition.then(function () {
                var currentUrl = that.get('router.url');
                if (user.get('avatar')) {
                    if (!currentUrl.includes(user.get('avatar'))) {
                        that.transitionTo('players.show.overview', user.get('avatar'));
                    }
                }
            });
        }
    });
});