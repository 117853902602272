define('msa/components/several-actions-player', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        ajax: _ember['default'].inject.service(),
        episodesPlayer: _ember['default'].inject.service(),

        total: null,
        skip: 0,
        limit: 10,
        currentVideoLength: 10,

        getEpisodeParams: _ember['default'].observer('showVideoPlayer', function () {
            this.send('getVideos');
        }),

        buildPages: _ember['default'].computed('total,skip', function () {
            var sk = this.get('skip');
            var pagesCount = Math.ceil(this.get('total') / 10);
            var skip = sk / 10 - 1 > 3 ? sk / 10 - 3 : 0;
            var arr = [];
            //строим массив со страницами
            for (var i = skip; i < pagesCount; i++) {
                var cl = sk / 10 === i ? 'page active' : 'page';
                arr.push({
                    page: i + 1,
                    cl: cl
                });
            }
            //добавляем первую, если улистали далеко
            if (skip) {
                arr.unshift({
                    page: 1,
                    cl: 'page'
                }, {
                    page: '...',
                    cl: 'dot'
                });
            }
            //добавляем последнюю, если листаем в начале
            if (pagesCount > 6) {
                if (sk / 10 < arr[arr.length - 3].page) arr.splice(7, arr.length - 8, {
                    page: '...',
                    cl: 'dot'
                });
            }
            return arr;
        }),

        /*isFirst: true,
        isLast: false,*/
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            var playerId = this.$('#player1').closest('.mejs__container').attr('id');
            var player = mejs.players[playerId];
            player.remove();
        },
        didInsertElement: function didInsertElement() {
            var that = this;
            MediaElementPlayer.prototype.buildloop = function (player, controls, layers, media) {
                var loop = $('<div class="mejs-button mejs-loop-button"><select id="mejs-custom-select"> <option value="10">10</option> <option value="15">15</option><option value="20">20</option><option value="30">30</option><option value="60">60</option></select></div>').appendTo(controls).click(function () {
                    player.options.loop = !player.options.loop;
                    if (player.options.loop) {
                        loop.removeClass('mejs-loop-off').addClass('mejs-loop-on');
                    } else {
                        loop.removeClass('mejs-loop-on').addClass('mejs-loop-off');
                    }
                });
            };
            $('video, audio').mediaelementplayer({
                alwaysShowControls: true,
                features: ['loop', 'playpause', 'current', 'progress', 'fullscreen'],
                success: function success(player, node) {
                    player.addEventListener('ended', function (e) {
                        that.incrementProperty('currentVideo');
                        if (that.get('currentVideo') === that.get('list').length) {
                            if (that.get('skip') / 10 + 1 === Math.ceil(that.get('total') / 10)) {
                                that.send('nextPage', 1);
                            } else {
                                that.send('nextPage', +$('.pagination .active').html() + 1);
                            }
                        }

                        that.send('getVideo', that.get('list')[that.get('currentVideo')]);
                    });
                }
            });
            this.send('getVideos');
        },

        actions: {
            downloadVideo: function downloadVideo(m) {
                var link = document.createElement("a");
                link.download = m.home + '-' + m.away + ' ' + m.action + ' at ' + m.time;
                link.download.replace(':', '-');
                link.download += m.video.substring(m.video.lastIndexOf('.'));
                link.filename = link.download;
                link.href = m.videoURL;
                link.click();
            },
            addVideoToVideolab: function addVideoToVideolab(model) {
                var startEndTime = null;
                if (this.get('currentVideo') == model.i) {
                    startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(model.timeTech, model.additionalVideoLength, this.get('currentVideoLength') * 500);
                } else {
                    startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(model.timeTech, model.additionalVideoLength);
                }
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var name = model.home + " " + model.away + " " + model.playerName + " " + model.action;
                console.log(model);
                var videoFile = { name: name, url: model.video, action: model.actionId, startTime: startEndTime.startTime, endTime: startEndTime.endTime };
                this.get('ajax').request("/videofolder", {
                    method: 'POST',
                    data: JSON.stringify(videoFile),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    flashMessages.success("Video was added to Videolab");
                });
            },

            getVideo: function getVideo(m) {
                $('.videoLabWrap tr').removeClass('active');
                this.set('currentVideo', m.i);
                this.set('currentVideoLength', 10);
                var model = m;
                if (!model.video) return;
                $('#mejs-custom-select').off('change');
                $("#mejs-custom-select").val('10');
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                player.setSrc(model.videoURL);
                player.media.play();
                var that = this;
                $("#mejs-custom-select").on('change', function () {
                    that.set('currentVideoLength', this.value);
                    _ember['default'].set(model, 'videoURL', that.get('episodesPlayer').getVideoURL('', model.video, model.timeTech, model.videoLength, this.value * 500));
                    player.setSrc(model.videoURL);
                    player.media.play();
                });

                _ember['default'].run.later(function () {
                    $('*[data-video-id="' + m.i + '"] ').addClass('active');
                }, 100);
            },

            removeModal: function removeModal() {
                this.sendAction('closePlayer');
            },

            nextPage: function nextPage(m) {
                this.set('skip', (m - 1) * 10);
                this.send('getVideos');
            },

            getVideos: function getVideos() {
                var that = this;
                var actsIds = this.get('actionsArray');
                if (!actsIds) return;
                var queryAdress = '/gameReportGetActionEpisodes';
                that.get('ajax').request(queryAdress, { // GetVideos
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        actions: actsIds,
                        skip: that.get('skip'),
                        limit: 10
                    }),
                    dataType: 'json'
                }).then(function (json) {
                    var newArr = [];
                    json.actions.forEach(function (item, index) {
                        var timeTech = item.action.time;
                        newArr.push({
                            i: index,
                            //action: action.tta.name,
                            //standardTypeName: action.standardType ? action.standardType.name : '',
                            //isAttack: action.length !== undefined,
                            //playerName: action.player.first_name + " " + action.player.last_name,
                            additionalVideoLength: 0,
                            actionId: item.action._id,
                            time: moment.utc(timeTech).format('HH:mm:ss'),
                            timeTech: timeTech,
                            gameId: item.action.game,
                            half: item.action.half,
                            video: item.server.url + item.url,
                            //away: item.game.away_team.name,
                            //awayId: item.game.away_team._id,
                            //home: item.game.home_team.name,
                            //homeId: item.game.home_team._id,
                            //date: moment.utc(ar[i].game.date).format('L'),
                            videoURL: that.get('episodesPlayer').getVideoURL(item.server.url, item.url, timeTech, 0)
                        });
                    });

                    that.set('list', newArr);
                    that.send('getVideo', newArr[0]);
                    that.set('total', json.count);
                });
            }
        }
    });
});