define('msa/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {

        currentUser: service(),
        session: service(),

        beforeModel: function beforeModel() {
            // Detect browser compability
            // see http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
            // Safari 3.0+ "[object HTMLElementConstructor]"
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window['safari'] || safari.pushNotification);
            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;

            //TODO: Show beautiful modal dialog instead of alert.
            // https://github.com/yapplabs/ember-modal-dialog
            if (isSafari || isIE) {
                alert('Warning!\n\nMSA does not support this browser. We recommend you to use Chrome or Mozilla Firefox.');
            }

            return this._loadCurrentUser();
        },

        sessionAuthenticated: function sessionAuthenticated() {
            var _this = this;

            this._super.apply(this, arguments);
            this._loadCurrentUser()['catch'](function () {
                return _this.get('session').invalidate();
            });
        },

        _loadCurrentUser: function _loadCurrentUser() {
            return this.get('currentUser').load();
        },

        actions: {
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
            },

            getVideoPlayer: function getVideoPlayer() {
                var that = this;
                this.render('modals/videoPlayer', {
                    into: 'application',
                    outlet: 'video'
                });
            },

            removeModal: function removeModal(link, model) {
                this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },

            serialize: (function (_serialize) {
                function serialize(_x, _x2) {
                    return _serialize.apply(this, arguments);
                }

                serialize.toString = function () {
                    return _serialize.toString();
                };

                return serialize;
            })(function (obj, prefix) {
                var str = [],
                    p;
                for (p in obj) {
                    if (obj.hasOwnProperty(p)) {
                        var k = prefix ? prefix + "[" + p + "]" : p,
                            v = obj[p];
                        str.push(v !== null && typeof v === "object" ? serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
                    }
                }
                return str.join("&");
            }),

            addOrRemoveFromFavorites: function addOrRemoveFromFavorites(model, attr, type) {
                console.log('type', type);
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var userId = this.get('session.data.authenticated.id');
                var that = this;
                var mes = undefined;
                this.get('store').findRecord('user', userId, { reload: true }).then(function (u) {
                    if (type) {
                        u.get(attr).removeObject(model);
                        mes = 'Successfully removed';
                    }
                    if (!type) {
                        u.get(attr).addObject(model);
                        mes = 'Successfully added';
                    }
                    u.save().then(function (res) {
                        flashMessages.success(mes);
                    })['catch'](function (err) {
                        flashMessages.danger('Something went wrong!');
                    });
                });
            }
        }

    });
});