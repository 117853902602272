define('msa/components/episodes-player', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        ajax: _ember['default'].inject.service(),
        episodesPlayer: _ember['default'].inject.service(),

        total: null,
        skip: 0,
        limit: 10,
        currentVideoLength: 10,

        getEpisodeParams: _ember['default'].observer('videoPlayer', function () {
            this.send('getVideos');
        }),

        /*isFirst: true,
        isLast: false,*/

        buildPages: _ember['default'].computed('total,skip', function () {
            var sk = this.get('skip');
            var pagesCount = Math.ceil(this.get('total') / 10);
            var skip = sk / 10 - 1 > 3 ? sk / 10 - 3 : 0;
            var arr = [];
            //строим массив со страницами
            for (var i = skip; i < pagesCount; i++) {
                var cl = sk / 10 === i ? 'page active' : 'page';
                arr.push({
                    page: i + 1,
                    cl: cl
                });
            }
            //добавляем первую, если улистали далеко
            if (skip) {
                arr.unshift({
                    page: 1,
                    cl: 'page'
                }, {
                    page: '...',
                    cl: 'dot'
                });
            }
            //добавляем последнюю, если листаем в начале
            if (pagesCount > 6) {
                if (sk / 10 < arr[arr.length - 3].page) arr.splice(7, arr.length - 8, {
                    page: '...',
                    cl: 'dot'
                });
            }
            return arr;
        }),

        didInsertElement: function didInsertElement() {
            var that = this;
            MediaElementPlayer.prototype.buildloop = function (player, controls, layers, media) {
                var loop = $('<div class="mejs-button mejs-loop-button"><select id="mejs-custom-select"> <option value="10">10</option> <option value="15">15</option><option value="20">20</option><option value="30">30</option><option value="60">60</option></select></div>').appendTo(controls).click(function () {
                    player.options.loop = !player.options.loop;
                    if (player.options.loop) {
                        loop.removeClass('mejs-loop-off').addClass('mejs-loop-on');
                    } else {
                        loop.removeClass('mejs-loop-on').addClass('mejs-loop-off');
                    }
                });
            };
            $('video, audio').mediaelementplayer({
                alwaysShowControls: true,
                features: ['loop', 'playpause', 'current', 'progress', 'fullscreen'],
                success: function success(player, node) {
                    player.addEventListener('ended', function (e) {
                        that.incrementProperty('currentVideo');
                        if (that.get('currentVideo') === that.get('list').length) {
                            if (that.get('skip') / 10 + 1 === Math.ceil(that.get('total') / 10)) {
                                that.send('nextPage', 1);
                            } else {
                                that.send('nextPage', +$('.pagination .active').html() + 1);
                            }
                        }

                        that.send('getVideo', that.get('list')[that.get('currentVideo')]);
                    });
                }
            });
            this.send('getVideos');
        },

        actions: {
            downloadVideo: function downloadVideo(m) {
                var link = document.createElement("a");
                link.download = m.home + '-' + m.away + ' ' + m.action + ' at ' + m.time;
                link.download.replace(':', '-');
                link.download += m.video.substring(m.video.lastIndexOf('.'));
                link.filename = link.download;
                link.href = m.videoURL;
                link.click();
            },
            addVideoToVideolab: function addVideoToVideolab(model) {
                var startEndTime = null;
                if (this.get('currentVideo') == model.i) {
                    startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(model.timeTech, model.additionalVideoLength, this.get('currentVideoLength') * 500);
                } else {
                    startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(model.timeTech, model.additionalVideoLength);
                }
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                console.log(model);
                var name = model.home + " " + model.away + " " + model.playerName + " " + model.action;
                var videoFile = { name: name, url: model.serverURL + model.video, action: model.actionId, startTime: startEndTime.startTime, endTime: startEndTime.endTime };
                this.get('ajax').request("/videofolder", {
                    method: 'POST',
                    data: JSON.stringify(videoFile),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    flashMessages.success("Video was added to Videolab");
                });
            },

            getVideo: function getVideo(m) {
                $('.videoLabWrap tr').removeClass('active');
                this.set('currentVideo', m.i);
                this.set('currentVideoLength', 10);
                var model = m;
                if (!model.video) return;
                $('#mejs-custom-select').off('change');
                $("#mejs-custom-select").val('10');
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                player.setSrc(model.videoURL);
                player.media.play();
                var that = this;
                $("#mejs-custom-select").on('change', function () {
                    console.log("this.value getVideo");
                    that.set('currentVideoLength', this.value);
                    _ember['default'].set(model, 'videoURL', that.get('episodesPlayer').getVideoURL('', model.video, model.timeTech, model.videoLength, this.value * 500));
                    player.setSrc(model.videoURL);
                    player.media.play();
                });

                _ember['default'].run.later(function () {
                    $('*[data-video-id="' + m.i + '"] ').addClass('active');
                }, 100);
            },

            removeModal: function removeModal() {
                this.set('videoPlayer', false);
            },

            nextPage: function nextPage(m) {
                this.set('skip', (m - 1) * 10);
                this.send('getVideos');
            },

            getVideos: function getVideos() {
                var that = this;
                var getAdress = '/' + that.get('modelName') + 'GetVideos';
                if (that.get('modelName') === 'player' || that.get('modelName') === 'team') {
                    getAdress = '/' + that.get('modelName') + 'GetEpisodes';
                }
                // NEED TO FIX!!!
                if (that.get('modelName') === 'competition') {
                    getAdress = 'leagueGetEpisodes';
                }
                var s = that.get('coordsStart') || [];
                var e = that.get('coordsEnd') || [];
                var t = that.get('ttaToEpisodes').mapBy('id');
                var pl = !!that.get('players') ? that.get('players').mapBy('_id') : [];
                var gm = !!that.get('games') ? that.get('games').mapBy('_id') : [];
                var tm = !!that.get('team.id') ? [].push(that.get('team.id')) : [];
                var md = !!that.get('model.id') ? that.get('model.id') : false;
                var req = {
                    HSize: 6,
                    VSize: 3,
                    skip: that.get('skip'),
                    limit: 10
                };
                if (s.length) req.cords = s;
                if (e.length) req.target_cords = e;
                if (that.get('modelName') === 'player' && t.length) {
                    req.playerTTA = t;
                } else if (that.get('modelName') === 'team' && t.length || that.get('modelName') === 'competition' && t.length) {
                    req.teamTTA = t;
                } else if (t.length) {
                    req.tta = t;
                }
                if (pl.length) req.players = pl;
                if (that.get('startDate')) req.startDate = that.get('startDate');
                if (that.get('endDate')) req.endDate = that.get('endDate');
                if (gm.length) req.games = gm;
                if (tm.length) req.teams = tm;
                if (md.length) req[that.get('modelName')] = md;
                if (!!that.get("currentComptetition.name") && that.get("currentComptetition.name") !== 'OVERALL') req.competition = that.get('currentComptetition.id');

                that.get('ajax').request(getAdress, { // GetVideos
                    method: 'POST',
                    data: JSON.stringify(req),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    var ar = json.array;
                    var newArr = [];
                    console.log('tut chto-to');
                    for (var i = 0; i < ar.length; i++) {
                        for (var b = 0; b < ar[i].actions.length; b++) {
                            var timeTech = ar[i].actions[b].time;
                            var item = ar[i];
                            var action = ar[i].actions[b];
                            var additionalVideoLength = action.videoLength ? action.videoLength : 0;
                            newArr.push({
                                i: newArr.length,
                                action: action.tta.name,
                                standardTypeName: action.standardType ? action.standardType.name : '',
                                isAttack: action.length !== undefined,
                                playerName: action.player ? action.player.first_name + " " + action.player.last_name : '',
                                additionalVideoLength: additionalVideoLength,
                                actionId: action._id,
                                time: moment.utc(timeTech).format('HH:mm:ss'),
                                timeTech: timeTech,
                                gameId: item.game._id,
                                half: item.half,
                                video: item.video,
                                away: item.game.away_team.name,
                                awayId: item.game.away_team._id,
                                home: item.game.home_team.name,
                                homeId: item.game.home_team._id,
                                date: moment.utc(ar[i].game.date).format('L'),
                                serverURL: item.server.url,
                                videoURL: that.get('episodesPlayer').getVideoURL(item.server.url, item.video, timeTech, additionalVideoLength)
                            });
                        }
                    }
                    that.set('list', newArr);
                    that.send('getVideo', newArr[0]);
                    that.set('total', json.count);
                });
            },

            prev: function prev() {},

            getFullVideo: function getFullVideo(model) {
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                player.setSrc(model.video);
                player.media.load();
            }
        }
    });
});