define('msa/routes/reports/episode', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        beforeModel: function beforeModel(transition) {
            //if(!("countryId" in transition.queryParams)) {
            //    console.log("no property");
            //}
            var that = this;
            that.set('playersIds', transition.queryParams.playersIds.split(','));
            that.set('gamesIds', transition.queryParams.gamesIds.split(','));
            that.set('teamId', transition.queryParams.teamId);
        },

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', t: 'country', width: '16.6%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', t: 'competition', query: 'country', width: '16.6%', title: 'select league', step: 2 }), _ember['default'].Object.create({ model: 'team', t: 'team', query: 'competition', width: '16.6%', title: 'select team', step: 3 }), _ember['default'].Object.create({ model: 'players', t: 'chose-player', query: 'team', width: '16.6%', title: 'select player', step: 4 }), _ember['default'].Object.create({ model: 'games', t: 'game', query: 'player', width: '16.6%', title: 'select games', step: 5 }), _ember['default'].Object.create({ model: '', t: 'episode', query: '', width: '16.6%', title: 'select episodes', step: 6 })]);
        },

        setupController: function setupController(controller, model) {
            controller.set('definedSteps', model);
            var that = this;
            var teamId = this.get('teamId');
            var playersIds = that.get('playersIds');
            var gamesIds = that.get('gamesIds');
            var query = '?teams=' + teamId;
            for (var i = 0; i < playersIds.length; i++) {
                query += '&players=' + playersIds[i];
            }
            that.get('ajax').request('/reportGames' + query).then(function (gamesData) {
                var games = gamesData.games.filter(function (game) {
                    return gamesIds.indexOf(game._id) !== -1;
                });
                controller.set('games', games);
            });
            that.get('ajax').request('/reportPlayers?teams=' + teamId).then(function (playersData) {
                var players = playersData.players.filter(function (player) {
                    return playersIds.indexOf(player._id) !== -1;
                });
                controller.set('players', players);
            });
            that.get('ajax').request('/team/' + teamId).then(function (teamData) {
                that.get('ajax').request('/competition/' + teamData.team.competition).then(function (competitionData) {
                    that.get('ajax').request('/country/' + competitionData.competition.country).then(function (countryData) {
                        controller.set('competition', competitionData.competition);
                        controller.set('team', teamData.team);
                        controller.set('country', countryData.country);
                    });
                });
            });

            controller.setProperties({
                step: 6,
                team: false,
                country: false,
                competition: false,
                games: _ember['default'].A(),
                players: _ember['default'].A()
            });
        },

        actions: {
            renderStep: function renderStep(modelName, q, t) {
                return true;
            },

            backStep: function backStep(step) {
                var controller = this.controllerFor('reports/episode');
                var country = controller.get('country');
                var team = controller.get('team');
                var competition = controller.get('competition');
                var playersIds = this.get('playersIds');
                var gamesIds = this.get('gamesIds');
                if (step.model === "country") {
                    controller.transitionToRoute('reports.country');
                } else if (step.model === "competition") {
                    controller.transitionToRoute('reports.competition', { queryParams: { countryId: country._id } });
                } else if (step.model === "team") {
                    controller.transitionToRoute('reports.team', { queryParams: { competitionId: competition._id } });
                } else if (step.model === "players") {
                    controller.transitionToRoute('reports.player', { queryParams: { teamId: team._id } });
                } else if (step.model === "games") {
                    controller.transitionToRoute('reports.game', { queryParams: { playerId: playersIds } });
                }
            }
        }

    });
});