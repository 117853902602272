define('msa/components/progress-attributes', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: ['isActive'],
        isActive: (function () {
            return _ember['default'].isEmpty(this.get('value')) ? 'none' : '';
        }).property('value'),

        width: _ember['default'].computed('value', 'max', function () {
            var v = 0;
            if (this.get('min')) v = (this.get('value') - this.get('min')) * 100 / (this.get('max') - this.get('min'));else v = this.get('value') * 100 / this.get('max');
            return _ember['default'].String.htmlSafe("width: " + v + "%");
        }),

        didInsertElement: function didInsertElement() {
            var suffix = this.get('suffix') || '';
            this.set('v', Math.round(this.get('value') * 10) / 10 + suffix);
        }
    });
});