define('msa/components/player-report-challenges', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            this.prepareData();
            //Ember.run.later(this, this.drawLines, 1000);
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this.prepareData();
            //Ember.run.later(this, this.drawLines, 1000);
        },

        prepareData: function prepareData() {
            var that = this;
            this.$(".act-radio").off();
            this.$(".act-radio").click(function (event) {
                that.send('changeType', parseInt(event.target.dataset.id));
            });
            var fieldData = this.get('fieldData');
            var dataActions = _ember['default'].A();
            this.set('activeHalf', 1);
            var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
            dataActions.addObjects(filteredData);
            this.set('dataActions', dataActions);
            this.send('prepareCurrentTableData');
        },

        actions: {
            openPlayer: function openPlayer(element) {
                this.sendAction('openActionPlayer', element.actionId);
            },
            prepareCurrentTableData: function prepareCurrentTableData() {
                var half = this.get('activeHalf');
                var positionsTable = this.get('positionsTable');
                var activeTable = _npmLodash['default'].filter(positionsTable, { 'half': half });
                this.set('activeTable', activeTable[0]);
            },
            openModal: function openModal(acts) {
                if (acts.length > 0) {
                    this.set('actionsArray', acts);
                    this.set('showVideoPlayer', true);
                }
            },
            closePlayer: function closePlayer() {
                this.set('showVideoPlayer', false);
            },
            changeHalf: function changeHalf(half) {
                this.set('activeHalf', half);
                var fieldData = this.get('fieldData');
                var filteredData = null;
                if (half == 3) {
                    filteredData = fieldData;
                } else {
                    filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                }
                var dataActions = _ember['default'].A();
                dataActions.addObjects(filteredData);
                this.set('dataActions', dataActions);
                this.send('prepareCurrentTableData');
            }
        }
    });
});