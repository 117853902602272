define('msa/routes/referees/show/average-stats', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),
        refereeDataTransform: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', that.controllerFor('referees.show').get("model"));

            that.get('ajax').request('/refereeAverageStats?referee=' + that.controllerFor('referees.show').get("model.id")).then(function (p) {
                console.log(p.result);
                var dataTransform = that.get('refereeDataTransform');
                var enrichedData = p.result.map(function (data) {
                    return dataTransform.refereeDataEnrich(data);
                });

                controller.set('refereeAverageStats', enrichedData);
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                refereeAverageStats: null
            });
        }
    });
});