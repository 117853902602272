define("msa/components/init-component", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        didInsertElement: function didInsertElement() {

            if ($(".wrap_date_form").length) {
                var ofs = $(".wrap_date_form").offset().top;
                $(window).scroll(function () {
                    if ($(this).scrollTop() >= ofs) {
                        $('.wrap_date_form').addClass('fixed');
                    } else {
                        $('.wrap_date_form').removeClass('fixed');
                    }
                });
            }
        }
    });
});