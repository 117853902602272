define('msa/transforms/array', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _emberData['default'].Transform.extend({
		deserialize: function deserialize(serialized) {
			if (_ember['default'].isArray(serialized)) {
				return _ember['default'].A(serialized);
			} else {
				return _ember['default'].A();
			}
		},

		serialize: function serialize(deserialized) {
			if (_ember['default'].isArray(deserialized)) {
				return _ember['default'].A(deserialized);
			} else {
				return _ember['default'].A();
			}
		}
	});
});