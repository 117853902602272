define('msa/libs/type-shims', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var string = attr('string');
  exports.string = string;
  var number = attr('number');
  exports.number = number;
  var boolean = attr('boolean');
  exports.boolean = boolean;
  var date = attr('date');
  exports.date = date;
  var structure = attr();
  exports.structure = structure;
  var dateCustom = attr('date-custom');

  exports.dateCustom = dateCustom;
  var belongsTo = _emberData['default'].belongsTo;
  exports.belongsTo = belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports.hasMany = hasMany;
});