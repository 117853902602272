define('msa/components/position-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            var fieldData = this.get('fieldData');
            var currentCards = _ember['default'].A();
            this.set('activeHalf', 1);
            var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
            currentCards.addObjects(filteredData);
            this.set('currentCards', currentCards);
        },
        didRender: function didRender() {
            $('.position_card').unbind();
            $(".position_card").mouseover(function () {
                $(this).find(".card_tiptool").show();
            });
            $(".position_card").mouseout(function () {
                $(this).find(".card_tiptool").hide();
            });
        },
        actions: {
            changeHalf: function changeHalf(half) {
                console.log('half', half);
                this.set('activeHalf', half);
                if (half != 'total') {
                    half = half;
                }
                var fieldData = this.get('fieldData');
                var filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                console.log('filteredData', filteredData);
                var currentCards = _ember['default'].A();
                currentCards.addObjects(filteredData);
                this.set('currentCards', currentCards);
            }
        }
    });
});