define('msa/models/tta', ['exports', 'ember-data', 'msa/libs/type-shims'], function (exports, _emberData, _msaLibsTypeShims) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _msaLibsTypeShims.string,
		ttaGroup: _msaLibsTypeShims.string,
		cl: _msaLibsTypeShims.string,
		singular: _msaLibsTypeShims.string,
		plural: _msaLibsTypeShims.string,
		inQuery: _msaLibsTypeShims.boolean,
		visible: _msaLibsTypeShims.boolean

	});
});
//name: Ember.computed.reads('plural'),