define('msa/components/zone-breakdown', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    breakdownActions: null,
    didInsertElement: function didInsertElement() {
      var breakdownActions = this.get('breakdownActions');
      console.log('breakdownActions', breakdownActions);
      this.set('firstRow', breakdownActions[2]);
      this.set('secondRow', breakdownActions[1]);
      this.set('thirdRow', breakdownActions[0]);
      var that = this;

      var breakdownHeatMapActions = this.get('breakdownHeatMapActions');
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var heatmapInstance = h337.create({
          opacity: 0.6,
          radius: 12,
          container: this.$('.secondBreakdownTab')[0]
        });

        var points = [];
        var max = 0;
        var width = 500;
        var height = 330;
        breakdownHeatMapActions.forEach(function (item, index) {
          var point = {
            x: Math.floor(item.loc_x * width),
            y: Math.floor(item.loc_y * height),
            value: 20
          };

          points.push(point);
        });
        var data = {
          max: max,
          data: points
        };
        heatmapInstance.setData(data);
        that.set('heatmapInstance', heatmapInstance);
      });
    },
    actions: {
      changeTab: function changeTab(tabId) {
        if (tabId === 'firstBreakdownTab') {
          this.$('.secondBreakdownTab').hide();
          this.$('.firstBreakdownTab').show();
          this.$('.breakdowns-zone-tab').addClass('breakdowns-active-tab');
          this.$('.breakdowns-heat-tab').removeClass('breakdowns-active-tab');
        } else {
          this.$('.firstBreakdownTab').hide();
          this.$('.secondBreakdownTab').show();
          this.$('.breakdowns-heat-tab').addClass('breakdowns-active-tab');
          this.$('.breakdowns-zone-tab').removeClass('breakdowns-active-tab');
        }
      },
      setTabActionMap: function setTabActionMap(model, index) {
        this.set('activeTab', index);
        this.sendAction('setActionMap', model);
      },
      activeTab: function activeTab(index) {
        this.set('activeTab', index);
      },
      openModal: function openModal(acts) {
        if (acts.length > 0) {
          this.set('actionsArray', acts);
          this.set('showVideoPlayer', true);
        }
      },
      closePlayer: function closePlayer() {
        this.set('showVideoPlayer', false);
      }
    }
  });
});