define('msa/adapters/application', ['exports', 'ember', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'msa/config/environment'], function (exports, _ember, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _msaConfigEnvironment) {
	exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
		headers: _ember['default'].computed('session', function () {
			return {
				"x-access-token": this.get("session.data.authenticated.access_token")
			};
		}),
		authorizer: 'authorizer:oauth2',

		// coalesceFindRequests: true, 
		host: _msaConfigEnvironment['default'].host + '/ember',
		pathForType: function pathForType(modelName) {
			return _ember['default'].String.camelize(modelName);
		}
	});
});
/*shouldReloadAll: function(store, snapshotArray) {
 var snapshots = snapshotArray.snapshots();
	  return snapshots.any(function(ticketSnapshot) {
   var timeDiff = moment().diff(ticketSnapshot.attr('lastAccessedAt')).minutes();
   if (timeDiff > 10) {
     return true;
   } else {
     return false;
   }
 });
}*/