define('msa/components/goals-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            this.prepareData();
            _ember['default'].run.later(this, this.drawLines, 1000);
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this.prepareData();
            _ember['default'].run.later(this, this.drawLines, 1000);
        },

        prepareData: function prepareData() {
            var fieldData = this.get('fieldData');
            var currentGoals = _ember['default'].A();
            this.set('activeHalf', 1);
            var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
            currentGoals.addObjects(filteredData);
            this.set('currentGoals', currentGoals);
        },
        drawLines: function drawLines() {
            console.log('drawLines');
            var currentGoals = this.get('currentGoals');
            var elem = this.$('canvas.my_new_line')[0];
            console.log('elem', elem);
            var ctx = elem.getContext('2d');
            elem.width = this.$().width();
            elem.height = this.$().height();
            currentGoals.forEach(function (item, index) {
                var fromx = item.resultPlayerLeft * elem.width * 0.01,
                    fromy = item.resultPlayerTop * elem.height * 0.01,
                    tox = item.resultTargetLeft * elem.width * 0.01,
                    toy = item.resultTargetTop * elem.height * 0.01;
                var angle = Math.atan2(toy - fromy, tox - fromx);
                var headlen = 4;
                ctx.beginPath();
                ctx.lineWidth = 3;
                ctx.moveTo(fromx, fromy);
                ctx.lineTo(tox, toy);
                ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
                ctx.moveTo(tox, toy);
                ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
                ctx.strokeStyle = '#bace4d';
                ctx.stroke();
            });
        },
        actions: {
            changeHalf: function changeHalf(half) {
                console.log('half', half);
                this.set('activeHalf', half);
                var fieldData = this.get('fieldData');
                var filteredData = null;
                if (half === 0) {
                    filteredData = fieldData;
                } else {
                    filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                }
                var currentGoals = _ember['default'].A();
                currentGoals.addObjects(filteredData);
                this.set('currentGoals', currentGoals);
                this.drawLines();
            },
            openPlayer: function openPlayer(element) {
                this.sendAction('openActionPlayer', element.actionId);
            }
        }
    });
});