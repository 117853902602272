define('msa/controllers/leagues/show/statistic', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service(),

        ctrl: _ember['default'].inject.controller('leagues.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),

        leagueStatisticController: _ember['default'].observer('endDate', 'model.id', 'currentTta.id', function () {
            this.send('getStatistic');
        }),

        actions: {

            sortModelOver: function sortModelOver(i) {
                var that = this;
                var f = this.get('orFilter');
                var s = that.get('statisticTab').sort(function (a, b) {
                    if (!f) return a.row[i] - b.row[i];
                    if (f) return b.row[i] - a.row[i];
                });
                this.toggleProperty('orFilter');
                that.set('statisticTab', null);
                that.set('statisticTab', s);
            },

            getStatistic: function getStatistic() {
                var that = this;
                var st = this.get('startDate');
                var end = this.get('endDate');
                var tta = this.get('currentTta.id');
                if (!st || !end || !tta) return;
                $('.stLoading').removeClass('none');
                that.get('ajax').request('/leagueStatisticv2?competition=' + this.get("model.id") + '&teamTTA=' + tta + '&startDate=' + this.get("startDate") + '&endDate=' + this.get("endDate")).then(function (json) {
                    var preparedArr = [];
                    json.leagueStatistic.forEach(function (item, i, arr) {
                        if (item.team != null) {
                            preparedArr.push(item);
                        }
                    });
                    var newResp = preparedArr.map(function (d, i) {
                        d.i = ++i;
                        var total = d.row.reduce(function (a, b) {
                            return a + b;
                        }, 0);
                        d.row.push(total);
                        return d;
                    });
                    that.set('statisticTab', newResp);
                    $('.stLoading').addClass('none');
                });
            },

            setCurrentTta: function setCurrentTta(m) {
                console.log('setCurrentTTA');
                console.log(m);
                this.set('currentTta', m);
            }
        }
    });
});