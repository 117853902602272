define('msa/components/post-match-report-menu', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        willRender: function willRender() {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                console.log('afterRender1111');
                if ($(window).width() < 1660) {
                    $('.toggle_menu_link').unbind();
                    $('.toggle_menu_link').on('click', function (e) {
                        //console.log('click there');
                        e.preventDefault();
                        $('.wrap_menu_list').toggleClass('animated fadeInLeft');
                        $(this).toggleClass('active');
                    });
                }
                $('.menu_list_item a').unbind();
                $('.menu_list_item a').on('click', function (e) {
                    e.preventDefault();
                    $('.menu_list_item a').removeClass('active');
                    $(this).addClass('active');
                    $(this).siblings('.menu_sublist').slideToggle();
                });

                $('.content_page__tab-content__item a').unbind();
                $('.content_page__tab-content__item a').on('click', function (e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    $(this).siblings('.inner').slideToggle();
                });
            });
        }
    });
});