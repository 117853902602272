define('msa/routes/post-match-report/attacks/touches-in-the-pbox', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            var gameModel = that.controllerFor('post-match-report.attacks').get("model");
            controller.set('model', gameModel);
            var requiredTttas = [52];
            that.get('ajax').request('/stadium/' + model.get("stadium.id")).then(function (stadiumData) {
                that.store.findAll('teamTTA').then(function (ttas) {
                    var filteredTtas = ttas.filter(function (item) {
                        return requiredTttas.indexOf(item.get('orderIndex')) > -1;
                    });
                    var query = '';
                    filteredTtas.forEach(function (tta, index) {
                        query += '&teamTTA=' + tta.get('id');
                    });
                    var homeTeamId = gameModel.get('home_team.id');
                    var awayTeamId = gameModel.get('away_team.id');
                    that.get('ajax').request('/gameReportCountBars?game=' + gameModel.get('id') + query).then(function (json) {
                        var setPieceActions = that.get('postMatchService').prepareCountBarsData(json, homeTeamId, awayTeamId);
                        controller.set('setPieceActions', setPieceActions);
                    });
                    that.get('ajax').request('/gameReportTimeBars?game=' + gameModel.get('id') + query).then(function (json) {
                        if (json.length == 0) return;
                        var homeTeamItem = null;
                        var awayTeamItem = null;
                        var maxNumber = that.get('postMatchService').getMaxNumber(json);
                        var scaleValues = that.get('postMatchService').getScaleValues(json);
                        controller.set('summaryScaleValues', scaleValues);
                        json.forEach(function (item, index) {
                            if (item.team._id === homeTeamId) {
                                homeTeamItem = item;
                            }
                            if (item.team._id === awayTeamId) {
                                awayTeamItem = item;
                            }
                        });
                        var timesArray = ['0-15', '15-30', '30-45', '45-60', '60-75', '75-90'];
                        var preparedData = [];
                        timesArray.forEach(function (item, index) {
                            var homeHeight = (homeTeamItem ? homeTeamItem.row[index] : 0) / maxNumber * 80;
                            var awayHeight = (awayTeamItem ? awaitTeamItem.row[index] : 0) / maxNumber * 80;
                            preparedData.push({ period: item, homeTeamHeight: homeHeight, awayTeamHeight: awayHeight });
                        });
                        controller.set('summaryHistogramData', preparedData);
                        var playerTtas = [35];
                        that.store.findAll('playerTTA').then(function (pTtas) {
                            var filteredPlayersTtas = pTtas.filter(function (item) {
                                return playerTtas.indexOf(item.get('orderIndex')) > -1;
                            });
                            var ttaPlayersListQuery = '';
                            filteredPlayersTtas.forEach(function (tta, index) {
                                ttaPlayersListQuery += '&playerTTA=' + tta.get('id');
                            });
                            var homePlayersListQuery = ttaPlayersListQuery + '&team=' + homeTeamId;
                            that.get('ajax').request('/gameReportActionsByPlayers?game=' + gameModel.get('id') + homePlayersListQuery).then(function (json) {
                                var homePlayersList = that.get('postMatchService').prepareReportActionsByPlayers(json);
                                controller.set('homePlayersList', homePlayersList);
                            });
                            var awayPlayersListQuery = ttaPlayersListQuery + '&team=' + awayTeamId;
                            that.get('ajax').request('/gameReportActionsByPlayers?game=' + gameModel.get('id') + awayPlayersListQuery).then(function (json) {
                                var awayPlayersList = that.get('postMatchService').prepareReportActionsByPlayers(json);
                                controller.set('awayPlayersList', awayPlayersList);
                            });
                            that.get('ajax').request('/gameReportActions?game=' + model.get('id') + query).then(function (json) {
                                var groupedByTeam = _npmLodash['default'].groupBy(json, 'team');
                                if (groupedByTeam[homeTeamId]) {
                                    var homeTouches = that.get('postMatchService').prepareTouches(groupedByTeam[homeTeamId], stadiumData.stadium.width, stadiumData.stadium.length);
                                    console.log(homeTouches);
                                    controller.set('homeTouches', homeTouches);
                                }
                                if (groupedByTeam[awayTeamId]) {
                                    var awayTouches = that.get('postMatchService').prepareTouches(groupedByTeam[awayTeamId], stadiumData.stadium.width, stadiumData.stadium.length);
                                    controller.set('awayTouches', awayTouches);
                                }
                            });
                        });
                    });
                });
            });
        }
    });
});