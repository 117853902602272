define('msa/components/js-carusel-right', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            var width = $('body').width();
            $('.slides').slick({
                infinite: true,
                slidesToScroll: 3
            });
        }
    });
});