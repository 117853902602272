define('msa/controllers/outlets/select/chose-player', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Controller.extend({

        sortAmpluas: _ember['default'].observer('model.[]', function () {
            this.send('getSortAmpluas');
        }),

        report: _ember['default'].inject.controller(),

        actions: {
            getSortAmpluas: function getSortAmpluas() {
                //this.send('sortPlayers')
                console.log("this.get('parentController.current.id')", this.get('video-player'));
                var obj = {},
                    arr = [];
                var that = this;
                this.store.findAll('player-amplua').then(function (a) {
                    //console.log('a', a);
                    that.get('model').map(function (m, i) {
                        var amp = m.amplua;
                        obj[amp] = obj[amp] || [];
                        obj[amp].push(m);
                    });

                    for (var i in obj) {
                        arr.push({
                            name: i !== 'undefined' ? that.store.peekRecord('player-amplua', i).get('plural') : 'Other',
                            arr: obj[i]
                        });
                    }

                    a.forEach(function (playerAmpluaItem) {
                        for (var _i = 0; _i < arr.length; _i++) {
                            var currentItem = arr[_i];
                            if (currentItem.name == playerAmpluaItem.get('plural')) {
                                currentItem.orderIndex = playerAmpluaItem.get('orderIndex');
                            }
                        }
                    });
                    var sortedArray = _npmLodash['default'].sortBy(arr, ['orderIndex']);
                    that.set('newModel', sortedArray);
                });
            }
        }
    });
});