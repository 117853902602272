define('msa/routes/player-statistics/player', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        beforeModel: function beforeModel(transition) {
            //if(!("countryId" in transition.queryParams)) {
            //    console.log("no property");
            //}
            this.set('teamId', transition.queryParams.teamId);
        },

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', width: '25%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', query: 'country', width: '25%', title: 'select league', step: 2 }), _ember['default'].Object.create({ model: 'team', query: 'competition', width: '25%', title: 'select team', step: 3 }), _ember['default'].Object.create({ model: 'player', query: 'team', width: '25%', title: 'select player', step: 4 })]);
        },

        setupController: function setupController(controller, model) {
            controller.set('definedSteps', model);
            var that = this;
            var teamId = this.get('teamId');
            that.get('ajax').request('/team/' + teamId).then(function (teamData) {
                controller.set('team', teamData.team);
                that.get('ajax').request('/competition/' + teamData.team.competition).then(function (competitionData) {
                    controller.set('competition', competitionData.competition);
                    that.get('ajax').request('/country/' + competitionData.competition.country).then(function (countryData) {
                        controller.set('country', countryData.country);
                    });
                });
            });
            controller.setProperties({
                step: 4,
                team: false,
                country: false,
                competition: false,
                players: _ember['default'].A()
            });
        },

        actions: {
            renderStep: function renderStep(modelName, query, t) {
                var that = this;
                var teamId = that.get('teamId');
                this.render('outlets/select/player', {
                    into: 'player-statistics/player',
                    outlet: 'steps',
                    model: that.store.query(modelName, { team: teamId }),
                    controller: 'outlets/select/player'
                });
                return true;
            },

            setModel: function setModel(modelName, model) {
                this.transitionTo('players.show', model);
            },

            backStep: function backStep(step) {
                var controller = this.controllerFor('player-statistics/player');
                var country = controller.get('country');
                var competition = controller.get('competition');
                if (step.model === "country") {
                    controller.transitionToRoute('player-statistics.country');
                } else if (step.model === "competition") {
                    controller.transitionToRoute('player-statistics.competition', { queryParams: { countryId: country._id } });
                } else if (step.model === "team") {
                    controller.transitionToRoute('player-statistics.team', { queryParams: { competitionId: competition._id } });
                }
            }

        }

    });
});