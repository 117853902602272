define('msa/routes/post-match-report/possession', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            return this.store.findRecord('game', params.m_id);
        },

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', model);
            var requiredTttas = [21, 22, 23, 24, 25, 26];
            that.store.findAll('teamTTA').then(function (ttas) {
                var filteredTtas = ttas.filter(function (item) {
                    return requiredTttas.indexOf(item.get('orderIndex')) > -1;
                });
                var query = '';
                filteredTtas.forEach(function (tta, index) {
                    query += '&teamTTA=' + tta.get('id');
                });
                var homeTeamId = model.get('home_team.id');
                var awayTeamId = model.get('away_team.id');
                that.get('ajax').request('/gameReportCountBars?game=' + model.get('id') + query).then(function (json) {
                    var possessionFieldAction = null;
                    var timePossesIndex = null;
                    var setPieceActions = that.get('postMatchService').prepareCountBarsData(json, homeTeamId, awayTeamId);
                    setPieceActions.forEach(function (action, index) {
                        if (action.ttaName === "Possession (time, ms)") {
                            possessionFieldAction = action;
                            timePossesIndex = index;
                        }
                    });

                    var timePossesItem = _npmLodash['default'].find(setPieceActions, { 'ttaName': "Possession (time, ms)" });
                    setPieceActions.splice(timePossesIndex, 1);
                    controller.set('setPieceActions', setPieceActions);
                    controller.set('timePossesItem', timePossesItem);

                    controller.set('possessionFieldAction', possessionFieldAction);
                });
                var totalTtaQuery = '';
                filteredTtas.forEach(function (tta, index) {
                    if (tta.get('orderIndex') === 23) {
                        totalTtaQuery = '&teamTTA=' + tta.get('id');
                    }
                });
                that.get('ajax').request('/gameReportTimeBars?game=' + model.get('id') + totalTtaQuery).then(function (json) {
                    if (json.length == 0) return;
                    var homeTeamItem = null;
                    var awayTeamItem = null;
                    var maxNumber = that.get('postMatchService').getMaxNumber(json);
                    var scaleValues = that.get('postMatchService').getScaleValues(json);
                    controller.set('summaryScaleValues', scaleValues);
                    json.forEach(function (item, index) {
                        if (item.team._id === homeTeamId) {
                            homeTeamItem = item;
                        }
                        if (item.team._id === awayTeamId) {
                            awayTeamItem = item;
                        }
                    });
                    var timesArray = ['0-15', '15-30', '30-45', '45-60', '60-75', '75-90'];
                    var preparedData = [];
                    timesArray.forEach(function (item, index) {
                        var homeHeight = homeTeamItem.row[index] / maxNumber * 88;
                        var awayHeight = awayTeamItem.row[index] / maxNumber * 88;
                        preparedData.push({ period: item, homeTeamHeight: homeHeight, awayTeamHeight: awayHeight });
                    });
                    controller.set('summaryHistogramData', preparedData);
                });
            });
        }
    });
});