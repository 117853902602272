define('msa/controllers/leagues/show/overview', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        getOverview: _ember['default'].observer('endDate', 'model.id', function () {
            this.send('getLeagueOverview');
        }),

        ajax: _ember['default'].inject.service(),

        ctrl: _ember['default'].inject.controller('leagues.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),
        startColumn: 'active false',

        actions: {

            sortModel: function sortModel(model, row) {
                var f = this.get('orFilter');
                var m = this.get(model).sort(function (a, b) {
                    var a = +a[row] || 0;
                    var b = +b[row] || 0;
                    if (!f) return a - b;
                    if (f) return b - a;
                });

                this.toggleProperty('orFilter');
                this.set(model, null);
                this.set(model, m);
                this.set('startColumn', '');
            },

            getLeagueOverview: function getLeagueOverview(q) {
                $('.overviewLoading').removeClass('none');

                var q = {
                    competition: this.get("model.id"),
                    startDate: this.get('startDate'),
                    endDate: this.get('endDate')
                };

                if (!q.competition || !q.startDate || !q.endDate) return;

                var that = this;
                var newOver = _ember['default'].A();
                that.get('ajax').request('/leagueOverview?competition=' + q.competition + '&startDate=' + q.startDate + '&endDate=' + q.endDate).then(function (json) {
                    for (var i in json) {
                        if (i !== 'table') newOver.pushObject({ name: i, total: json[i].total, team: json[i]._id });
                    }

                    that.set('overview', newOver.map(function (m) {
                        if (m.name === 'penalties') m.fName = 'Penalties';
                        if (m.name === 'challengeTop') m.fName = 'Air Challenge';
                        if (m.name === 'yellowCard') m.fName = 'Yellow Cards';
                        if (m.name === 'redCard') m.fName = 'Red Cards';

                        return m;
                    }));

                    var getMax = function getMax(arr, row) {
                        var max = 0;
                        var resp = [];
                        for (i = 0; i < arr.length; i++) {
                            var item = arr[i];
                            if (arr[i][row] > max) {
                                resp = [];
                                max = arr[i][row];
                            }

                            if (arr[i][row] === max) {
                                //arr[i].t = arr[i][row];
                                resp.push({ t: arr[i][row], team: item.team, array: item.array });
                            }
                        }
                        return resp;
                    };
                    var response = [{
                        name: 'Home form',
                        arr: getMax(json.table, 'maxRowHomeWins')
                    }, {
                        name: 'Away form',
                        arr: getMax(json.table, 'maxRowAwayWins')
                    }, {
                        name: 'Games Won in a Row',
                        arr: getMax(json.table, 'maxRowWins')
                    }, {
                        name: 'Games Lost in a Row',
                        arr: getMax(json.table, 'maxRowLooses')
                    }, {
                        name: 'Games Scored in a Row',
                        arr: getMax(json.table, 'maxRowScored')
                    }];

                    for (var i = 0; i < json.table.length; i++) {
                        json.table[i].summ = json.table[i].array.length;

                        json.table[i].array = json.table[i].array.slice(0, 5);
                        var newAr = [];
                        var maxT = 0;
                        for (var t = 0; t < 5; t++) {
                            if (!isNaN(json.table[i].array[4 - t])) newAr.push(json.table[i].array[4 - t]);
                            if (json.table[i].array[t] === 3) maxT++;
                        }

                        json.table[i].maxT = maxT;
                        json.table[i].array = newAr;
                    }

                    var xr = getMax(json.table, 'maxT');
                    for (var i = 0; i < 5; i++) {
                        var arr = [];
                        for (var b = 0; b < xr.length; b++) {
                            if (xr[b].array[i] === 3) arr.push(xr[b]);
                        }

                        if (i === 4 || arr.length === 1) {
                            xr = arr;
                            break;
                        }
                    };

                    that.set('formSec', {
                        name: 'Form',
                        arr: xr
                    });
                    var newResp = json.table.map(function (d, i) {
                        d.i = ++i;
                        return d;
                    });
                    that.set('main', newResp);
                    that.set('second', response);

                    $('.overviewLoading').addClass('none');
                });
            }
        }
    });
});