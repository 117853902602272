define('msa/routes/players/show', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            return this.store.findRecord('player', params.m_id);
        },

        ajax: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', model);
            that.get('ajax').request('/playerSeasons?player=' + model.get("id")).then(function (d) {
                controller.set('seasons', d.seasons);
                controller.set('currentSeason', d.seasons.get('lastObject'));
                controller.set('startDate', d.seasons.get('lastObject.startDate'));
                controller.set('endDate', d.seasons.get('lastObject.endDate'));
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                endDate: null,
                startDate: null,
                ttaToEpisodes: []
            });
        }
    });
});