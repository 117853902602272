define('msa/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        ajax: _ember['default'].inject.service(),

        fastSearch: _ember['default'].observer('sText', function () {

            var that = this;

            if (!this.get('sText') || this.get('sText.length') < 3) {
                that.set('fSearch', _ember['default'].A());
                return;
            }

            that.get('ajax').request('/searchGlobal?search=' + that.get('sText')).then(function (json) {
                var arr = [];
                for (var i = 0; i < 100; i++) {
                    if (json.players[i]) arr.push({
                        id: json.players[i]._id,
                        name: json.players[i].first_name + ' ' + json.players[i].last_name,
                        img: json.players[i].photo,
                        link: 'players.show'
                    });
                    if (json.teams[i]) arr.push({
                        id: json.teams[i]._id,
                        name: json.teams[i].name,
                        img: json.teams[i].logo,
                        link: 'teams.show'
                    });
                    if (json.competitions[i]) arr.push({
                        id: json.competitions[i]._id,
                        name: json.competitions[i].name,
                        img: json.competitions[i].logo,
                        link: 'leagues.show'
                    });
                    if (arr.length >= 7) break;
                }

                that.set('fSearch', arr);
            });
        }),

        actions: {
            clearSearch: function clearSearch() {
                this.set('sText', '');
            },

            redirectFromSerach: function redirectFromSerach(m) {
                this.transitionToRoute(m.link, m.id);
                this.set('sText', '');
            }
        }
    });
});