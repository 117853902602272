define('msa/components/action-player', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        ajax: _ember['default'].inject.service(),
        episodesPlayer: _ember['default'].inject.service(),

        total: null,
        skip: 0,
        limit: 10,
        currentVideoLength: 10,

        getEpisodeParams: _ember['default'].observer('showVideoPlayer', function () {
            this.send('getVideos');
        }),

        didInsertElement: function didInsertElement() {
            var that = this;
            MediaElementPlayer.prototype.buildloop = function (player, controls, layers, media) {
                var loop = $('<div class="mejs-button mejs-loop-button"><select id="mejs-custom-select"> <option value="10">10</option> <option value="15">15</option><option value="20">20</option><option value="30">30</option><option value="60">60</option></select></div>').appendTo(controls).click(function () {
                    player.options.loop = !player.options.loop;
                    if (player.options.loop) {
                        loop.removeClass('mejs-loop-off').addClass('mejs-loop-on');
                    } else {
                        loop.removeClass('mejs-loop-on').addClass('mejs-loop-off');
                    }
                });
            };
            $('video, audio').mediaelementplayer({
                alwaysShowControls: true,
                features: ['loop', 'playpause', 'current', 'progress', 'fullscreen'],
                success: function success(player, node) {
                    player.addEventListener('ended', function (e) {
                        that.incrementProperty('currentVideo');
                        if (that.get('currentVideo') === that.get('list').length) {
                            if (that.get('skip') / 10 + 1 === Math.ceil(that.get('total') / 10)) {
                                that.send('nextPage', 1);
                            } else {
                                that.send('nextPage', +$('.pagination .active').html() + 1);
                            }
                        }

                        that.send('getVideo', that.get('list')[that.get('currentVideo')]);
                    });
                }
            });
            this.send('getVideos');
        },

        actions: {
            downloadVideo: function downloadVideo(m) {
                var link = document.createElement("a");
                console.log(m);
                var model = this.get('list')[0];
                link.download = model.home + '-' + model.away + ' ' + model.action + ' at ' + model.time;
                link.download.replace(':', '-');
                link.download += model.video.substring(model.video.lastIndexOf('.'));
                link.filename = link.download;
                link.href = model.videoURL;
                link.click();
            },
            addVideoToVideolab: function addVideoToVideolab(model) {
                var startEndTime = null;
                var obj = this.get('list')[0];
                console.log(this.get('currentVideo'));
                if (obj && this.get('currentVideo') == obj.i) {
                    startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(obj.timeTech, obj.additionalVideoLength, this.get('currentVideoLength') * 500);
                } else {
                    startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(obj.timeTech, obj.additionalVideoLength);
                }
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var name = obj.home + " " + obj.away + " " + obj.playerName + " " + obj.action;
                console.log('modell', model);
                var videoFile = { name: name, url: obj.video, action: obj.actionId, startTime: startEndTime.startTime, endTime: startEndTime.endTime };
                this.get('ajax').request("/videofolder", {
                    method: 'POST',
                    data: JSON.stringify(videoFile),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    flashMessages.success("Video was added to Videolab");
                });
            },

            getVideo: function getVideo(m) {
                $('.videoLabWrap tr').removeClass('active');
                this.set('currentVideo', m.i);
                this.set('currentVideoLength', 10);
                var model = this.get('list')[0];
                if (!model.video) return;
                $('#mejs-custom-select').off('change');
                $("#mejs-custom-select").val('10');
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                player.setSrc(model.videoURL);
                player.media.play();
                var that = this;
                $("#mejs-custom-select").on('change', function () {
                    console.log("this.value getVideo");
                    that.set('currentVideoLength', this.value);
                    _ember['default'].set(model, 'videoURL', that.get('episodesPlayer').getVideoURL('', model.video, model.timeTech, model.videoLength, this.value * 500));
                    player.setSrc(model.videoURL);
                    player.media.play();
                });

                _ember['default'].run.later(function () {
                    $('*[data-video-id="' + m.i + '"] ').addClass('active');
                }, 100);
            },

            removeModal: function removeModal() {
                this.set('showVideoPlayer', false);
            },

            nextPage: function nextPage(m) {
                this.set('skip', (m - 1) * 10);
                this.send('getVideos');
            },

            getVideos: function getVideos() {
                var that = this;
                var actionId = this.get('actionId');
                var getAdress = '/gameReportGetActionEpisode/' + actionId;
                that.get('ajax').request(getAdress, { // GetVideos
                    method: 'GET',
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    console.log("json get", json);
                    var timeTech = json.action.time;
                    var newArr = [];
                    newArr.push({
                        i: 0,
                        //action: action.tta.name,
                        //standardTypeName: action.standardType ? action.standardType.name : '',
                        //isAttack: action.length !== undefined,
                        //playerName: action.player.first_name + " " + action.player.last_name,
                        additionalVideoLength: 0,
                        actionId: json.action._id,
                        time: moment.utc(timeTech).format('HH:mm:ss'),
                        timeTech: timeTech,
                        gameId: json.action.game,
                        half: json.action.half,
                        video: json.server.url + json.url,

                        videoURL: that.get('episodesPlayer').getVideoURL(json.server.url, json.url, timeTech, 0)
                    });
                    that.set('list', newArr);
                    that.send('getVideo', newArr[0]);
                    that.set('total', newArr.length);
                });
            }
        }
    });
});