define('msa/helpers/pagination-icon', ['exports', 'ember-data-table/helpers/pagination-icon'], function (exports, _emberDataTableHelpersPaginationIcon) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDataTableHelpersPaginationIcon['default'];
    }
  });
  Object.defineProperty(exports, 'paginationIcon', {
    enumerable: true,
    get: function get() {
      return _emberDataTableHelpersPaginationIcon.paginationIcon;
    }
  });
});