define('msa/components/dropdown-button', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        ajax: _ember['default'].inject.service(),

        didInsertElement: function didInsertElement() {
            var that = this;
            var m = that.get('model');
            var videoParts = [];
            that.get('ajax').request('/watchFullMatch?match=' + m.get('id')).then(function (ar) {
                for (var i = 0; i < ar.length; i++) {
                    videoParts.push({
                        half: ar[i].half,
                        video: ar[i].server.url + ar[i].url
                    });
                }
                that.set('halfList', videoParts);
            });
        },

        actions: {
            showDD: function showDD(id) {
                $('.dropdown-button-toggled:not([data-id=' + id + '])').slideUp(); // First close all other DDs
                $('.dropdown-button-toggled[data-id=' + id + ']').slideToggle();
            }
        }
    });
});