define('msa/controllers/teams/compare', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Controller.extend({

        ajax: _ember['default'].inject.service(),
        teamService: _ember['default'].inject.service(),
        formationlm: [],
        formationrm: [],

        currentTab: 'overview',

        seasons: _ember['default'].A([{
            name: 2018,
            startDate: "2018-01-01T00:00:00.000Z",
            endDate: "2018-12-31T00:00:00.000Z"
        }, {
            name: 2019,
            startDate: "2019-01-01T00:00:00.000Z",
            endDate: "2019-12-31T00:00:00.000Z"
        }, {
            name: 2020,
            startDate: "2020-01-01T00:00:00.000Z",
            endDate: "2020-12-31T00:00:00.000Z"
        }]),

        /*getCustomDates: Ember.observer('endDate', function() {
           //this.send('findDataByDate');
        }), */

        actions: {
            changeCurrentTab: function changeCurrentTab(tabname) {
                this.set('currentTab', tabname);
                this.send('findDataByDate');
            },
            changeRightSeason: function changeRightSeason(m) {
                var that = this;
                $('.right-season').removeClass('active');
                $('.right-season' + m.name).addClass('active');
                this.set('rightStartDate', m.startDate);
                this.set('rightEndDate', m.endDate);
                this.set('rightStart', '');
                this.set('rightEnd', '');
                this.send('findDataByDate');
            },

            changeLeftSeason: function changeLeftSeason(m) {
                var that = this;
                $('.left-season').removeClass('active');
                $('.left-season' + m.name).addClass('active');
                this.set('leftStartDate', m.startDate);
                this.set('leftEndDate', m.endDate);
                this.set('leftStart', '');
                this.set('leftEnd', '');
                this.send('findDataByDate');
            },

            findCustomRightDates: function findCustomRightDates() {
                $('.right-season').removeClass('active');
                var s = moment(this.get('rightStart')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(this.get('rightEnd')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                this.set('rightStartDate', s);
                this.set('rightEndDate', e);
                this.send('findDataByDate');
            },

            findCustomLeftDates: function findCustomLeftDates() {
                $('.left-season').removeClass('active');
                var s = moment(this.get('leftStart')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(this.get('leftEnd')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                this.set('leftStartDate', s);
                this.set('leftEndDate', e);
                this.send('findDataByDate');
            },

            findDataByDate: function findDataByDate() {
                var that = this;
                var currentTab = this.get('currentTab');
                this.send('getAveragePlayers');
                if (currentTab == 'overview') {
                    this.send('getOverview');
                } else if (currentTab == 'statistics') {
                    this.send('getsAttributes');
                } else if (currentTab == 'formation') {
                    this.send('getFormation');
                }
            },

            addRight: function addRight(m) {
                this.set('rm', m);
                this.send('findDataByDate');
            },

            addLeft: function addLeft(m) {
                this.set('lm', m);
                this.send('findDataByDate');
            },

            addTta: function addTta(t) {
                var l = this.get('ttaToSearch');
                if (l.includes(t)) {
                    l.removeObject(t);
                    t.set('inQuery', false);
                } else {
                    l.addObject(t);
                    t.set('inQuery', true);
                }
                this.set('comparePerf', null);
                this.send('getsAttributes');
            },

            getAveragePlayers: function getAveragePlayers() {
                var that = this;
                var rm = this.get('rm.id');
                var lm = this.get('lm.id');
                that.get('ajax').request('/teamAveragePlayerData?team=' + rm).then(function (json) {
                    var s = json.teamAveragePlayerData && json.teamAveragePlayerData.aWeight ? +json.teamAveragePlayerData.aWeight.toFixed(1) : '-';
                    var b = json.teamAveragePlayerData && json.teamAveragePlayerData.aGrowth ? +json.teamAveragePlayerData.aGrowth.toFixed(1) : '-';
                    var a = json.teamAveragePlayerData && json.teamAveragePlayerData.age ? +json.teamAveragePlayerData.aAge.toFixed(1) : '-';
                    that.set('rm.age', a);
                    that.set('rm.weight', s);
                    that.set('rm.lbs', s !== '-' ? (s * 2.20462).toFixed(1) : '-');
                    that.set('rm.fut', b !== '-' ? Math.floor(b * 0.0328084) : '-');
                    that.set('rm.inch', b !== '-' ? (((b * 0.0328084).toFixed(1) - Math.floor(b * 0.0328084)) * 10).toFixed(1) : '-');
                    that.set('rm.growth', b !== '-' ? b.toFixed(1) : '-');
                });

                that.get('ajax').request('/teamAveragePlayerData?team=' + lm).then(function (json) {
                    var s = json.teamAveragePlayerData && json.teamAveragePlayerData.aWeight ? json.teamAveragePlayerData.aWeight.toFixed(1) : '-';
                    var b = json.teamAveragePlayerData && json.teamAveragePlayerData.aGrowth ? +json.teamAveragePlayerData.aGrowth.toFixed(1) : '-';
                    var a = json.teamAveragePlayerData && json.teamAveragePlayerData.age ? +json.teamAveragePlayerData.aAge.toFixed(1) : '-';
                    console.log(s);
                    that.set('lm.age', a);
                    that.set('lm.weight', s);
                    that.set('lm.lbs', s !== '-' ? (s * 2.20462).toFixed(1) : '-');
                    that.set('lm.fut', b !== '-' ? (b * 0.0328084).toFixed(1) : '-');
                    that.set('lm.inch', b !== '-' ? (((b * 0.0328084).toFixed(1) - Math.floor(b * 0.0328084)) * 10).toFixed(1) : '-');
                    that.set('lm.growth', b !== '-' ? b.toFixed(1) : '-');
                });
            },

            getOverview: function getOverview() {
                var that = this;
                var rm = this.get('rm.id');
                var lm = this.get('lm.id');
                console.log('lm ', lm);
                console.log('rm ', rm);
                var lq = '&startDate=' + that.get('leftStartDate') + '&endDate=' + that.get('leftEndDate');
                var rq = '&startDate=' + that.get('rightStartDate') + '&endDate=' + that.get('rightEndDate');
                if (!rm || !lm) return;
                $('.squadLoading').removeClass('none');

                that.get('ajax').request('/teamGamesCompare?team=' + rm + rq).then(function (json) {
                    console.log(json);
                    var rmMap = json.map(function (d, index) {
                        var elem = {};
                        if (d.home_team._id === rm) {
                            elem.current_team = d.away_team;
                            elem.type = "H";
                        } else {
                            elem.current_team = d.home_team;
                            elem.type = "A";
                        }
                        elem.score = 'l';
                        elem.formatDate = moment(d.date).format('MM/DD/YYYY');
                        elem.team_home_score = d.team_home_score;
                        elem.team_away_score = d.team_away_score;
                        if (d.team_home_score === d.team_away_score) elem.score = 'n';
                        if (d.team_home_score > d.team_away_score && elem.type === 'H') elem.score = 'w';
                        if (d.team_home_score < d.team_away_score && elem.type === 'A') elem.score = 'w';
                        console.log(elem);
                        return elem;
                    }).slice(0, 10);
                    that.set('rmGames', rmMap);
                });

                that.get('ajax').request('/teamGamesCompare?team=' + lm + lq).then(function (json) {
                    console.log(json);
                    var lmMap = json.map(function (d, index) {
                        var elem = {};
                        if (d.home_team._id === lm) {
                            elem.current_team = d.away_team;
                            elem.type = "H";
                        } else {
                            elem.current_team = d.home_team;
                            elem.type = "A";
                        }
                        elem.score = 'l';
                        elem.formatDate = moment(d.date).format('MM/DD/YYYY');
                        elem.team_home_score = d.team_home_score;
                        elem.team_away_score = d.team_away_score;
                        if (d.team_home_score === d.team_away_score) elem.score = 'n';
                        if (d.team_home_score > d.team_away_score && elem.type === 'H') elem.score = 'w';
                        if (d.team_home_score < d.team_away_score && elem.type === 'A') elem.score = 'w';
                        return elem;
                    }).slice(0, 10);
                    that.set('lmGames', lmMap);
                    $('.squadLoading').addClass('none');
                });
            },
            //получаем Performance
            getsAttributes: function getsAttributes() {
                var that = this;
                var rm = this.get('rm.id');
                var lm = this.get('lm.id');
                if (!rm || !lm) return;
                $('.squadLoading').removeClass('none');
                var arr = this.get('ttaToSearch').mapBy('id');
                var lq = '&startDate=' + that.get('leftStartDate') + '&endDate=' + that.get('leftEndDate');
                var rq = '&startDate=' + that.get('rightStartDate') + '&endDate=' + that.get('rightEndDate');

                for (var i = 0; i < arr.length; i++) {
                    lq += '&teamTTA=' + arr[i];
                    rq += '&teamTTA=' + arr[i];
                }

                var c = that.get('currentComptetition');
                that.get('ajax').request('/cAttributesv2?team=' + rm + rq).then(function (rmo) {
                    that.get('ajax').request('/cAttributesv2?team=' + lm + lq).then(function (lmo) {
                        var ttasObj = {};

                        for (var i = 0; i < rmo.length; i++) {
                            ttasObj[rmo[i].teamTTA._id] = ttasObj[rmo[i].teamTTA._id] || {};
                            ttasObj[rmo[i].teamTTA._id].name = rmo[i].teamTTA.plural;
                            ttasObj[rmo[i].teamTTA._id]['rm'] = ttasObj[rmo[i].teamTTA._id]['rm'] ? ttasObj[rmo[i].teamTTA._id]['rm'] : {};
                            ttasObj[rmo[i].teamTTA._id]['rm'].total = rmo[i].actions;
                            ttasObj[rmo[i].teamTTA._id]['rm'].maxTotal = rmo[i].max_actions;
                            ttasObj[rmo[i].teamTTA._id]['rm'].averageTotal = rmo[i].average;
                            ttasObj[rmo[i].teamTTA._id]['rm'].averageSuccess = rmo[i].success_average;
                            ttasObj[rmo[i].teamTTA._id]['rm'].success = rmo[i].success_actions;
                            ttasObj[rmo[i].teamTTA._id]['rm'].max_average = rmo[i].max_average;
                            if (rmo[i].hasOwnProperty('success_actions')) {
                                ttasObj[rmo[i].teamTTA._id]['rm'].success_calculated = (rmo[i].success_actions || 0) / (rmo[i].actions || 1) * 100;
                            }
                        }

                        for (var i = 0; i < lmo.length; i++) {
                            ttasObj[lmo[i].teamTTA._id] = ttasObj[lmo[i].teamTTA._id] || {};
                            ttasObj[lmo[i].teamTTA._id].name = lmo[i].teamTTA.plural;
                            ttasObj[lmo[i].teamTTA._id]['lm'] = ttasObj[lmo[i].teamTTA._id]['lm'] ? ttasObj[lmo[i].teamTTA._id]['lm'] : {};
                            ttasObj[lmo[i].teamTTA._id]['lm'].total = lmo[i].actions;
                            ttasObj[lmo[i].teamTTA._id]['lm'].maxTotal = lmo[i].max_actions;
                            ttasObj[lmo[i].teamTTA._id]['lm'].averageTotal = lmo[i].average;
                            ttasObj[lmo[i].teamTTA._id]['lm'].averageSuccess = lmo[i].success_average;
                            ttasObj[lmo[i].teamTTA._id]['lm'].success = lmo[i].success_actions;
                            ttasObj[lmo[i].teamTTA._id]['lm'].max_average = lmo[i].max_average;
                            if (lmo[i].hasOwnProperty('success_actions')) {
                                ttasObj[lmo[i].teamTTA._id]['lm'].success_calculated = (lmo[i].success_actions || 0) / (lmo[i].actions || 1) * 100;
                            }
                        }
                        var resp = [];
                        for (var key in ttasObj) {
                            if (!(ttasObj[key] && ttasObj[key]['rm'] && ttasObj[key]['lm'])) continue;
                            var showTeamSucess = ttasObj[key]['rm'].success_calculated || ttasObj[key]['lm'].success_calculated ? true : false;
                            var showTeamAverageSucess = ttasObj[key]['rm'].averageSuccess || ttasObj[key]['lm'].averageSuccess ? true : false;
                            resp.push({
                                name: ttasObj[key].name,
                                rm: ttasObj[key]['rm'],
                                lm: ttasObj[key]['lm'],
                                showTeamSucess: showTeamSucess,
                                showTeamAverageSucess: showTeamAverageSucess
                            });
                        }
                        resp = _npmLodash['default'].sortBy(resp, 'name');
                        that.set('comparePerf', resp);
                        $('.squadLoading').addClass('none');
                    });
                });
            },

            getFormation: function getFormation() {
                var that = this;
                var lmmd = this.get('lm.id');
                var rmmd = this.get('rm.id');
                var lmq = '&startDate=' + that.get('leftStartDate') + '&endDate=' + that.get('leftEndDate');
                var rmq = '&startDate=' + that.get('rightStartDate') + '&endDate=' + that.get('rightEndDate');
                if (!rmmd || !lmmd) return;
                $('.getFormationLoadinglm').removeClass('none');
                $('.getFormationLoadingrm').removeClass('none');

                that.get('ajax').request('/teamFormation?team=' + lmmd + lmq).then(function (lmjson) {
                    that.get('ajax').request('/teamFormation?team=' + rmmd + rmq).then(function (rmjson) {
                        console.log(lmjson, rmjson);
                        var rmArr = that.get('teamService').prepareSquadData(rmjson);
                        that.set('formationrm', rmArr);
                        $('.getFormationLoadingrm').addClass('none');
                        var lmArr = that.get('teamService').prepareSquadData(lmjson);
                        that.set('formationlm', lmArr);
                        $('.getFormationLoadinglm').addClass('none');
                    });
                });
            }
        }
    });
});