define('msa/routes/leagues/show', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            return this.store.findRecord('competition', params.m_id);
        },

        ajax: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', model);
            that.get('ajax').request('/leagueSeasons?competition=' + model.get("id")).then(function (d) {
                controller.set('seasons', d.leagueSeasons);

                //получаем статистику по сезону
                that.get('ajax').request('/leagueStatistics?competition=' + model.get("id") + '&startDate=' + d.leagueSeasons.get("lastObject.startDate") + '&endDate=' + d.leagueSeasons.get("lastObject.endDate")).then(function (json) {
                    controller.set('statistic', json.leagueStatistics);
                });

                //получаем total data
                that.get('ajax').request('/leagueTotalData?competition=' + model.get("id")).then(function (json) {
                    controller.set('totals', json.leagueTotalData);
                });

                that.get('ajax').request('/leagueAveragePlayerData?competition=' + model.get("id")).then(function (json) {
                    var s = json.leagueAveragePlayerData && json.leagueAveragePlayerData.aWeight ? json.leagueAveragePlayerData.aWeight.toFixed(1) : '-';
                    var b = json.leagueAveragePlayerData && json.leagueAveragePlayerData.aGrowth ? json.leagueAveragePlayerData.aGrowth.toFixed(1) : '-';
                    var a = json.leagueAveragePlayerData && json.leagueAveragePlayerData.aAge ? json.leagueAveragePlayerData.aAge.toFixed(1) : '-';
                    controller.set('playersSt', {
                        age: a,
                        height: b,
                        weight: s,
                        lbs: s !== '-' ? (s * 2.20462).toFixed(1) : '-',
                        fut: b !== '-' ? Math.floor(b * 0.0328084) : '-',
                        inch: b !== '-' ? (((b * 0.0328084).toFixed(1) - Math.floor(b * 0.0328084)) * 10).toFixed() : '-'
                    });
                });

                controller.set('currentSeason', d.leagueSeasons.get('lastObject'));
                controller.set('startDate', d.leagueSeasons.get('lastObject.startDate'));
                controller.set('endDate', d.leagueSeasons.get('lastObject.endDate'));
            });
        },

        actions: {},

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                endDate: null,
                startDate: null
            });
        }
    });
});