define('msa/models/common-assist', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        half: _emberData['default'].attr('number'),
        game_time: _emberData['default'].attr('number'),
        current_team: _emberData['default'].attr('string'),

        tta: _emberData['default'].belongsTo('tta', { async: true }),
        player: _emberData['default'].belongsTo('player', { async: true }),
        team: _emberData['default'].belongsTo('team', { async: true }),
        target_player: _emberData['default'].belongsTo('player', { async: true }),
        game: _emberData['default'].belongsTo('game', { async: true }),

        actionTimeP: (function () {
            var time = this.get('game_time');
            return Math.floor(time / 1000 / 60 * 10) / 10;
        }).property('half'),

        actionTime: (function () {
            var time = this.get('game_time');
            var half = this.get('half');
            if (half == 2) time += 45 * 60 * 1000; //- 45;
            if (half == 3) time += 90 * 60 * 1000;; //- 90;
            var min = Math.floor(time / 1000 / 60);
            var sec = Math.floor((time - min * 60 * 1000) / 1000);
            return min + ':' + sec;
        }).property('half'),

        needsTeam: (function () {
            var ct = this.get('current_team');
            var g = this.get('game');
            var gt = g.get('home_team.id');
            console.log(gt === ct, gt, ct);
            if (gt === ct) {
                return g.get('away_team');
            } else {
                return g.get('home_team');
            }
        }).property('game.away_team'),

        actionPosition: (function () {
            var time = this.get('actionTimeP');
            var half = this.get('half');

            return Math.floor(time * 100 / 45 * 10) / 10;
        }).property('actionTimeP')
    });
});