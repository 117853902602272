define("msa/components/bar-report-chart", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        store: _ember["default"].inject.service(),

        didInsertElement: function didInsertElement() {
            var ctx = document.getElementById("barReportChart");

            var chartData = {
                labels: ["5", "10", "15", "20", "25", "30", "35", "40", "45", "5", "10", "15", "20", "25", "30", "35", "40", "45"],

                datasets: [{
                    label: "Successful actions",
                    backgroundColor: 'green',
                    stack: "Stack 0",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }, {
                    label: "Unsuccessful actions",
                    backgroundColor: 'red',
                    stack: "Stack 0",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                }]
            };
            var chartParams = {
                type: "bar",
                data: chartData,
                barThickness: 5,
                options: {
                    responsive: true,
                    scales: {
                        xAxes: [{
                            barPercentage: 0.25,
                            display: true,

                            gridLines: {
                                display: false
                            }
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    }
                }
            };

            var myChart = new Chart(ctx, chartParams);
            this.set('barChart', myChart);
        },

        didUpdate: function didUpdate() {
            console.log(this.get('reportGraphicParams'));
            console.log(this.get('barChart'));
            if (!this.get('barChart') || !this.get('reportGraphicParams')) return;
            this.get('barChart').config.data.datasets = this.get('reportGraphicParams').data.datasets;
            this.get('barChart').config.type = this.get('reportGraphicParams').type;
            this.get('barChart').update();
        }
    });
});