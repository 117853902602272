define('msa/controllers/leagues/show/players', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        getSq: _ember['default'].observer('endDate', 'model.id', function () {
            this.send('getFormation');
        }),

        ajax: _ember['default'].inject.service(),

        ctrl: _ember['default'].inject.controller('leagues.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),

        actions: {
            getFormation: function getFormation() {}
        }
    });
});