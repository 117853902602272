define('msa/components/ember-dragula', ['exports', 'ember', 'ember-dragula/components/ember-dragula'], function (exports, _ember, _emberDragulaComponentsEmberDragula) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports['default'] = _emberDragulaComponentsEmberDragula['default'].extend({
        setEventListeners: function setEventListeners() {
            var _this = this;

            if (!this.get('config.enabledEvents')) {
                return;
            }
            var drake = this.get('drake');
            this.get('config.enabledEvents').forEach(function (eventName) {
                drake.on(eventName, function () {
                    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                        args[_key] = arguments[_key];
                    }

                    _this.sendAction.apply(_this, [eventName].concat(_toConsumableArray(args.concat(drake))));
                });
            });
        }
    });
});