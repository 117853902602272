define('msa/components/range-slider', ['exports', 'ember-cli-nouislider/components/range-slider', 'ember'], function (exports, _emberCliNouisliderComponentsRangeSlider, _ember) {
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = _emberCliNouisliderComponentsRangeSlider['default'].extend({
    formatTo: function formatTo(value) {
      return Math.round(value);
    },
    setup: on('didInsertElement', function () {
      var _this = this;

      var $this = this.$().get(0);
      var properties = this.getProperties('start', 'step', 'margin', 'limit', 'range', 'connect', 'orientation', 'direction', 'behaviour', 'animate', 'snap', 'pips', 'format', 'tooltips');
      var sliderEvents = _ember['default'].A(['change', 'set', 'slide', 'update', 'start', 'end']);

      // We first check if the element has a slider already created
      if ($this.noUiSlider && $this.noUiSlider.destroy) {
        $this.noUiSlider.destroy();
      }

      noUiSlider.create($this, properties, true);

      var slider = $this.noUiSlider;

      // We set slider next sync cycle to avoid deprecation warnings
      run.schedule('sync', function () {
        _this.set('slider', slider);

        sliderEvents.forEach(function (event) {
          if (!isEmpty(_this.get('on-' + event))) {
            slider.on(event, function () {
              run(_this, function () {
                var val = this.get("slider").get();
                if (!isEmpty(this.get('model'))) {
                  this.sendAction('on-' + event, val, this.get('model'));
                } else {
                  this.sendAction('on-' + event, val);
                }
              });
            });
          }
        });

        /** DEPRECATED AND WILL BE REMOVED BEFORE 1.0 **/
        slider.on('change', function () {
          run(_this, function () {
            var val = this.get("slider").get();
            if (!isEmpty(this.get('model'))) {
              this.sendDeprecatedAction("change", val, this.get('model'));
            } else {
              this.sendDeprecatedAction("change", val);
            }
          });
        });

        if (!isEmpty(_this.get('slide'))) {
          slider.on('slide', function () {
            run(_this, function () {
              var val = this.get("slider").get();
              this.sendDeprecatedAction('slide', val);
            });
          });
        }
      });
    })
  });
});