define('msa/controllers/referees/show/overview', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),

        //наблюдаем за sqads
        refereePerformance: _ember['default'].observer('ttaToSearch.[]', function () {
            this.send('getsAttributes');
        }),

        changeDate: _ember['default'].observer('endDate', 'model.id', function () {

            var that = this,
                qo = {
                referee: that.get('model.id'),
                startDate: that.get('startDate'),
                endDate: that.get('endDate')
            };

            if (!qo.referee || !qo.startDate || !qo.endDate) return;
            $('.datasLoadingsp').removeClass('none');

            this.send('getsAttributes');
        }),

        ctrl: _ember['default'].inject.controller('referees.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),

        actions: {
            //добавляем tta в попап
            addTta: function addTta(t) {
                var l = this.get('ttaToSearch');
                if (l.includes(t)) {
                    l.removeObject(t);
                    t.set('inQuery', false);
                } else {
                    l.addObject(t);
                    t.set('inQuery', true);
                }
            },

            //получаем карту с экшенами
            getsAttributes: function getsAttributes() {
                $('.squadLoading').removeClass('none');
                var that = this;
                var st = this.get('startDate');
                var end = this.get('endDate');

                if (!st || !end) return;
                if (!this.get('ttaToSearch')) return;
                var arr = this.get('ttaToSearch').filterBy('parameter', true).mapBy('id');
                console.log('arr');
                var q = '';
                for (var i = 0; i < arr.length; i++) {
                    q += '&refereeTTA=' + arr[i];
                }

                that.get('ajax').request('/refereePerformance?referee=' + this.get("model.id") + q + '&startDate=' + this.get("startDate") + '&endDate=' + this.get("endDate")).then(function (json) {
                    if (!json) return;
                    var upperCased = json.map(function (act) {

                        if (act.total > 999999) {
                            act.total = Math.round(act.total / 100000) / 10 + 'M';
                        } else if (act.total > 999) {
                            act.total = Math.round(act.total / 100) / 10 + 'K';
                        } else if (act.total > 99) {
                            act.total = Math.round(act.total);
                        } else {
                            act.total = Math.round(act.total * 10) / 10;
                        }
                        if (isNaN(act.leagueAverage)) {
                            act.leagueAverage = '-';
                        } else if (act.leagueAverage > 999999) {
                            act.leagueAverage = Math.round(act.leagueAverage / 100000) / 10 + 'M';
                        } else if (act.leagueAverage > 999) {
                            act.leagueAverage = Math.round(act.leagueAverage / 100) / 10 + 'K';
                        } else if (act.leagueAverage > 99) {
                            act.leagueAverage = Math.round(act.leagueAverage * 10) / 10;
                        } else {
                            act.leagueAverage = Math.round(act.leagueAverage * 10) / 10;
                        }

                        if (act.average > 999999) {
                            act.average = Math.round(act.average / 100000) / 10 + 'M';
                        } else if (act.average > 999) {
                            act.average = Math.round(act.average / 100) / 10 + 'K';
                        } else if (act.average > 99) {
                            act.average = Math.round(act.average);
                        } else {
                            act.average = Math.round(act.average * 10) / 10;
                        }
                        act.tta.plural = act.tta.plural.toUpperCase();
                        return act;
                    });
                    that.set('attributes', upperCased);
                    $('.squadLoading').addClass('none');
                });
            }
        }
    });
});