define('msa/models/competition', ['exports', 'ember-data', 'msa/libs/type-shims'], function (exports, _emberData, _msaLibsTypeShims) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _msaLibsTypeShims.string,
		name_short: _msaLibsTypeShims.string,
		round_count: _msaLibsTypeShims.number,
		logo: _msaLibsTypeShims.string,
		is_cup: _msaLibsTypeShims.boolean,

		country: (0, _msaLibsTypeShims.belongsTo)('country', { async: true }),
		seasonType: (0, _msaLibsTypeShims.belongsTo)('season-type', { async: true }),

		//todo: Need determinate. it usage somewhere?
		ind: Ember.computed('seasonType.name', 'name', function () {
			if (!this.get('seasonType.name')) return;

			var name = this.get('name');
			var sessionTypeName = this.get('sessionType.name');
			var sy = sessionTypeName === 'Anniversary' ? ' / ' + name + 1 : '';
			return name + sy;
		})

	});
});