define('msa/controllers/post-match-report/referee', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        model: function model(params) {
            this.set('m_id', params.m_id);
            return this.store.findRecord('referee', params.r_id);
        },

        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        passesSelect: _ember['default'].A(['']),

        getCustomDates: _ember['default'].observer('endDate', function () {
            this.send('getStat');
        }),

        getrightType: _ember['default'].observer('rightType', function () {
            this.send('getRight');
        }),

        rightType: 'Passes received',

        actions: {

            changeSeason: function changeSeason(m) {
                var that = this;
                this.set('startDate', m.startDate);
                this.set('endDate', m.endDate);
            },

            setPlayerInRightSide: function setPlayerInRightSide(m) {
                this.set('currentPlayer', m);
            },

            findCustomDates: function findCustomDates() {
                var that = this;
                $('.currentSeasonId').removeClass('active');
                var s = moment(that.get('start')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(that.get('end')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                that.set('startDate', s);
                that.set('endDate', e);
            },
            getStat: function getStat() {
                $('.getStatLoading').removeClass('none');

                var that = this,
                    qo = {
                    referee: that.get('model.id'),
                    startDate: that.get('startDate'),
                    endDate: that.get('endDate')
                };

                if (!qo.referee || !qo.startDate || !qo.endDate) return;
                var q = '?referee=' + qo.referee + '&startDate=' + qo.startDate + '&endDate=' + qo.endDate;

                that.get('ajax').request('/refereeStatistics' + q).then(function (json) {
                    that.set('statistic', json);
                    $('.getStatLoading').addClass('none');
                });
            },

            printPage: function printPage() {
                var enemyName = this.get('oT.name');
                var playerName = this.get('model.first_name');
                document.title = 'MSA ' + playerName + ' vs ' + enemyName + ' report.pdf';
                window.print();
                setTimeout(function () {
                    document.title = 'MSA';console.log('timeout');
                }, 15000);
            },

            getRight: function getRight() {
                var that = this,
                    q = this.get('rightType'),
                    referee = that.get('model.id');
                var game = this.get('game').id;
                that.get('ajax').request('/refereeGameReportActionsByZones?referee=' + referee + '&refereeTTA=' + q.id + '&game=' + game + '&HSize=6&VSize=3').then(function (json) {
                    var groupedArr = that.get('postMatchService').prepareActionsByZones(json);
                    that.set('breakdownActions', groupedArr);
                });
            }
        }
    });
});