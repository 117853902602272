define('msa/routes/post-match-report/summary', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            return this.store.findRecord('game', params.m_id);
        },

        ajax: _ember['default'].inject.service()

    });
});
/*setupController: function(controller, model) {
    const that = this;
    controller.set('model', model);
    that.get('ajax').request(`/playerSeasons?player=${model.get("id")}`).then(function(d) {
        controller.set('seasons', d.seasons);
        controller.set('currentSeason', d.seasons.get('lastObject'));
        controller.set('startDate', d.seasons.get('lastObject.startDate'));
        controller.set('endDate', d.seasons.get('lastObject.endDate'));
    });
    
},
  resetController(controller, isExiting, transition) {
    controller.setProperties({
        endDate: null,
        startDate: null,
        ttaToEpisodes: []
    });
}*/