define('msa/controllers/leagues/show', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        currentUser: _ember['default'].inject.service('current-user'), //авторизованный пользователь
        ajax: _ember['default'].inject.service(),

        //добавлена ли комнда в избранные
        isFavorite: _ember['default'].computed('currentUser.user.fav_competitions.@each', 'model', function () {
            if (this.get('currentUser.user.fav_competitions').includes(this.get('model'))) return true;
        }),

        //следим за изменениями даты
        getCustomDates: _ember['default'].observer('endDate', function () {
            this.send('getStatistica');
        }),

        actions: {
            setAmpluaQuery: function setAmpluaQuery(l) {
                $('.rightLoading').removeClass('none');
                if (!l) {
                    this.set('fiteredPlayers', this.get('players'));
                    this.send('setPlayerInRightSide', this.get('players.firstObject'));
                } else {
                    this.set('playersFilter', l);
                    var arr = this.get('players').filter(function (i) {
                        return i.get('amplua.id') === l.get('id');
                    });
                    this.set('fiteredPlayers', arr);
                    this.send('setPlayerInRightSide', arr.get('firstObject'));
                }
                _ember['default'].run.later(function () {
                    var divBackup = $(".coverflow");
                    $(".coverflow").remove();
                    $(".playerSlide").html(divBackup);
                    $(".coverflow").coverflow({});
                    $('.rightLoading').addClass('none');
                }, 1000);
            },

            changeSeason: function changeSeason(m) {
                var that = this;
                this.set('currentSeason', m);
                this.set('start', false);
                this.set('end', false);
                this.set('startDate', m.startDate);
                this.set('endDate', m.endDate);
                $('.currentSeasonId').addClass('active');
            },

            findCustomDates: function findCustomDates() {
                $('.currentSeasonId').removeClass('active');
                var s = moment(this.get('start')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(this.get('end')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                this.set('startDate', s);
                this.set('endDate', e);
            },

            getStatistica: function getStatistica() {
                $('.statisticLoading').removeClass('none');
                var that = this;
                that.get('ajax').request('/leagueStatistics?competition=' + that.get("model.id") + '&startDate=' + that.get("currentSeason.startDate") + '&endDate=' + that.get("currentSeason.endDate")).then(function (json) {
                    that.set('statistic', json.leagueStatistics);
                    $('.statisticLoading').addClass('none');
                });
            }
        }
    });
});