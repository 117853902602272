define('msa/routes/select-league', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: inject.service(),
    ajax: inject.service(),

    model: function model() {
      return _ember['default'].A([_ember['default'].Object.create({ model: 'country', width: '50%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', query: 'country', width: '50%', title: 'select league', step: 2 })]);
    },

    setupController: function setupController(controller, model) {
      // const step = !!controller.get('step') ? controller.get('step') : 1;
      controller.setProperties({
        st: model,
        step: 1,
        country: false,
        competition: false
      });
    },

    actions: {
      renderStep: function renderStep(modelName, query) {
        var that = this;

        if (!query) {
          that.get('ajax').request('/selectCountry').then(function (json) {
            that.render('outlets/select/country', {
              into: 'select-league',
              outlet: 'steps',
              model: json.country
            });
          });
          return true;
        }

        if (modelName === 'competition') {
          that.get('ajax').request('/selectCompetition?country=' + query.country).then(function (json) {
            that.render('outlets/select/competition', {
              into: 'select-league',
              outlet: 'steps',
              model: json.team
            });
          });
          return true;
        }

        this.render('outlets/select/' + modelName, {
          into: 'select-league',
          outlet: 'steps',
          model: query ? that.store.query(modelName, query) : that.store.findAll('country'),
          controller: 'outlets/select/' + modelName
        });
      },

      setModel: function setModel(mn, m) {
        var controller = this.controllerFor('select-league');
        controller.set(mn, m);
        this.send('nextSteps');
      },

      nextSteps: function nextSteps() {
        var controller = this.controllerFor('select-league');
        var step = controller.get('step');
        controller.set('step', ++step);
        var steps = controller.get('st');
        if (step >= steps.length + 1) {
          this.transitionTo('leagues.show', controller.get('competition._id'));
        } else if (step === 1) {
          this.send('renderStep', 'country', false);
        } else {
          var newModelName = steps[step - 1]['model'];
          var query = {};
          var model = controller.get(steps[step - 2]['model']);
          query[steps[step - 1]['query']] = model._id ? model._id : model.get('id');
          this.send('renderStep', newModelName, query);
        }
      }

    }

  });
});