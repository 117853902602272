define('msa/components/simple-player', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        ajax: _ember['default'].inject.service(),
        episodesPlayer: _ember['default'].inject.service(),

        didInsertElement: function didInsertElement() {
            var that = this;
            $('video, audio').mediaelementplayer({
                alwaysShowControls: true,
                success: function success(player, node) {
                    player.addEventListener('ended', function (e) {
                        that.set('currentVideo', 0);
                        that.send('getVideo', that.get('simpleList')[that.get('currentVideo')]);
                    });
                }
            });

            that.send('getVideo', that.get('simpleList.firstObject'));
        },

        actions: {
            getVideo: function getVideo(m) {
                $('.videoLabWrap tr').removeClass('active');
                this.set('currentVideo', m.i);
                var model = m;
                if (!model.video) return;
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                var src = model.video + '?start=' + (+model.timeTech - 5000) / 1000 + '&end=' + (+model.timeTech + 5000) / 1000;
                player.setSrc(src);
                player.media.play();

                _ember['default'].run.later(function () {
                    $('*[data-video-id="' + m.i + '"] ').addClass('active');
                }, 100);
            },

            removeModal: function removeModal() {
                this.set('showSimplePlayer', false);
            },
            downloadVideo: function downloadVideo(m) {
                var link = document.createElement("a");
                console.log(m);
                link.download = m.home + '-' + m.away + ' ' + m.action + ' at ' + m.time;
                link.download.replace(':', '-');
                link.download += m.video.substring(m.video.lastIndexOf('.'));
                link.filename = link.download;
                link.href = m.video;
                link.click();
            },
            addVideoToVideolab: function addVideoToVideolab(model) {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var name = model.home + " " + model.away + " " + model.playerName + " " + model.action;
                var startEndTime = this.get('episodesPlayer').getVideoStartAndEnd(model.timeTech, 0);
                console.log(model);
                var videoFile = { action: model.actionId, name: name, url: model.video, action: model.actionId, startTime: startEndTime.startTime, endTime: startEndTime.endTime };
                this.get('ajax').request("/videofolder", {
                    method: 'POST',
                    data: JSON.stringify(videoFile),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    flashMessages.success("Video was added to Videolab");
                });
            }
        }
    });
});