define('msa/models/team-game-list', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		away_team: _emberData['default'].belongsTo('team', { async: true }),
		competition: _emberData['default'].belongsTo('competition', { async: true }),
		date: _emberData['default'].attr('string'),
		actions: _emberData['default'].attr('array'),
		ad: _emberData['default'].attr('array'),
		hd: _emberData['default'].attr('array'),
		hasVideo: _emberData['default'].attr('boolean'),
		hasHL: _emberData['default'].attr('boolean'),
		//fieldMatrix: DS.belongsTo('country', {async: true}),
		home_team: _emberData['default'].belongsTo('team', { async: true }),
		current_team: _emberData['default'].belongsTo('team', { async: true }),
		round_number: _emberData['default'].attr('string'),
		stadium: _emberData['default'].belongsTo('country', { async: true }),
		team_away_number_color: _emberData['default'].attr('string'),
		score: _emberData['default'].attr('string'),
		type: _emberData['default'].attr('string'),
		team_away_score: _emberData['default'].attr('string'),
		team_away_shirt_color: _emberData['default'].attr('string'),
		team_home_number_color: _emberData['default'].attr('string'),
		team_home_score: _emberData['default'].attr('string'),
		team_home_shirt_color: _emberData['default'].attr('string'),
		old_id: _emberData['default'].attr('string'),
		formatDate: (function () {
			return moment.parseZone(this.get('date')).format('L');
		}).property('date_birth')
	});
});