define('msa/services/current-user', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Service.extend({
    session: service(),
    store: service(),

    load: function load() {
      var _this = this;

      var userId = this.get('session.data.authenticated.id');
      if (!isEmpty(userId)) {
        return this.get('store').findRecord('user', userId).then(function (user) {
          _this.set('user', user);
        });
      } else {
        return _ember['default'].RSVP.resolve();
      }
    }
  });
});