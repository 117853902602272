define('msa/components/selects/select-form', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'section',
        classNames: ['main_section_episode_search_page'],

        steps: _ember['default'].computed('step', function () {
            var that = this;
            var steps = this.get('definedSteps');
            return steps.map(function (s, index) {
                var cl;
                if (that.get('step') == s.step) {
                    cl = 'active';
                }
                if (that.get('step') > s.step) cl = 'on_click';
                if (that.get('step') < s.step) {
                    cl = '';
                }
                s.set('class', cl);
                return s;
            });
        }),

        didInsertElement: function didInsertElement() {
            this.send('startAction');
        },

        actions: {

            fastStep: function fastStep(step) {
                this.set('step', step);
                var steps = this.get('st');
                var newModelName = steps[step - 1]['model'];
                var qId = this.get(steps[step - 2]['model']).get('id');
                var query = {};
                query[steps[step - 1]['query']] = qId;

                this.sendAction('renderStep', newModelName, query);
            },

            startAction: function startAction(st) {
                var step = this.get('step');
                var steps = this.get('steps');

                if (step === 1) {
                    this.sendAction('renderStep', 'country', false);
                } else {
                    var newModelName = steps[step - 1]['model'];
                    this.sendAction('renderStep', newModelName, false);
                }
            },
            backStep: function backStep(step) {
                this.sendAction('backStep', step);
            }
        }
    });
});