define('msa/components/referee-report-heatmap', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
  exports['default'] = _ember['default'].Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      this.prepareData();
      //Ember.run.later(this, this.drawLines, 1000);
    },

    prepareData: function prepareData() {
      var that = this;
      this.$(".act-radio").off();
      this.$(".act-radio").click(function (event) {
        that.send('changeType', parseInt(event.target.dataset.id));
      });
      var fieldData = this.get('playerActionMaps');
      this.set('activeHalf', 1);
      var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
      this.set('filteredRefereeData', filteredData);
    },
    changeData: _ember['default'].observer('filteredRefereeData', function () {
      var that = this;
      var playerActionMaps = this.get('filteredRefereeData');
      if (!playerActionMaps) return;
      var heatmapInstance = this.get('heatmapInstance');
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (!heatmapInstance) {
          (function () {
            heatmapInstance = h337.create({
              opacity: 0.6,
              radius: 12,
              container: document.querySelector('.heatmap')
            });
            var points = [];
            var max = 0;
            var width = heatmapInstance._renderer._width;
            var height = heatmapInstance._renderer._height;
            playerActionMaps.forEach(function (item, index) {
              var point = {
                x: Math.floor(item.x * width / 100),
                y: Math.floor(item.y * height / 100),
                value: 20
              };
              points.push(point);
            });
            var data = {
              max: max,
              data: points
            };
            heatmapInstance.setData(data);
            that.set('heatmapInstance', heatmapInstance);
          })();
        } else {
          (function () {
            heatmapInstance.repaint();
            var points = [];
            var max = 0;
            var width = heatmapInstance._renderer._width;
            var height = heatmapInstance._renderer._height;
            playerActionMaps.forEach(function (item, index) {
              var point = {
                x: Math.floor(item.x * width / 100),
                y: Math.floor(item.y * height / 100),
                value: 20
              };
              points.push(point);
            });
            var data = {
              max: max,
              data: points
            };
            heatmapInstance.setData(data);
          })();
        }
      });
    }),

    didInsertElement: function didInsertElement() {
      var that = this;
      var playerActionMaps = this.get('filteredRefereeData');
      if (!playerActionMaps) return;
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var heatmapInstance = h337.create({
          opacity: 0.6,
          radius: 12,
          container: document.querySelector('.heatmap')
        });

        var points = [];
        var max = 0;
        var width = heatmapInstance._renderer._width;
        var height = heatmapInstance._renderer._height;
        playerActionMaps.forEach(function (item, index) {
          var point = {
            x: Math.floor(item.x * width / 100),
            y: Math.floor(item.y * height / 100),
            value: 20
          };
          points.push(point);
        });
        var data = {
          max: max,
          data: points
        };
        heatmapInstance.setData(data);
        that.set('heatmapInstance', heatmapInstance);
      });
    },

    actions: {
      changeTab: function changeTab(tabId) {
        if (tabId === 'firstPlayerActionTab') {
          $('#secondPlayerActionTab').hide();
          $('#firstPlayerActionTab').show();
          $('#firstPlayerActionPane').addClass('title_inner_action_tabs_box_active');
          $('#secondPlayerActionPane').removeClass('title_inner_action_tabs_box_active');
        } else {
          $('#firstPlayerActionTab').hide();
          $('#secondPlayerActionTab').show();
          $('#secondPlayerActionPane').addClass('title_inner_action_tabs_box_active');
          $('#firstPlayerActionPane').removeClass('title_inner_action_tabs_box_active');
        }
      },
      setTabActionMap: function setTabActionMap(model, index) {
        this.set('activeTab', index);
        this.sendAction('setActionMap', model);
      },
      activeTab: function activeTab(index) {
        this.set('activeTab', index);
      },
      changeHalf: function changeHalf(half) {
        this.set('activeHalf', half);
        var fieldData = this.get('playerActionMaps');
        var filteredData = null;
        if (half !== 3) filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });else filteredData = fieldData;

        this.set('filteredRefereeData', filteredData);
      }

    }
  });
});