define('msa/routes/search-players', ['exports', 'ember', 'msa/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaConfigEnvironment, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
	var service = _ember['default'].inject.service;
	var isEmpty = _ember['default'].isEmpty;
	var RSVP = _ember['default'].RSVP;
	exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		setupController: function setupController(controller, model) {

			this.store.findAll('country', { reload: true }).then(function (c) {
				c = c.sortBy('name');
				controller.set('countries', c);
			});
			this.store.findAll('playerAmplua', { reload: true }).then(function (pa) {
				pa = pa.sortBy('name');
				controller.set('positions', pa);
			});
			controller.get('ajax').request('/playerTTA').then(function (ttas) {
				controller.set('ttas', ttas.playertta);
			});
		},
		resetController: function resetController(controller, isExiting, transition) {
			controller.setProperties({
				selectedCountry: null,
				selectedCompetition: null,
				selectedTeam: null,
				selectedPosition: null,
				selectedAge: null
			});
		}
	});
});

//import LoadingIndicator 		from 'uti-ui/mixins/loading-indicator';