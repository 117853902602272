define('msa/routes/referees/show/overview', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', that.controllerFor('referees.show').get("model"));

            // that.get('ajax').request(`/refereeActionMapTypes?referee=${that.controllerFor('referees.show').get("model.id")}`).then(function(p) {
            //     //controller.set('refereeActionMapTypes', p.refereeActionMapTypes);
            //     //controller.set('currentAction', p.refereeActionMapTypes[0]);
            // });

            that.store.findAll('refereeTTA').then(function (ttas) {
                console.log(ttas);
                var newObj = _ember['default'].A();
                ttas.forEach(function (tta, index) {
                    ttas.objectAt(index).set('inQuery', false);
                });
                ttas = ttas.sortBy('plural');
                controller.set('tta', ttas);
                for (var i = 0; i < 8; i++) {
                    ttas.objectAt(i).set('inQuery', true);
                }
                var paramsArray = [];
                newObj.pushObjects(ttas);
                console.log(newObj);
                controller.set('ttaToSearch', newObj);
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                refereeActionMapTypes: null,
                currentAction: null
            });
        }
    });
});