define('msa/components/player-vs-player-table', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        breakdownActions: null,
        didRender: function didRender() {
            console.log("this.get('tableData')", this.get('tableData'));
            /*const breakdownActions = this.get('breakdownActions');
            this.set('firstRow', breakdownActions[0]);
            this.set('secondRow', breakdownActions[1]);
            this.set('thirdRow', breakdownActions[2]); */
        },
        actions: {
            openModal: function openModal(acts) {
                if (acts.length > 0) {
                    this.set('actionsArray', acts);
                    this.set('showVideoPlayer', true);
                }
            },
            closePlayer: function closePlayer() {
                this.set('showVideoPlayer', false);
            }
        }
    });
});