define('msa/components/squads-list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        store: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        currentUser: _ember['default'].inject.service(),
        orFilter: false,
        ttaToSearch: _ember['default'].A(),

        positions: _ember['default'].A(['Goalkeeper', 'Midfielder', 'Defender', 'Forward', 'Attacking Midfielder']),

        //следим за изменениями даты
        getData: _ember['default'].observer('endDate', 'currentCompetition', 'ttaToSearch.[]', function () {
            var st = this.get('startDate');
            var end = this.get('endDate');
            var ttas = this.get('ttaToSearch');

            if (!st || !end || !ttas.get('length')) return;
            this.send('getsQuads', st, end, ttas);
        }),

        //следим за изменениями даты
        sortingSquad: _ember['default'].computed('sqf', 'squadList.[]', function () {
            var that = this;
            if (!that.get('sqf')) return that.get('squadList');
            return that.get('squadList').filterBy('position', that.get('sqf'));
        }),

        didInsertElement: function didInsertElement() {
            var store = this.get('store');
            var that = this;

            store.findAll('playerTTA').then(function (ttas) {
                ttas.forEach(function (tta, index) {
                    ttas.objectAt(index).set('inQuery', false);
                });
                that.set('tta', ttas.filterBy('parameter', true).sortBy('plural'));
                ttas = ttas.filterBy('parameter', true).sortBy('plural');
                for (var i = 0; i < 8; i++) {
                    ttas.objectAt(i).set('inQuery', true);
                }
                that.set('ttaToSearch', ttas.objectsAt([0, 1, 2, 3, 4, 5, 6, 7]));
            });
        },

        actions: {
            sortByInSquad: function sortByInSquad(m, row) {
                var that = this;
                var f = this.get('orFilter');
                var s = that.get('squadList').sort(function (a, b) {
                    if (!f) return a.get('tta')[row] - b.get('tta')[row];
                    if (f) return b.get('tta')[row] - a.get('tta')[row];
                });
                this.toggleProperty('orFilter');
                that.set('squadList', null);
                that.set('squadList', s);
            },

            //добавляем юзера из squads в фавориты
            addToFavoritesSquad: function addToFavoritesSquad(m, i) {
                var store = this.get('store');
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var u = this.get('currentUser.user');
                var mes, stat;
                store.findRecord('player', m.get('i')).then(function (p) {
                    if (m.get('isFavorite')) {
                        u.get('fav_players').removeObject(p);
                        mes = 'Successfully removed';
                        stat = 'Add to favorites';
                    } else {
                        u.get('fav_players').pushObject(p);
                        mes = 'Successfully added';
                        stat = 'Remove from favorites';
                    }
                    u.save().then(function (res) {
                        flashMessages.success(mes);
                        m.set('isFavorite', !m.get('isFavorite'));
                        m.set('fav_status', stat);
                    })['catch'](function (err) {
                        flashMessages.danger('Something went wrong!');
                    });
                });
            },

            addTta: function addTta(m) {
                var list = this.get('ttaToSearch');

                if (list.includes(m)) {
                    m.set('inQuery', false);
                    list.removeObject(m);
                } else {
                    if (list.get('length') > 9) return false;
                    m.set('inQuery', true);
                    list.addObject(m);
                }
            },

            getsQuads: function getsQuads(st, end, ttas) {
                $('.squadLoading').removeClass('none');
                var that = this;
                var arr = ttas.mapBy('id');

                var q = '';
                for (var i = 0; i < arr.length; i++) {
                    q += '&playerTTA=' + arr[i];
                }
                if (!!this.get('currentCompetition') && this.get('currentCompetition.name') !== 'OVERALL') q += '&competition=' + this.get('currentCompetition.id');

                that.get('ajax').request('/' + that.get('query') + '=' + this.get("model.id") + '&startDate=' + st + '&endDate=' + end + q).then(function (json) {
                    if (!json) return;
                    var newArray = _ember['default'].A();
                    var ids = that.get('currentUser.user.fav_players').mapBy('id');
                    for (var _i = 0; _i < json.values.length; _i++) {
                        if (!json.values[_i].player) continue;
                        var player = {
                            i: json.values[_i].player._id,
                            first_name: json.values[_i].player.first_name,
                            last_name: json.values[_i].player.last_name,
                            position: json.values[_i].player.amplua ? json.values[_i].player.amplua.name : '-',
                            photo: json.values[_i].player.photo,
                            country: json.values[_i].player.country ? json.values[_i].player.country.name : "",
                            age: Math.floor(+moment().format('YYYY') - +moment(json.values[_i].player.date_birth).format('YYYY')) || '-',
                            tta: json.values[_i].values
                        };

                        if (ids.includes(json.values[_i].player._id)) {
                            player.isFavorite = true;
                            player.fav_status = 'Remove from favorites';
                        } else {
                            player.isFavorite = false;
                            player.fav_status = 'Add to favorites';
                        }
                        newArray.pushObject(_ember['default'].Object.create(player));
                    }

                    that.set('squadList', newArray);
                    $('.squadLoading').addClass('none');
                });
            },
            showPlayer: function showPlayer(item) {
                if (event.target.className === 'fav') {
                    return;
                } else {
                    this.get('router').transitionTo('players.show', item.i);
                }
            }
        }
    });
});