define('msa/routes/post-match-report/defensive/challenges', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            var gameModel = that.controllerFor('post-match-report.defensive').get("model");
            controller.set('model', gameModel);
            var requiredTttas = [39, 40, 41];
            that.get('ajax').request('/stadium/' + model.get("stadium.id")).then(function (stadiumData) {
                that.store.findAll('teamTTA').then(function (ttas) {
                    var filteredTtas = ttas.filter(function (item) {
                        return requiredTttas.indexOf(item.get('orderIndex')) > -1;
                    });
                    var query = '';
                    filteredTtas.forEach(function (tta, index) {
                        query += '&teamTTA=' + tta.get('id');
                    });
                    var homeTeamId = gameModel.get('home_team.id');
                    var awayTeamId = gameModel.get('away_team.id');
                    that.get('ajax').request('/gameReportCountBars?game=' + gameModel.get('id') + query).then(function (json) {
                        var setPieceActions = that.get('postMatchService').prepareCountBarsData(json, homeTeamId, awayTeamId);
                        controller.set('setPieceActions', setPieceActions);
                    });
                    var totalTtaQuery = '';
                    filteredTtas.forEach(function (tta, index) {
                        if (tta.get('orderIndex') === 39) {
                            totalTtaQuery = '&teamTTA=' + tta.get('id');
                        }
                    });
                    var homeZonesTeamQuery = totalTtaQuery + '&team=' + homeTeamId + '&HSize=6&VSize=3';
                    that.get('ajax').request('/gameReportActionsByZones?game=' + gameModel.get('id') + homeZonesTeamQuery).then(function (json) {
                        var groupedArr = that.get('postMatchService').prepareActionsByZones(json);
                        controller.set('breakdownHomeActions', groupedArr);
                    });
                    var awayZonesTeamQuery = totalTtaQuery + '&team=' + awayTeamId + '&HSize=6&VSize=3';
                    that.get('ajax').request('/gameReportActionsByZones?game=' + gameModel.get('id') + awayZonesTeamQuery).then(function (json) {
                        var groupedArr = that.get('postMatchService').prepareActionsByZones(json);
                        controller.set('breakdownAwayActions', groupedArr);
                    });
                    that.get('ajax').request('/gameReportHeatMapActions?game=' + gameModel.get('id') + awayZonesTeamQuery).then(function (json) {
                        var filteredData = that.get('postMatchService').prepareBreakdownsHeatmapData(json, stadiumData.stadium.width, stadiumData.stadium.length);
                        controller.set('awayBreakdownHeatMapActions', filteredData);
                    });
                    that.get('ajax').request('/gameReportHeatMapActions?game=' + gameModel.get('id') + homeZonesTeamQuery).then(function (json) {
                        var filteredData = that.get('postMatchService').prepareBreakdownsHeatmapData(json, stadiumData.stadium.width, stadiumData.stadium.length);
                        controller.set('homeBreakdownHeatMapActions', filteredData);
                    });
                    that.get('ajax').request('/gameReportTimeBars?game=' + gameModel.get('id') + totalTtaQuery).then(function (json) {
                        if (json.length == 0) return;
                        var homeTeamItem = null;
                        var awayTeamItem = null;
                        var maxNumber = that.get('postMatchService').getMaxNumber(json);
                        var scaleValues = that.get('postMatchService').getScaleValues(json);
                        controller.set('summaryScaleValues', scaleValues);
                        json.forEach(function (item, index) {
                            if (item.team._id === homeTeamId) {
                                homeTeamItem = item;
                            }
                            if (item.team._id === awayTeamId) {
                                awayTeamItem = item;
                            }
                        });
                        var timesArray = ['0-15', '15-30', '30-45', '45-60', '60-75', '75-90'];
                        var preparedData = [];
                        timesArray.forEach(function (item, index) {
                            var homeHeight = homeTeamItem.row[index] / maxNumber * 80;
                            var awayHeight = awayTeamItem.row[index] / maxNumber * 80;
                            preparedData.push({ period: item, homeTeamHeight: homeHeight, awayTeamHeight: awayHeight });
                        });
                        controller.set('summaryHistogramData', preparedData);
                        var playerTtas = ["Challenges"];
                        that.store.findAll('playerTTA').then(function (pTtas) {
                            var filteredPlayersTtas = pTtas.filter(function (item) {
                                return playerTtas.indexOf(item.get('plural')) > -1;
                            });
                            var ttaPlayersListQuery = '';
                            filteredPlayersTtas.forEach(function (tta, index) {
                                ttaPlayersListQuery += '&playerTTA=' + tta.get('id');
                            });
                            var homePlayersListQuery = ttaPlayersListQuery + '&team=' + homeTeamId;
                            that.get('ajax').request('/gameReportActionsByPlayers?game=' + gameModel.get('id') + homePlayersListQuery).then(function (json) {
                                json.header.push({ plural: "Won" });
                                json.header.push({ plural: "%" });
                                var homePlayersList = that.get('postMatchService').prepareReportActionsByPlayersForPasses(json);
                                controller.set('homePlayersList', homePlayersList);
                            });
                            var awayPlayersListQuery = ttaPlayersListQuery + '&team=' + awayTeamId;
                            that.get('ajax').request('/gameReportActionsByPlayers?game=' + gameModel.get('id') + awayPlayersListQuery).then(function (json) {
                                json.header.push({ plural: "Won" });
                                json.header.push({ plural: "%" });
                                var awayPlayersList = that.get('postMatchService').prepareReportActionsByPlayersForPasses(json);
                                controller.set('awayPlayersList', awayPlayersList);
                            });

                            that.get('ajax').request('/gameReportChallengeTable?game=' + gameModel.get('id')).then(function (json) {
                                for (var i = 0; i < json.array.length; i++) {
                                    if (json.array[i] && json.array[i].player && json.array[i].player.first_name && json.array[i].player.first_name.length) json.array[i].player.first_name = json.array[i].player.first_name[0];
                                    for (var k = 0; k < json.array[i].values.length; k++) {
                                        json.array[i].values[k].hasActs = false;
                                        json.array[i].values[k].hasLooseActs = false;
                                        if (json.array[i].values[k].acts.length && json.array[i].values[k].acts.length > 0) {
                                            json.array[i].values[k].hasActs = true;
                                        }
                                        if (json.array[i].values[k].looseActs.length && json.array[i].values[k].looseActs.length > 0) {
                                            json.array[i].values[k].hasLooseActs = true;
                                        }
                                    }
                                }
                                for (var i = 0; i < json.header.length; i++) {
                                    if (json.header[i] && json.header[i].first_name && json.header[i].first_name.length) json.header[i].first_name = json.header[i].first_name[0];
                                }
                                controller.set('challengeTableData', json);
                            });
                        });
                    });
                });
            });
        }
    });
});