define('msa/components/player-action-map', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        position: _ember['default'].computed('x,y', function () {
            var x = this.get('x');
            var y = this.get('y');
            var left = Math.floor(x * 1000) / 10 > 100 ? 100 : Math.floor(x * 1000) / 10;
            var top = Math.floor(y * 1000) / 10 > 100 ? 100 : Math.floor(y * 1000) / 10;
            var str = 'left: ' + left + '%; top: ' + top + '%';

            return _ember['default'].String.htmlSafe(str);
        })
    });
});