define('msa/routes/post-match-report/referee', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

    model: function model(params) {
      this.set('m_id', params.m_id);
      return this.store.findRecord('referee', params.r_id);
    },

    getrightType: _ember['default'].observer('rightType', function () {

      this.send('getRight');
    }),

    rightType: 'Passes received',
    passesSelect: _ember['default'].A(['Passes received', 'Passes made']),
    ajax: _ember['default'].inject.service(),
    postMatchService: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      var that = this;
      controller.set('valuesList', ['1234', '5678']);
      var requiredTttas = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15, 30];
      that.store.findAll('refereeTTA').then(function (ttas) {
        var filteredTtas = ttas.filter(function (item) {
          return requiredTttas.indexOf(item.get('orderIndex')) > -1;
        });
        controller.set('passesSelect', filteredTtas);
      });
      this.store.findRecord('game', this.get('m_id')).then(function (g) {
        controller.set('awaySetPiecesFieldData', [1, 2, 3]);
        that.get('ajax').request('/stadium/' + g.get('stadium.id')).then(function (stadiumData) {
          controller.set('game', g);
          controller.set('model', model);
          that.get('ajax').request('/refereeSeasons?referee=' + model.get("id")).then(function (d) {
            controller.set('seasons', d.seasons);
            controller.set('currentSeason', d.seasons.get('lastObject'));
            controller.set('startDate', d.seasons.get('lastObject.startDate'));
            controller.set('endDate', d.seasons.get('lastObject.endDate'));
            that.get('ajax').request('/refereeGameReportActions?game=' + g.get("id") + '&referee=' + model.get("id")).then(function (json) {
              var playerActions = that.get('postMatchService').prepareDataForRefereeActionsOverview(json);
              controller.set('playerActions', playerActions);
            });
          });
          that.get('ajax').request('/refereeGameReportHeatmap?game=' + g.get("id") + '&referee=' + model.get("id")).then(function (json) {
            var resized = json.map(function (pos) {
              return {
                x: (pos.x + stadiumData.stadium.length * 0.5) / stadiumData.stadium.length * 100 * 0.90 + 5,
                y: pos.y / stadiumData.stadium.width * 100 * 0.90 + 5,
                half: pos.half
              };
            });
            controller.set('playerActionMaps', resized);
          });
          // /ember/refereeGameReportKeyMoments
          that.get('ajax').request('/refereeGameReportKeyMoments?game=' + g.get("id") + '&referee=' + model.get("id")).then(function (json) {
            var resized = json.map(function (pos) {
              return {
                x: pos.loc_x / stadiumData.stadium.length * 100 * 0.90 + 5,
                y: pos.loc_y / stadiumData.stadium.width * 100 * 0.90 + 5,
                half: pos.half,
                player: pos.player.first_name + " " + pos.player.last_name,
                tta: pos.tta.name
              };
            });
            controller.set('keyMoments', resized);
          });
          // доделать запрос
          that.get('ajax').request('/refereeGameReportChallengesAndFouls?game=' + g.get("id") + '&referee=' + model.get("id")).then(function (json) {
            var timesArray = ['0-15', '15-30', '30-45', '45-60', '60-75', '75-90'];
            var maxNumber = Math.max.apply(Math, _toConsumableArray(json.challenges.concat(json.fouls)));
            maxNumber = Math.ceil(maxNumber / 10) * 10;
            var preparedData = timesArray.map(function (item, index) {
              var Challenges = json.challenges.length >= index ? json.challenges[index] / maxNumber * 80 : 0;
              var Fouls = json.fouls.length >= index ? json.fouls[index] / maxNumber * 80 : 0;
              return {
                period: item,
                challenges: Challenges,
                fouls: Fouls
              };
            });
            var scaleValues = [0, 0, 0, 0, 0];
            if (maxNumber > 0) {
              var diff = maxNumber / 4;
              for (var i = 1; i < 5; i++) {
                scaleValues[4 - i] = diff * i;
              }
            }
            controller.set('summaryScaleValues', scaleValues);
            controller.set('summaryHistogramData', preparedData);
          });

          that.get('ajax').request('/refereeGameReportAccelerationsAndSprints?game=' + g.get("id") + '&referee=' + model.get("id")).then(function (json) {
            var AccelerationsAndSprints = that.get('postMatchService').prepareRefereeRuns(json, stadiumData.stadium.length, stadiumData.stadium.width);
            controller.set('AccelerationsAndSprints', AccelerationsAndSprints);
          });
        });
      });
    },

    resetController: function resetController(controller, isExiting, transition) {
      controller.setProperties({
        endDate: null,
        startDate: null,
        ttaToEpisodes: []
      });
    }
  });
});