define('msa/services/episodes-player', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        maxEpisodesToPlay: 10000,
        getVideoURL: function getVideoURL(videoHost, video, timeTech) {
            var additionalVideoLength = arguments.length <= 3 || arguments[3] === undefined ? 5000 : arguments[3];
            var secondsOffset = arguments.length <= 4 || arguments[4] === undefined ? 5000 : arguments[4];

            var millisInSecond = 1000;
            var src = videoHost + video;
            if (timeTech) {
                var startTime = Math.floor((timeTech - secondsOffset) / millisInSecond);
                var endTime = (timeTech + additionalVideoLength + secondsOffset) / millisInSecond;
                src += '?start=' + startTime + '&end=' + endTime;
            }
            return src;
        },
        getVideoStartAndEnd: function getVideoStartAndEnd(timeTech, additionalVideoLength) {
            var secondsOffset = arguments.length <= 2 || arguments[2] === undefined ? 5000 : arguments[2];

            var millisInSecond = 1000;
            var result = {};
            result.startTime = Math.floor((timeTech - secondsOffset) / millisInSecond);
            result.endTime = (timeTech + secondsOffset + additionalVideoLength) / millisInSecond;
            return result;
        }
    });
});