define('msa/routes/report', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        currentUser: service(),
        ajax: service(),

        model: function model() {
            return _ember['default'].A([_ember['default'].Object.create({ model: 'country', t: 'country', width: '16.6%', title: 'select country', step: 1 }), _ember['default'].Object.create({ model: 'competition', t: 'competition', query: 'country', width: '16.6%', title: 'select league', step: 2 }), _ember['default'].Object.create({ model: 'team', t: 'team', query: 'competition', width: '16.6%', title: 'select team', step: 3 }), _ember['default'].Object.create({ model: 'players', t: 'chose-player', query: 'team', width: '16.6%', title: 'select player', step: 4 }), _ember['default'].Object.create({ model: 'games', t: 'game', query: 'player', width: '16.6%', title: 'select games', step: 5 }), _ember['default'].Object.create({ model: '', t: 'episode', query: '', width: '16.6%', title: 'select episodes', step: 6 })]);
        },

        setupController: function setupController(controller, model) {
            controller.set('st', model);
            /*if (!controller.get('step')) {
                controller.set('step', 1);
            }*/
            controller.setProperties({
                step: 1,
                country: false,
                team: false,
                competition: false,
                games: _ember['default'].A(),
                players: _ember['default'].A()
            });
        },

        actions: {

            setModelG: function setModelG(m, modelName) {
                var that = this;
                var controller = this.controllerFor('report');
                if (controller.get(modelName).includes(m)) {
                    controller.get(modelName).removeObject(m);
                } else {
                    controller.get(modelName).addObject(m);
                }
            },

            getGames: function getGames() {
                var _this = this;

                console.log("There");
                var controller = this.controllerFor('report');
                var query = controller.get('players').map(function (m) {
                    return m.get('id');
                });

                controller.set('step', 5);

                this.store.query('game', { player: query }).then(function (d) {
                    console.log(d);
                    _this.render('outlets/select/game', {
                        into: 'report',
                        outlet: 'steps',
                        model: d
                    });
                });
            },

            renderStep: function renderStep(modelName, query, t) {
                var that = this;
                var templ = !!t ? t : 'country';
                var controller = templ;

                var that = this;
                if (modelName === 'country') {
                    that.get('ajax').request('/selectCountry').then(function (json) {
                        that.render('outlets/select/country', {
                            into: 'report',
                            outlet: 'steps',
                            model: json.country
                        });
                    });
                    return true;
                }

                if (modelName === 'competition') {
                    that.get('ajax').request('/selectCompetition?country=' + query.country).then(function (json) {
                        that.render('outlets/select/competition', {
                            into: 'report',
                            outlet: 'steps',
                            model: json.team
                        });
                    });
                    return true;
                }

                if (modelName === 'players') {
                    that.get('ajax').request('/reportPlayers?teams=' + query.team).then(function (json) {
                        that.render('outlets/select/chose-player', {
                            into: 'report',
                            outlet: 'steps',
                            model: json.players,
                            controller: 'outlets/select/chose-player'
                        });
                    });

                    return false;
                }
                if (modelName === 'reportGames') {
                    console.log('into reportGames');
                    var pl = that.controllerFor('report').get('players').mapBy('_id');
                    var q = '?teams=' + that.controllerFor('report').get('team.id');
                    for (var i = 0; i < pl.length; i++) {
                        q += '&players=' + pl[i];
                    }
                    that.get('ajax').request('/reportGames' + q).then(function (json) {
                        console.log('/reportGames', json);
                        that.render('outlets/select/game', {
                            into: 'report',
                            outlet: 'steps',
                            model: json.games,
                            controller: 'outlets/select/game'
                        });
                    });

                    that.controllerFor('report').set('step', 5);

                    return false;
                }
                if (modelName === 'reportEpisodes') {
                    that.render('outlets/select/episode', {
                        into: 'report',
                        outlet: 'steps',
                        controller: 'outlets/select/episode'
                    });

                    that.controllerFor('report').set('step', 6);

                    return false;
                }

                this.render('outlets/select/' + templ, {
                    into: 'report',
                    outlet: 'steps',
                    model: query ? that.store.query(modelName, query) : that.store.findAll('country'),
                    controller: 'outlets/select/' + controller
                });
            },

            setModel: function setModel(mn, m) {
                var controller = this.controllerFor('report');
                controller.set(mn, m);
                this.send('nextSteps');
            },

            nextSteps: function nextSteps() {
                var controller = this.controllerFor('report');
                var step = controller.get('step');
                controller.set('step', ++step);
                var steps = controller.get('st');
                if (step === 1) {
                    this.send('renderStep', 'country', false);
                } else {
                    var newModelName = steps[step - 1]['model'];
                    var t = steps[step - 1]['t'];
                    var query = {};
                    var model = controller.get(steps[step - 2]['model']);
                    query[steps[step - 1]['query']] = model._id ? model._id : model.get('id');
                    this.send('renderStep', newModelName, query, t);
                }
            },

            nextStepEp: function nextStepEp(m) {
                this.render('outlets/select/episode', {
                    into: 'report',
                    outlet: 'steps',
                    model: m,
                    controller: 'outlets/select/episode'
                });
            }

        }

    });
});