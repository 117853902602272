define('msa/components/rightside-players', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        store: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        currentUser: _ember['default'].inject.service(),

        getData: _ember['default'].observer('endDate', 'playersFilter', function () {
            var st = this.get('startDate');
            var end = this.get('endDate');
            var p = this.get('playersFilter');
            if (!st || !end || !p) return;
            this.send('getPlayers');
        }),

        didInsertElement: function didInsertElement() {
            var store = this.get('store');
            var that = this;
            store.findAll('player-amplua').then(function (amps) {
                that.set('ampluas', amps);
                that.set('playersFilter', amps.get('firstObject'));
            });
        },

        actions: {

            getPlayers: function getPlayers() {
                var that = this;
                var store = this.get('store');
                that.get('ajax').request('/' + that.get('path') + '?' + that.get('modelName') + '=' + that.get("model.id") + '&amplua=' + that.get('playersFilter.id') + '&startDate=' + that.get('startDate') + '&endDate=' + that.get('endDate')).then(function (players) {
                    that.set('fiteredPlayers', players[that.get('path')]);
                    that.send('setPlayerInRightSide', players[that.get('path')][0]);
                });
            },

            setPlayerInRightSide: function setPlayerInRightSide(m) {
                var that = this;
                var r;
                if (this.get('modelName') === 'team') {
                    r = 'teamPanelPlayerData';
                } else {
                    r = 'leaguePanelPlayerData';
                }

                var q = r + ('?' + that.get('modelName') + '=' + that.get('model.id') + '&player=' + m._id + '&startDate=' + that.get('startDate') + '&endDate=' + that.get('endDate'));
                that.get('ajax').request('/' + q).then(function (json) {
                    that.set('rightPlayer', {
                        name: m.first_name + ' ' + m.last_name,
                        // team: m.get('team.name'),
                        id: m._id,
                        actions: json.teamPanelPlayerData
                    });
                });
            }
        }

    });
});