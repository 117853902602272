define('msa/components/player-total-actions-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({
        activeType: null,
        didInsertElement: function didInsertElement() {
            this.prepareData();
            //Ember.run.later(this, this.drawLines, 1000);
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this.prepareData();
            //Ember.run.later(this, this.drawLines, 1000);
        },

        prepareData: function prepareData() {
            var that = this;
            //this.$( ".act-radio").off();
            //this.$(".act-radio").click(function(event) {
            //    that.send('changeType', parseInt(event.target.dataset.id));
            //});
            var fieldData = this.get('fieldData');
            var dataActions = _ember['default'].A();
            this.set('activeHalf', 1);
            var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
            dataActions.addObjects(filteredData);
            this.set('dataActions', dataActions);
        },
        actions: {
            changeHalf: function changeHalf(half) {
                console.log('half', half);
                this.set('activeHalf', half);
                var type = this.get('activeType');
                var fieldData = this.get('fieldData');
                var filteredData = null;
                if (half == 3) {
                    if (type !== null) {
                        filteredData = _npmLodash['default'].filter(fieldData, { 'type': type });
                    } else {
                        filteredData = fieldData;
                    }
                } else {
                    if (type !== null) {
                        filteredData = _npmLodash['default'].filter(fieldData, { 'type': type, 'half': half });
                    } else {
                        filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                    }
                }
                var dataActions = _ember['default'].A();
                dataActions.addObjects(filteredData);
                this.set('dataActions', dataActions);
            },
            changeType: function changeType(type) {
                console.log('type', type);
                var activeHalf = this.get('activeHalf');
                var fieldData = this.get('fieldData');
                console.log('fieldData', fieldData);
                var filteredData = null;
                if (activeHalf == 3) {
                    filteredData = _npmLodash['default'].filter(fieldData, { 'type': type });
                } else {
                    filteredData = _npmLodash['default'].filter(fieldData, { 'type': type, 'half': activeHalf });
                }
                var dataActions = _ember['default'].A();
                dataActions.addObjects(filteredData);
                this.set('activeType', type);
                this.set('dataActions', dataActions);
            },
            openPlayer: function openPlayer(element) {
                this.sendAction('openActionPlayer', element._id);
            }
        }
    });
});