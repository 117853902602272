define('msa/models/referee', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        first_name: _emberData['default'].attr('string'),
        last_name: _emberData['default'].attr('string'),
        name_short: _emberData['default'].attr('string'),
        weight: _emberData['default'].attr('string'),
        growth: _emberData['default'].attr('string', { defaultValue: '-' }),
        date_birth: _emberData['default'].attr('date'),
        photo: _emberData['default'].attr('string'),
        //role: DS.belongsTo('role', {async: true}),
        country: _emberData['default'].belongsTo('country', { async: true }),
        //custom_roles: DS.hasMany('role', {async: true}),

        fullName: (function () {
            return this.get('first_name') + ' ' + this.get('last_name');
        }).property('first_name,last_name'),

        age: (function () {
            return this.get('date_birth') ? (+(moment().diff(this.get('date_birth'), 'days') / 365)).toFixed(0) : '-';
        }).property('date_birth'),

        lbs: (function () {
            return Number(this.get('weight')) ? (+this.get('weight') * 2.20462).toFixed(1) : '-';
        }).property('weight'),

        fut: (function () {
            return this.get('growth') ? Math.floor(+this.get('growth') * 0.0328084) : '-';
        }).property('weight'),

        inch: (function () {
            return this.get('growth') ? (((+this.get('growth') * 0.0328084).toFixed(1) - Math.floor(+this.get('growth') * 0.0328084)) * 10).toFixed() : '-';
        }).property('weight'),

        birth: (function () {
            return this.get('date_birth') ? moment(this.get('date_birth')).format('L') : '-';
        }).property('date_birth')
    });
});