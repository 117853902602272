define('msa/controllers/outlets/select/competition', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		text: '',
		sortedModel: _ember['default'].computed('model.[]', 'text', function () {

			var text = this.get('text');

			if (!this.get('model.length')) {
				$('.loading').addClass('none');
				$('.noData').removeClass('none');
				return _ember['default'].A();
			}
			if (!text) {
				$('.loading').addClass('none');
				$('.noData').removeClass('none');
				return this.get('model').sortBy('name');
			}

			var m = this.get('model').map(function (i) {
				if (i.name.toLowerCase().indexOf(text.toLowerCase()) !== -1) return i;
			});

			$('.loading').addClass('none');
			return m.sortBy('name');;
		}),

		actions: {
			setCompetition: function setCompetition(m) {
				console.log(m);

				if (m.child.length) {
					this.set('sp', m.child);
					$('.secondLevel').removeClass('none');
				} else {
					$('.secondLevel').addClass('none');
					this.set('sp', null);
					this.send('setModel', 'competition', m);
				}
			}
		}
	});
});