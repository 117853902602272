define('msa/routes/post-match-report/player', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            this.set('m_id', params.m_id);
            return this.store.findRecord('player', params.p_id);
        },

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            this.store.findRecord('game', this.get('m_id')).then(function (g) {
                that.get('ajax').request('/stadium/' + g.get('stadium.id')).then(function (stadiumData) {
                    controller.set('game', g);
                    var homeTeamId = g.get('home_team.id');
                    var awayTeamId = g.get('away_team.id');
                    var playerTeamId = model.get('team.id');
                    var oppositeTeam = null;
                    if (playerTeamId != homeTeamId) {
                        oppositeTeam = homeTeamId;
                    } else {
                        oppositeTeam = awayTeamId;
                    }
                    that.store.findRecord('team', oppositeTeam).then(function (oT) {
                        controller.set("oT", oT);
                    });
                    controller.set('model', model);
                    that.get('ajax').request('/playerSeasons?player=' + model.get("id")).then(function (d) {
                        controller.set('seasons', d.seasons);
                        controller.set('currentSeason', d.seasons.get('lastObject'));
                        controller.set('startDate', d.seasons.get('lastObject.startDate'));
                        controller.set('endDate', d.seasons.get('lastObject.endDate'));
                        that.get('ajax').request('/playerGameReportActions?game=' + g.get("id") + '&player=' + model.get("id")).then(function (json) {
                            var playerActions = that.get('postMatchService').prepareDataForPlayerActionsOverview(json);
                            controller.set('playerActions', playerActions);
                        });
                        that.get('ajax').request('/playerGameReportTotalActions?game=' + g.get("id") + '&player=' + model.get("id")).then(function (json) {
                            console.log('json', json);
                            var playerGameReportTotalActions = that.get('postMatchService').prepareDataForPlayerGameReportTotalActions(json, stadiumData.stadium.width, stadiumData.stadium.length);
                            controller.set('playerGameReportTotalActions', playerGameReportTotalActions);
                        });
                        that.get('ajax').request('/playerGameReportPasses?game=' + g.get("id") + '&player=' + model.get("id")).then(function (json) {
                            var playerGameReportPasses = that.get('postMatchService').prepareDataForPlayerGameReportPasses(json, stadiumData.stadium.width, stadiumData.stadium.length);
                            controller.set('playerGameReportPasses', playerGameReportPasses.data);
                            controller.set('playerGameReportPassesSucessTable', playerGameReportPasses.sucessTable);
                        });
                        that.get('ajax').request('/playerGameReportPassesDistribution?game=' + g.get("id") + '&player=' + model.get("id")).then(function (json) {
                            var playerGameReportPassesDistribution = that.get('postMatchService').prepareDataForplayerGameReportPassesDistribution(json, stadiumData.stadium.width, stadiumData.stadium.length, model.get('id'));
                            controller.set('playerGameReportPassesDistribution', playerGameReportPassesDistribution);
                        });
                        that.get('ajax').request('/playerGameReportChallenges?game=' + g.get("id") + '&player=' + model.get("id")).then(function (json) {
                            var playerGameReportChallenges = that.get('postMatchService').prepareDataForPlayerGameReportChallenges(json, stadiumData.stadium.width, stadiumData.stadium.length);
                            controller.set('playerGameReportChallenges', playerGameReportChallenges);
                        });
                    });
                });
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                endDate: null,
                startDate: null,
                ttaToEpisodes: []
            });
        }
    });
});