define('msa/components/coverflow-slider', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        currentUser: _ember['default'].inject.service(),
        store: _ember['default'].inject.service(),

        slidesOb: _ember['default'].observer('slides', function () {
            $('.rightLoadings').removeClass('none');
            this.send('setPlayerInRightSide', this.get('slides')[0]);
            var that = this;
            _ember['default'].run.later(function () {
                var divBackup = $(".coverflow");
                $(".coverflow").remove();
                $(".playerSlide").html(divBackup);
                $(".coverflow").coverflow({
                    select: function select(event, data) {
                        that.send('setPlayerInRightSide', that.get('slides')[data.index]);
                    }
                });
                $('.rightLoadings').addClass('none');
            }, 1000);
        }),

        actions: {

            setPlayerInRightSide: function setPlayerInRightSide(m) {
                var that = this;
                var arr = this.get('currentUser.user.fav_players').mapBy('id');
                if (arr.includes(m._id)) {
                    this.set('isFavorite', true);
                } else {
                    this.set('isFavorite', false);
                }
                this.sendAction('setPlayerInRightSide', m);
            },

            addOrRemoveFromFavorites: function addOrRemoveFromFavorites(m) {
                var store = this.get('store');

                var type = !this.get('isFavorite') ? true : false;
                this.set('isFavorite', type);
                store.pushPayload('player', {
                    'player': {
                        _id: m._id
                    }
                });
                var pl = store.peekRecord('player', m._id);
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var u = this.get('currentUser.user');
                var mes;
                if (type) {
                    u.get('fav_players').pushObject(pl);
                    mes = 'Successfully added';
                }
                if (!type) {
                    u.get('fav_players').removeObject(pl);
                    mes = 'Successfully removed';
                }
                u.save().then(function (res) {
                    flashMessages.success(mes);
                })['catch'](function (err) {
                    flashMessages.danger('Something went wrong!');
                });
            }
        }
    });
});