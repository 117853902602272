define('msa/services/config', ['exports', 'ember', 'msa/config/environment'], function (exports, _ember, _msaConfigEnvironment) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Service.extend({
    config: _msaConfigEnvironment['default'],
    host: computed.reads('config.host'),
    size100Prefix: computed.reads('config.size100Prefix'),
    size70Prefix: computed.reads('config.size70Prefix'),
    size200Prefix: computed.reads('config.size200Prefix'),
    size250Prefix: computed.reads('config.size250Prefix'),
    size20Prefix: computed.reads('config.size20Prefix'),
    videoHost: computed.reads('config.videoHost')
  });
});