define('msa/routes/post-match-report/summary/average-positions', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        /*model: function(params) {
            return this.store.findRecord('game', params.m_id)
        },*/

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            var gameModel = that.controllerFor('post-match-report.summary').get("model");
            controller.set('model', gameModel);
            var homeTeamId = model.get('home_team.id');
            var awayTeamId = model.get('away_team.id');
            that.get('ajax').request('/stadium/' + model.get("stadium.id")).then(function (stadiumData) {
                that.get('ajax').request('/gameReportAvgPosition?game=' + model.get('id')).then(function (json) {
                    var teamArrays = {};
                    json.forEach(function (item) {
                        teamArrays[item.team] = item.array;
                    });
                    var homeFieldData = that.get('postMatchService').preparePlayerPositionsFieldsData(teamArrays[homeTeamId], stadiumData.stadium.width, stadiumData.stadium.length);
                    controller.set('homeFieldData', homeFieldData);
                    var awayFieldData = that.get('postMatchService').preparePlayerPositionsFieldsData(teamArrays[awayTeamId], stadiumData.stadium.width, stadiumData.stadium.length);
                    controller.set('awayFieldData', awayFieldData);
                    console.log(homeFieldData);
                });
            });
        }
    });
});