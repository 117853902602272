define('msa/components/league-referees-breakdown', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        breakdownActions: null,
        ajax: _ember['default'].inject.service(),
        actions: {
            setCurrentTta: function setCurrentTta(m) {
                console.log('set current tta');
                this.onSetCurrentTta(m);
            }
        }

    });
});