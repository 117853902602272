define('msa/components/player/skills-tab', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['wrap_diagrammes_box'],

        tabs: _ember['default'].A([
        //Ember.Object.create({ name: 'top skills', isActive: true, hasContent: false, contentComponent: 'stub1' }),
        //Ember.Object.create({ name: 'all skills', isActive: false, hasContent: false, contentComponent: 'stub2' }),
        _ember['default'].Object.create({ name: 'pass radar', isActive: true, hasContent: true, contentComponent: 'stub3' })]),

        actions: {
            changeTab: function changeTab(selectedTab) {
                var tabs = this.get('tabs');
                tabs.map(function (tab, index) {
                    var tabName = tab.get('name');
                    if (tabName == selectedTab.get('name')) {
                        tab.set('isActive', true);
                    } else {
                        tab.set('isActive', false);
                    }
                    return tab;
                });
                this.set('tabs', tabs);
            }
        }
    });
});