define('msa/helpers/acos', ['exports', 'ember-math-helpers/helpers/acos'], function (exports, _emberMathHelpersHelpersAcos) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersAcos['default'];
    }
  });
  Object.defineProperty(exports, 'acos', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersAcos.acos;
    }
  });
});