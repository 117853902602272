define('msa/components/reports-compare-attributes', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        maxWidthTable: { 4: 25.4, 6: 31, 7: 34, 8: 36, 9: 40, 10: 44, 5: 27, 3: 20, 2: 18, 1: 17 },
        postMatchService: _ember['default'].inject.service(),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            var title = this.get('title');
            var suffix = this.get('suffix') || '';
            var sActLeft = Math.abs(Math.round(this.get('sActLeft') * 10) / 10) || 0;
            var sActRight = Math.abs(Math.round(this.get('sActRight') * 10) / 10) || 0;
            this.set('sActLeft', sActLeft);
            this.set('sActRight', sActRight);
            var width_rm = this.get('postMatchService').calculateReportCompareWidth(this.get('rm'), this.get('mrm'), this.get('suffix'));
            var width_lm = this.get('postMatchService').calculateReportCompareWidth(this.get('lm'), this.get('mlm'), this.get('suffix'));
            var showSuccess = true;
            this.set('width_rm', width_rm);
            this.set('width_lm', width_lm);
            var rightTotal = Math.abs(Math.round(this.get('rm') * 10) / 10) || 0;
            var leftTotal = Math.abs(Math.round(this.get('lm') * 10) / 10) || 0;
            var r = rightTotal + suffix;
            var l = leftTotal + suffix;
            this.set('r', r);
            this.set('l', l);
            var widthSucessActionsLeft = this.get('postMatchService').calculateReportCompareWidth(sActLeft, this.get('mlm'), '%');
            var sucessPercentsLeft = Math.round(sActLeft / leftTotal * 100);
            var widthSucessPercentsLeft = this.get('postMatchService').calculateReportCompareWidth(sucessPercentsLeft, 100, '%');
            sucessPercentsLeft = sucessPercentsLeft + '%';
            this.set('widthSucessActionsLeft', widthSucessActionsLeft);
            this.set('sucessPercentsLeft', sucessPercentsLeft);
            this.set('widthSucessPercentsLeft', widthSucessPercentsLeft);
            var widthSucessActionsRight = this.get('postMatchService').calculateReportCompareWidth(sActRight, this.get('mrm'), '%');
            var sucessPercentsRight = Math.round(sActRight / rightTotal * 100);
            var widthSucessPercentsRight = this.get('postMatchService').calculateReportCompareWidth(sucessPercentsRight, 100, '%');
            sucessPercentsRight = sucessPercentsRight + '%';
            this.set('widthSucessActionsRight', widthSucessActionsRight);
            this.set('sucessPercentsRight', sucessPercentsRight);
            this.set('widthSucessPercentsRight', widthSucessPercentsRight);
            if (sActLeft === 0 && sActRight === 0) {
                showSuccess = false;
            } else {
                this.set('title', title + ' / %');
            }
            if (this.get('showTime')) {
                var rTime = moment.utc(this.get('rm')).format('mm ss');
                var rTimeArr = rTime.split(" ");
                var totalRTimeValue = rTimeArr[0] + 'm' + ' ' + rTimeArr[1] + 's';
                var lTime = moment.utc(this.get('lm')).format('mm ss');
                var lTimeArr = lTime.split(" ");
                var totalLTimeValue = lTimeArr[0] + 'm' + ' ' + lTimeArr[1] + 's';
                var widthRm = this.get('postMatchService').calculateReportCompareWidth(totalRTimeValue, totalRTimeValue, '%');
                var widthLm = this.get('postMatchService').calculateReportCompareWidth(totalLTimeValue, totalLTimeValue, '%');
                var _showSuccess = true;
                this.set('width_rm', 'width: 37%');
                this.set('width_lm', 'width: 37%');
                this.set('l', totalLTimeValue);
                this.set('r', totalRTimeValue);
                //this.set('l', 'l');
            }
            this.set('showSuccess', showSuccess);
        },

        actions: {
            openPlayer: function openPlayer(acts) {
                if (!acts) return;
                if (acts.length > 0) {
                    this.sendAction('openAttributesPlayer', acts);
                }
            }
        }
    });
});