define('msa/components/episodes-area', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        store: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        episodesPlayer: _ember['default'].inject.service(),

        stadium: [[{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 2, y: 0 }, { x: 3, y: 0 }, { x: 4, y: 0 }, { x: 5, y: 0 }], [{ x: 0, y: 1 }, { x: 1, y: 1 }, { x: 2, y: 1 }, { x: 3, y: 1 }, { x: 4, y: 1 }, { x: 5, y: 1 }], [{ x: 0, y: 2 }, { x: 1, y: 2 }, { x: 2, y: 2 }, { x: 3, y: 2 }, { x: 4, y: 2 }, { x: 5, y: 2 }]],

        //следим за зонами
        getEpisodeParams: _ember['default'].observer('coordsEnd', 'endDate', 'currentCompetition', 'coordsStart', 'ttaToEpisodes.[]', function () {
            this.send('getEpisodes');
        }),

        coordsEnd: null,
        coordsStart: null,

        ttaToEpisodes: _ember['default'].A(),

        didInsertElement: function didInsertElement() {
            var store = this.get('store');
            this.set('ttaToEpisodes', _ember['default'].A());
            this.set('coordsEnd', null);
            this.set('coordsStart', null);
            var that = this;
            store.findAll('tta').then(function (ttas) {
                store.findAll('ttaGroup').then(function (ttg) {
                    var newArr = ttg.map(function (i) {
                        var arr = ttas.filterBy('ttaGroup', i.get('id')).filterBy('episode', true);
                        i.set('tta', arr);
                        return i;
                    });
                    var disp = newArr.filterBy('displayable', true);
                    var notDisp = newArr.filterBy('displayable', false);
                    that.set('ttaGroups', disp);
                    that.set('ttaGroupsn', notDisp);
                });
            });
        },

        actions: {

            setTtaToEpisode: function setTtaToEpisode(t) {
                if (this.get('ttaToEpisodes').includes(t)) {
                    this.get('ttaToEpisodes').removeObject(t);
                } else {
                    this.get('ttaToEpisodes').addObject(t);
                }
            },

            getVideoPlayer: function getVideoPlayer() {
                if (this.get('episodes.ep') === 0 || this.get('episodes.ep') > this.get('episodesPlayer').maxEpisodesToPlay) return false;
                this.set('videoPlayer', true);
            },

            getEpisodes: function getEpisodes() {
                var that = this;
                $('.episodeLoading').removeClass('none');
                var s = that.get('coordsStart') || [];
                var e = that.get('coordsEnd') || [];
                var t = that.get('ttaToEpisodes').mapBy('id');
                var pl = !!that.get('players') ? that.get('players').mapBy('_id') : [];
                var gm = !!that.get('games') ? that.get('games').mapBy('_id') : [];
                var tm = !!that.get('team.id') ? [].push(that.get('team.id')) : [];
                var md = !!that.get('model.id') ? that.get('model.id') : false;
                var req = {
                    HSize: 6,
                    VSize: 3
                };
                if (s.length) req.cords = s;
                if (e.length) req.target_cords = e;
                if (t.length) req.tta = t;
                if (pl.length) req.players = pl;
                if (that.get('startDate')) req.startDate = that.get('startDate');
                if (that.get('endDate')) req.endDate = that.get('endDate');
                if (gm.length) req.games = gm;
                if (tm.length) req.teams = tm;
                if (md.length) req[that.get('modelName')] = md;
                if (!!that.get("currentCompetition.name") && that.get("currentCompetition.name") !== 'OVERALL') req.competition = that.get('currentCompetition.id');
                if (!s.length && !t.length && !e.length) {
                    that.set("episodes", {
                        m: 0,
                        ep: 0,
                        t: 0
                    });
                    $('.episodeLoading').addClass('none');
                    return false;
                };

                that.get('ajax').request('/' + that.get('query'), {
                    method: 'POST',
                    data: JSON.stringify(req),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    var sum = 0;
                    var m = json ? json.length : 0;
                    for (var i = 0; i < m; i++) sum += +json[i];
                    var time = moment.utc(sum * 10000).format('HH:mm:ss');
                    if (time === '00:00:00') {
                        that.set("episodes", {
                            m: 0,
                            ep: 0,
                            t: 0
                        });
                        $('.episodeLoading').addClass('none');
                        return false;
                    };
                    that.set("episodes", {
                        m: m,
                        ep: sum,
                        t: time
                    });
                    $('.episodeLoading').addClass('none');
                });
            }
        }

    });
});