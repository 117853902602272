define('msa/controllers/outlets/select/country', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		text: '',
		sortedModel: _ember['default'].computed('model.[]', 'text', function () {
			var text = this.get('text');
			if (!text) return this.get('model').sortBy('name');
			var m = this.get('model').map(function (i) {
				if (i.name.toLowerCase().indexOf(text.toLowerCase()) !== -1) return i;
			});

			return m.sortBy('name');
		})
	});
});