define('msa/controllers/teams/show/overview', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        ajax: _ember['default'].inject.service(),

        half: 1,
        hS: 0,
        hSp: 15,
        hEp: 30,
        hE: 45,
        x: 0,
        y: 0,

        actionsTeamInStadiumArea: null, //действия выбранной команды
        actionsOpponentInStadiumArea: null, //действия противника

        ctrl: _ember['default'].inject.controller('teams.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),
        currentCompetition: _ember['default'].computed.alias('ctrl.currentCompetition'),

        //следим за изменениями даты
        getData: _ember['default'].observer('endDate', 'model.id', 'currentCompetition', function () {

            var q = {
                team: this.get("model.id"),
                startDate: this.get('startDate'),
                endDate: this.get('endDate')
            };

            var c = this.get('currentCompetition');
            if (!q.startDate || !q.endDate || !c) return;

            var qStr = '?team=' + q.team + '&startDate=' + q.startDate + '&endDate=' + q.endDate;
            if (c.get("name") !== 'OVERALL') qStr += '&competition=' + c.get('id');

            this.send('getGoals');
            this.send('getGoalsTotal');
            this.send('getPassFreq', qStr);
            this.send('getAttrs', qStr);
        }),

        //сортируем goalsScored по командам
        sortgoalsScored: _ember['default'].observer('goalsScored', function () {
            var team = this.get('model.id');
            var n = this.get('goalsScored').map(function (el) {
                el.set('current_team', team);
                var isOur = el.get('team.id') === team;
                el.set('isOur', isOur);
                return el;
            });
            this.set('actionsOpponentInStadiumArea', n.filterBy('isOur', false));
            this.set('actionsTeamInStadiumArea', n.filterBy('isOur', true));
        }),

        //наблюдаем за кликами с поля
        observToHalf: _ember['default'].observer('half', function () {
            this.send('getGoals');
            this.send('getGoalsTotal');
        }),

        poleType: 'goals-scored',

        actions: {

            getPassFreq: function getPassFreq(q) {
                $('.getPassFreqLoading').removeClass('none');
                var that = this;
                that.get('ajax').request('/passFrequency' + q).then(function (json) {
                    that.set('passFrequency', json.passes);
                    that.set('totalGames', json.games);
                    that.get('ajax').request('/lastGamePassFrequency' + q).then(function (json2) {
                        that.set('passFrequencyLast', json2.passes);
                        console.log('There');
                        $('.getPassFreqLoading').addClass('none');
                        that.get('ajax').request('/averagePassFrequency' + q).then(function (json3) {
                            for (var i = 0; i < json3.passes.length; i++) {
                                json3.passes[i].passes = json3.passes[i].passes.toFixed(1);
                            };
                            var passPerGame = json3.passes.filter(function (item) {
                                return item.player && item.target_player;
                            });
                            that.set('passPerGame', passPerGame);
                        });
                    });
                });
            },

            getAttrs: function getAttrs(q) {
                $('.getAttrsLoading').removeClass('none');
                var that = this;
                that.get('ajax').request('/attributesV2' + q).then(function (json) {
                    var l = json.length;
                    for (var i = 0; i < json.length; i++) {
                        if (json[i].hasOwnProperty('success_actions')) json[i].success_actions_calculated = (json[i].success_actions || 0) / (json[i].actions || 1) * 100;
                        if (json[i].teamTTA && json[i].teamTTA.has_success) json[i].success_word = "Success";
                        if (json[i].teamTTA && json[i].teamTTA.has_won) json[i].success_word = "Won";
                        if (json[i].teamTTA && json[i].teamTTA.has_on_target) json[i].success_word = "On Target";
                        if (json[i].teamTTA && json[i].teamTTA.has_accurate) json[i].success_word = "Accurate";
                    }
                    var best = [json[0], json[1], json[2], json[3]];
                    var worst = [json[l - 4], json[l - 3], json[l - 2], json[l - 1]];
                    that.set('bestAttributes', best);
                    that.set('worstAttributes', worst);
                    $('.getAttrsLoading').addClass('none');
                });
            },

            setPoleType: function setPoleType(m) {
                this.set('poleType', m);
                this.send('getGoals');
                this.send('getGoalsTotal');
            },

            //кликаем по полю
            setAreaNum: function setAreaNum(x, y) {
                this.set('x', x);
                this.set('y', y);
                this.send('getGoals');
                this.send('getGoalsTotal');
            },

            //выбираем период
            setHalf: function setHalf(h) {
                if (h === 1) {
                    this.set('hS', '0');
                    this.set('hSp', '15');
                    this.set('hEp', '30');
                    this.set('hE', '45');
                }
                if (h === 2) {
                    this.set('hS', '45');
                    this.set('hE', '90');
                    this.set('hSp', '60');
                    this.set('hEp', '75');
                }
                if (h === 3) {
                    this.set('hS', '90');
                    this.set('hE', '135');
                    this.set('hSp', '105');
                    this.set('hEp', '120');
                }

                this.set('half', h);
            },

            getGoalsTotal: function getGoalsTotal() {
                var req = this.get('poleType') === 'goals-scored' ? 'goalsTotal2' : 'commonAssistsTotal';
                var that = this,
                    q = '?team=' + that.get('model.id') + '&startDate=' + that.get('startDate') + '&endDate=' + that.get('endDate') + '&half=' + that.get('half') + '&HSize=6&VSize=3';
                if (that.get("currentCompetition.name") !== 'OVERALL') q += '&competition=' + that.get('currentCompetition.id');

                that.get('ajax').request('/' + req + q).then(function (json) {
                    // Goals scored, sordet by XY coords:
                    // 00 01 02 10 11 12 20 21 22 30 31 32 40 41 42 50 51 52
                    that.set('goalsScCounts', json);
                });
            },

            //получаем инфу по матчам
            getGoals: function getGoals() {
                $('.getGoalsLoading').removeClass('none');
                var req = this.get('poleType');
                var that = this,
                    q = {
                    team: that.get("model.id"),
                    x: that.get('x'),
                    y: that.get('y'),
                    half: that.get('half'),
                    startDate: that.get("startDate"),
                    endDate: that.get("endDate"),
                    HSize: 6,
                    VSize: 3
                };

                if (!q.startDate || !q.endDate) return;
                if (that.get("currentCompetition.name") !== 'OVERALL') q.competition = that.get("currentCompetition.id");

                that.store.query(req, q).then(function (json) {
                    that.set('goalsScored', json);
                    $('.getGoalsLoading').addClass('none');
                });
            }
        }
    });
});