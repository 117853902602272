define('msa/routes/teams/show', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        beforeModel: function beforeModel(transition) {
            //if(!("countryId" in transition.queryParams)) {
            //    console.log("no property");
            //}
            var that = this;
            console.log("transition", transition.queryParams);
            //that.set('competitionId', transition.queryParams.competitionId);
        },

        model: function model(params) {
            return this.store.findRecord('team', params.m_id);
        },

        ajax: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var _this = this;

            var that = this;
            controller.set('model', model);

            that.store.query('seasonsAndLeague', { team: model.get('id') }).then(function (d) {

                controller.set('seasons', d);
                if (d.get('length') === 0) return;
                var currentSeason = d.get('lastObject');

                if (!controller.get('all')) {
                    var allLeaguesRecord = _this.store.createRecord('competition', {
                        _id: '1',
                        name: 'OVERALL',
                        name_short: 'OVERALL'
                    });
                    controller.set('all', allLeaguesRecord);
                    currentSeason.get('competitions').addObject(controller.get('all'));
                    controller.set('currentCompetition', allLeaguesRecord);
                }

                //получаем total data
                that.get('ajax').request('/totalData?team=' + model.get("id")).then(function (json) {
                    controller.set('totals', json.totalData);
                });

                that.get('ajax').request('/teamAveragePlayerData?team=' + model.get("id")).then(function (json) {
                    var s = json.teamAveragePlayerData && json.teamAveragePlayerData.aWeight ? json.teamAveragePlayerData.aWeight.toFixed(1) : '-';
                    var b = json.teamAveragePlayerData && json.teamAveragePlayerData.aGrowth ? json.teamAveragePlayerData.aGrowth.toFixed(1) : '-';
                    var a = json.teamAveragePlayerData && json.teamAveragePlayerData.aAge ? json.teamAveragePlayerData.aAge.toFixed(1) : '-';
                    controller.set('playersSt', {
                        age: a,
                        height: b,
                        weight: s,
                        lbs: s !== '-' ? (s * 2.20462).toFixed(1) : '-',
                        fut: b !== '-' ? Math.floor(b * 0.0328084) : '-',
                        inch: b !== '-' ? (((b * 0.0328084).toFixed(1) - Math.floor(b * 0.0328084)) * 10).toFixed() : '-'
                    });
                });

                controller.set('currentSeason', d.get('lastObject'));
                controller.set('startDate', d.get('lastObject.startDate'));
                controller.set('endDate', d.get('lastObject.endDate'));
                controller.send('getStatistica');
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                endDate: null,
                startDate: null,
                ttaToEpisodes: [],
                currentCompetition: controller.get('all') });
        }
    });
});
//действия выбранной команды