define('msa/controllers/leagues/show/referees', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        ajax: _ember['default'].inject.service(),

        ctrl: _ember['default'].inject.controller('leagues.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),
        actions: {
            setCurrentTta: function setCurrentTta(m) {
                console.log('kak naod');
                this.set('currentTta', m);
                this.send('getStatistic');
            },
            getStatistic: function getStatistic() {
                var that = this;
                var st = this.get('startDate');
                var end = this.get('endDate');
                var tta = this.get('currentTta.id');
                if (!st || !end || !tta) return;
                $('.stLoading').removeClass('none');
                that.get('ajax').request('/leagueRefereeStatistic?competition=' + this.get("model.id") + '&refereeTTA=' + tta + '&startDate=' + this.get("startDate") + '&endDate=' + this.get("endDate")).then(function (json) {
                    var preparedArr = [];
                    json.leagueStatistic.forEach(function (item, i, arr) {
                        if (item.referee != null) {
                            preparedArr.push(item);
                        }
                    });
                    var newResp = preparedArr.map(function (d, i) {
                        d.i = ++i;
                        var total = d.row.reduce(function (a, b) {
                            return a + b;
                        }, 0);
                        d.row.push(Math.round(total * 100) / 100);
                        return d;
                    });
                    console.log(newResp);
                    that.set('statisticTab', newResp);
                    that.set('someValue', '{text: someText} ' + Math.random());
                    $('.stLoading').addClass('none');
                });
            }

        }
    });
});