define('msa/components/player-passes-distribution', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            this.prepareData();
            //Ember.run.later(this, this.drawLines, 1000);
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this.prepareData();
            //Ember.run.later(this, this.drawLines, 1000);
        },

        prepareData: function prepareData() {
            var that = this;
            this.$(".act-radio").off();
            this.$(".act-radio").click(function (event) {
                that.send('changeType', parseInt(event.target.dataset.id));
            });
            var fieldData = this.get('fieldData');
            var dataActions = _ember['default'].A();
            this.set('activeHalf', 1);
            var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
            dataActions.addObjects(filteredData);
            this.set('dataActions', dataActions);
            this.send('prepareCurrentTableData');
            this.send('drawLines');
        },

        actions: {
            drawLines: function drawLines() {
                var half = this.get('activeHalf');
                var fieldData = this.get('lines');
                var filteredData = null;
                filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                var elem = this.$('canvas.my_new_line')[0];
                var table = this.$('.margin__table')[0];
                var ctx = elem.getContext('2d');

                elem.width = this.$().width();
                elem.height = this.$().height();
                ctx.clearRect(0, 0, elem.width, elem.height);
                //console.log(dataActions); fieldData.length
                for (var i = 0; i < filteredData.length; i++) {
                    ctx.beginPath();
                    ctx.lineWidth = 3;
                    var comb = filteredData[i];
                    var fromx = comb.loc_x * elem.width * 0.01,
                        fromy = comb.loc_y * elem.height * 0.01,
                        tox = comb.target_loc_x * elem.width * 0.01,
                        toy = comb.target_loc_y * elem.height * 0.01;
                    var angle = Math.atan2(toy - fromy, tox - fromx);
                    var headlen = 4;
                    ctx.moveTo(fromx, fromy);
                    ctx.lineTo(tox, toy);
                    ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
                    ctx.moveTo(tox, toy);
                    ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
                    if (filteredData[i].isReceived > 0) {
                        ctx.strokeStyle = '#3e6c60';
                    } else {
                        ctx.strokeStyle = '#bace4d';
                    }
                    ctx.stroke();
                }
            },
            openModal: function openModal(acts) {
                if (acts.length > 0) {
                    this.set('actionsArray', acts);
                    this.set('showVideoPlayer', true);
                }
            },
            closePlayer: function closePlayer() {
                this.set('showVideoPlayer', false);
            },
            prepareCurrentTableData: function prepareCurrentTableData() {
                var half = this.get('activeHalf');
                var positionsTable = this.get('positionsTable');
                var activeTable = _npmLodash['default'].filter(positionsTable, { 'half': half });
                this.set('activeTable', activeTable[0]);
            },
            changeHalf: function changeHalf(half) {
                this.set('activeHalf', half);
                var fieldData = this.get('fieldData');
                var filteredData = null;
                filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                var dataActions = _ember['default'].A();
                dataActions.addObjects(filteredData);
                this.set('dataActions', dataActions);
                this.send('prepareCurrentTableData');
                this.send('drawLines');
            }
        }
    });
});
//{{action 'openPlayer' i on='click'}}