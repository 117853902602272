define('msa/routes/favorites', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: service(),
    model: function model() {
      return this.get('currentUser.user');
    }
  });
});

//import LoadingIndicator 		from 'uti-ui/mixins/loading-indicator';