define('msa/controllers/teams/show/average', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Controller.extend({

        ctrl: _ember['default'].inject.controller('teams.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),
        currentCompetition: _ember['default'].computed.alias('ctrl.currentCompetition'),
        ajax: _ember['default'].inject.service(),

        getPerf: _ember['default'].observer('endDate', 'model.id', 'currentCompetition.name', function () {
            this.send('getTeamPerf');
        }),

        playersFilter: null,
        orFilter: false,

        actions: {
            sortModel: function sortModel(model, row) {
                var f = this.get('orFilter');
                var m = this.get(model).sort(function (a, b) {
                    var a = +a[row] || 0;
                    var b = +b[row] || 0;
                    if (!f) return a - b;
                    if (f) return b - a;
                });

                this.toggleProperty('orFilter');
                this.set(model, null);
                this.set(model, m);
            },

            getTeamPerf: function getTeamPerf() {
                var q = {
                    team: this.get("model.id"),
                    startDate: this.get('startDate'),
                    endDate: this.get('endDate')
                };

                var c = this.get('currentCompetition');
                if (!q.startDate || !q.endDate || !c) return;

                var qStr = '?team=' + q.team + '&startDate=' + q.startDate + '&endDate=' + q.endDate;
                if (c.get("name") !== 'OVERALL') qStr += '&competition=' + c.get('id');

                $('.getTeamPerfLoading').removeClass('none');
                var that = this;
                that.get('ajax').request('/teamPerformance' + qStr).then(function (json) {
                    var arr = [];
                    for (var i = 0; i < json.length; i++) {
                        arr.push({
                            name: json[i].tta.name,
                            averageSuccess: json[i].averageSuccess ? json[i].averageSuccess.toFixed(1) : '-',
                            averageTotal: json[i].averageTotal ? json[i].averageTotal.toFixed(1) : '-',
                            success: json[i].success || '-',
                            total: json[i].total || '-'
                        });
                    }
                    arr = _npmLodash['default'].sortBy(arr, 'name');
                    that.set('teamPerformance', arr);
                    $('.getTeamPerfLoading').addClass('none');
                });
            }

        }
    });
});