define('msa/components/graphic-chart', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        store: _ember['default'].inject.service(),

        didInsertElement: function didInsertElement() {
            var ctx = document.getElementById("myChart");
            var myChart = new Chart(ctx, this.get('chartParams'));
            this.set('chart', myChart);
        },

        didUpdate: function didUpdate() {
            this.get('chart').config.data.labels = this.get('chartParams').data.labels;
            this.get('chart').data.datasets[0].data = this.get('chartParams').data.datasets[0].data;
            if (this.get('chart').data.datasets.length > 1) {
                this.get('chart').data.datasets[1].data = this.get('chartParams').data.datasets[1].data;
            }
            this.get('chart').update();
        }
    });
});