define('msa/routes/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        currentUser: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        model: function model() {
            return this.get('currentUser.user');
        },

        setupController: function setupController(controller, model) {
            var that = this;
            var newFavPlayers = [];
            var newFavTeams = [];
            var newFavCompetitions = [];
            this.get("currentUser.user.fav_players").then(function (favPlayers) {
                favPlayers.forEach(function (item, i) {
                    newFavPlayers.push({ id: item.id, photo: item.data.photo });
                });
                controller.set('favPlayers', newFavPlayers);
            });

            this.get("currentUser.user.fav_teams").then(function (favTeams) {
                favTeams.forEach(function (item, i) {
                    newFavTeams.push({ id: item.id, logo: item.data.logo });
                });
                controller.set('favTeams', newFavTeams);
            });

            this.get("currentUser.user.fav_competitions").then(function (favCompetitions) {
                favCompetitions.forEach(function (item, i) {
                    newFavCompetitions.push({ id: item.id, logo: item.data.logo });
                });
                controller.set('favCompetitions', newFavCompetitions);
            });

            this.get('ajax').request('/boardGamesStat?team=' + that.get("currentUser.user.team.id")).then(function (json) {

                var obj = {};

                for (var key in json) {
                    var aw, hm;
                    if (!json[key].table || !json[key].table.length) continue;
                    console.log(key);
                    json[key].table[0].acts = json[key].table[0].acts.sortBy('act');
                    json[key].table[1].acts = json[key].table[1].acts.sortBy('act');

                    if (json[key].game.away_team === json[key].table[0].team._id) {
                        aw = 0;
                        hm = 1;
                    } else {
                        aw = 1;
                        hm = 0;
                    }

                    obj[key] = {
                        date: moment(json[key].game.date).format('LL'),
                        home: json[key].table[hm].team.logo,
                        homeSc: json[key].game.team_home_score,
                        away: json[key].table[aw].team.logo,
                        awaySc: json[key].game.team_away_score,
                        homeId: json[key].table[hm].team._id,
                        awayId: json[key].table[aw].team._id,
                        actions: []
                    };

                    var actsObj = {};

                    for (var h = 0; h < json[key].table[hm].acts.length; h++) {
                        actsObj[json[key].table[hm].acts[h].act] = actsObj[json[key].table[hm].acts[h].act] || {};
                        actsObj[json[key].table[hm].acts[h].act][hm] = json[key].table[hm].acts[h].total;
                        actsObj[json[key].table[hm].acts[h].act][aw] = 0;
                    }

                    for (var a = 0; a < json[key].table[aw].acts.length; a++) {
                        actsObj[json[key].table[aw].acts[a].act] = actsObj[json[key].table[aw].acts[a].act] || {};
                        actsObj[json[key].table[aw].acts[a].act][aw] = json[key].table[aw].acts[a].total;
                    }

                    for (var k in actsObj) {
                        obj[key].actions.push({
                            name: k,
                            home: actsObj[k][hm] || '0',
                            away: actsObj[k][aw] || '0'
                        });
                    }
                }
                console.log(obj);
                if (obj.last) controller.set('last', obj.last);
                if (obj.prev) controller.set('prev', obj.prev);
                if (obj.enemyLast) controller.set('enemy', obj.enemyLast);
            });
        }
    });
});

//import LoadingIndicator           from 'uti-ui/mixins/loading-indicator';