define('msa/mixins/loading-indicator', ['exports', 'ember'], function (exports, _ember) {

    NProgress.configure({ showSpinner: false });

    exports['default'] = _ember['default'].Mixin.create({
        actions: {
            willTransition: function willTransition() {
                NProgress.start();
                this.send('removeModal');
                _ember['default'].run.later(function () {
                    NProgress.done();
                }, 1000);
                return true;
            },
            error: function error() {
                NProgress.done();
                return true;
            }
        }
    });
});