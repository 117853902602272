define('msa/routes/videolab', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        /* model: function(params) {
             return this.store.findRecord('competition', params.m_id)
         }, */

        ajax: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            console.log('setupController');
            var that = this;
            controller.set('jsTreePlugins', 'dnd');
            controller.set('jsTreeThemes', { "icons": false });
            var jsTreeData = function jsTreeData(obj, cb) {
                var query = '/videofolder';
                if (obj.id == '#') {
                    query += '?parent=null&startTime[$exists]=false'; //&startTime[$exists]=false
                } else {
                        query += '?parent=' + obj.id + '&startTime[$exists]=false';
                    }
                that.get('ajax').request(query).then(function (json) {
                    var foldersData = json.videofolder;
                    var preparedData = [];
                    foldersData.forEach(function (item) {
                        var parent = item.parent === null ? '#' : item.parent;
                        var preparedItem = { "id": item._id, "parent": parent, "text": item.name };
                        if (item.hasChild) {
                            preparedItem.children = item.hasChild;
                        }
                        preparedData.push(preparedItem);
                    });
                    cb.call(this, preparedData);
                });
            };
            controller.send('getCurrentFileList');
            controller.set('addedSnippets', []);
            controller.set('jsTreeData', jsTreeData);
            _ember['default'].run.schedule("afterRender", this, function () {
                console.log('another one afterRender');
                $('video, audio').mediaelementplayer({
                    alwaysShowControls: true,
                    features: ['loop', 'playpause', 'current', 'progress', 'fullscreen'],
                    success: function success(player, node) {
                        player.addEventListener('ended', function (e) {
                            var videoContainer = controller.get('videoContainer');
                            var currentVideo = controller.get('currentVideo');
                            var playerId = $('#player1').closest('.mejs__container').attr('id');
                            var playerInstance = mejs.players[playerId];
                            if (videoContainer.length > 1) {
                                var videoIndex = videoContainer.indexOf(currentVideo);
                                if (videoContainer.objectAt(videoIndex + 1)) {
                                    controller.set('currentVideo', videoContainer.objectAt(videoIndex + 1));
                                    playerInstance.setSrc(videoContainer.objectAt(videoIndex + 1));
                                    playerInstance.media.play();
                                }
                            }
                        });
                    }
                });
                $('.mejs__controls').addClass('mejs__controls_videolab');
            });
        },

        actions: {
            handleJstreeEventDidChange: function handleJstreeEventDidChange(data) {
                console.log(data);
            },
            didTransition: function didTransition() {
                //this.controller.get('errors.base').clear();
                return true; // Bubble the didTransition event
            }
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({
                endDate: null,
                startDate: null
            });
        }
    });
});