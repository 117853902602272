define('msa/components/compare-histogram', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        breakdownActions: null,
        didInsertElement: function didInsertElement() {
            /*const breakdownActions = this.get('breakdownActions');
            this.set('firstRow', breakdownActions[0]);
            this.set('secondRow', breakdownActions[1]);
            this.set('thirdRow', breakdownActions[2]); */
        }
    });
});