define('msa/routes/leagues/show/statistic', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            var model = that.controllerFor('leagues.show').get("model");
            controller.set('model', model);
            that.store.findAll('teamTta').then(function (ttas) {
                that.store.findAll('teamTtaGroup').then(function (ttg) {
                    var newArr = ttg.map(function (i) {
                        var arr = ttas.filterBy('teamTTAGroup', i.get('id'));
                        i.set('tta', arr);
                        return i;
                    });
                    var disp = newArr.filterBy('displayable', true);
                    var notDisp = newArr.filterBy('displayable', false);
                    controller.set('ttaGroups', newArr);
                    //controller.set('ttaGroupsn', notDisp);
                    controller.set('currentTta', newArr.get('firstObject.tta.firstObject'));
                });
            });
        },

        resetController: function resetController(controller, isExiting, transition) {
            controller.setProperties({});
        }
    });
});