define('msa/models/player-tta', ['exports', 'ember-data', 'msa/libs/type-shims'], function (exports, _emberData, _msaLibsTypeShims) {
	exports['default'] = _emberData['default'].Model.extend({
		cl: _msaLibsTypeShims.string,
		singular: _msaLibsTypeShims.string,
		playerTTAGroup: _msaLibsTypeShims.string,
		plural: _msaLibsTypeShims.string,
		inQuery: _emberData['default'].attr('boolean', { defaultValue: false }),
		visible: _emberData['default'].attr('boolean', { defaultValue: true }),
		parameter: _emberData['default'].attr('boolean', { defaultValue: false }),
		episode: _emberData['default'].attr('boolean', { defaultValue: false }),
		has_won: _emberData['default'].attr('boolean', { defaultValue: false }),
		has_success: _emberData['default'].attr('boolean', { defaultValue: false }),
		has_on_target: _emberData['default'].attr('boolean', { defaultValue: false }),
		orderIndex: _msaLibsTypeShims.number

	});
});
//name: Ember.computed.reads('plural'),