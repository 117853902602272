define('msa/router', ['exports', 'ember', 'msa/config/environment'], function (exports, _ember, _msaConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _msaConfigEnvironment['default'].locationType,
    rootURL: _msaConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('select-team');
    this.route('select-player');
    this.route('login');
    this.route('reset-password');
    this.route('select-league');
    this.route('search-players');
    this.route('favorites');

    this.route('players', function () {
      this.route('show', { path: ":m_id" }, function () {
        this.route('overview');
        this.route('full-matches');
        this.route('episodes-search');
      });
      this.route('compare');
    });
    this.route('referees', function () {
      this.route('show', { path: ":m_id" }, function () {
        this.route('overview');
        this.route('average-stats');
        this.route('full-matches');
        this.route('episodes-search');
      });
    });

    this.route('post-match-report', function () {
      this.route('summary', { path: "/summary/:m_id" }, function () {
        this.route('overview');
        this.route('challenges');
        this.route('attacks');
        this.route('passes');
        this.route('average-positions');
      });

      this.route('player', { path: "/player/:p_id/:m_id" });
      this.route('referee', { path: "/referee/:r_id/:m_id" });

      this.route('passes', { path: '/passes/:m_id' }, function () {
        this.route('total');
        this.route('non-attacking');
        this.route('attacking');
        this.route('forward');
        this.route('back');
        this.route('left');
        this.route('right');
        this.route('short');
        this.route('middle');
        this.route('long');
      });
      this.route('passes-page2', { path: '/passes-page2/:m_id' }, function () {
        this.route('key');
        this.route('to-the-box');
        this.route('crosses');
        this.route('from-defensive-to-middle');
        this.route('from-middle-to-attacking');
        this.route('to-attacking');
      });
      this.route('attacks', { path: '/attacks/:m_id' }, function () {
        this.route('total');
        this.route('with-shots');
        this.route('counter-attacks');
        this.route('positional-attacks');
        this.route('set-piece-attacks');
        this.route('touches-in-the-pbox');
      });
      this.route('set-pieces', { path: '/set-pieces/:m_id' }, function () {
        this.route('overview');
        this.route('throw-ins');
        this.route('free-kicks');
        this.route('corners');
      });
      this.route('cards', { path: "/cards/:m_id" });
      this.route('possession', { path: "/possession/:m_id" });
      this.route('defensive', { path: '/defensive/:m_id' }, function () {
        this.route('overview');
        this.route('challenges');
        this.route('air-challenges');
        this.route('tackles');
        this.route('interceptions');
        this.route('clearances');
        this.route('ball-recovery');
        this.route('offside');
        this.route('rebounds');
        this.route('picking-up-free-ball');
        this.route('losses');
      });
      this.route('goals', { path: "/goals/:m_id" });
    });

    this.route('leagues', function () {
      this.route('show', { path: ":m_id" }, function () {
        this.route('overview');
        this.route('statistic');
        this.route('full-matches');
        this.route('episodes-search');
        this.route('players');
        this.route('referees');
      });
    });

    this.route('teams', function () {
      this.route('compare');
      this.route('show', { path: ":m_id" }, function () {
        this.route('overview');
        this.route('squad');
        this.route('average');
        this.route('full-matches');
        this.route('episodes-search');
        this.route('dynamics');
      });
    });
    this.route('video-player');
    this.route('report');
    this.route('reports', function () {
      this.route('country');
      this.route('competition');
      this.route('team');
      this.route('player');
      this.route('game');
      this.route('episode');
    });
    this.route('team-statistics', function () {
      this.route('country');
      this.route('competition');
      this.route('team');
    });
    this.route('league-statistics', function () {
      this.route('country');
      this.route('competition');
    });
    this.route('player-statistics', function () {
      this.route('country');
      this.route('competition');
      this.route('team');
      this.route('player');
    });
    this.route('search-results');
    this.route('videolab');
  });

  Router.reopen({
    notifyYandexMetrika: (function () {
      if (window[_msaConfigEnvironment['default'].yaCounterName] !== undefined) {
        window[_msaConfigEnvironment['default'].yaCounterName].hit(this.get('url'));
      }
    }).on('didTransition'),
    notifyGoogleAnalytics: (function () {
      return ga('send', 'pageview', {
        'page': this.get('url'),
        'title': this.get('url')
      });
    }).on('didTransition')
  });

  exports['default'] = Router;
});