define('msa/components/player-episodes-area', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        store: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        episodesPlayer: _ember['default'].inject.service(),

        stadium: [[{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 2, y: 0 }, { x: 3, y: 0 }, { x: 4, y: 0 }, { x: 5, y: 0 }], [{ x: 0, y: 1 }, { x: 1, y: 1 }, { x: 2, y: 1 }, { x: 3, y: 1 }, { x: 4, y: 1 }, { x: 5, y: 1 }], [{ x: 0, y: 2 }, { x: 1, y: 2 }, { x: 2, y: 2 }, { x: 3, y: 2 }, { x: 4, y: 2 }, { x: 5, y: 2 }]],

        getEpisodeParams: _ember['default'].observer('coordsEnd', 'endDate', 'currentCompetition', 'coordsStart', 'ttaToEpisodes.[]', function () {
            this.send('getEpisodes');
        }),

        coordsEnd: null,
        coordsStart: null,

        ttaToEpisodes: _ember['default'].A(),

        didInsertElement: function didInsertElement() {
            var store = this.get('store');
            this.set('ttaToEpisodes', _ember['default'].A());
            this.set('coordsEnd', null);
            this.set('coordsStart', null);
            var that = this;
            store.findAll('playerTTA').then(function (playerTtaItems) {
                //ttas ttg
                store.findAll('playerTtaGroup').then(function (playerTtaGroupItems) {
                    var playerTtaPreparedItems = playerTtaGroupItems.map(function (playerTtaGroupItem) {
                        var filteredTtas = playerTtaItems.filterBy('playerTTAGroup', playerTtaGroupItem.get('id')).filterBy('episode', true);
                        playerTtaGroupItem.set('tta', filteredTtas);
                        return playerTtaGroupItem;
                    });
                    var disp = playerTtaPreparedItems.filterBy('displayable', true);
                    var notDisp = playerTtaPreparedItems.filterBy('displayable', false);
                    that.set('ttaGroups', disp);
                    that.set('ttaGroupsn', notDisp);
                });
            });
        },

        actions: {

            setTtaToEpisode: function setTtaToEpisode(t) {
                if (this.get('ttaToEpisodes').includes(t)) {
                    this.get('ttaToEpisodes').removeObject(t);
                } else {
                    this.get('ttaToEpisodes').addObject(t);
                }
            },

            getVideoPlayer: function getVideoPlayer() {
                if (this.get('episodes.ep') === 0 || this.get('episodes.ep') > this.get('episodesPlayer').maxEpisodesToPlay) return false;
                this.set('videoPlayer', true);
            },

            getEpisodes: function getEpisodes() {
                var that = this;
                $('.episodeLoading').removeClass('none');
                var coordsStart = that.get('coordsStart') || [];
                var coordsEnd = that.get('coordsEnd') || [];
                var playerTtas = that.get('ttaToEpisodes').mapBy('id');
                var players = !!that.get('players') ? that.get('players').mapBy('_id') : [];
                var games = !!that.get('games') ? that.get('games').mapBy('_id') : [];
                var teamIds = !!that.get('team.id') ? [].push(that.get('team.id')) : [];
                var modelId = !!that.get('model.id') ? that.get('model.id') : false;
                var req = { HSize: 6, VSize: 3 };
                if (coordsStart.length) req.cords = coordsStart;
                if (coordsEnd.length) req.target_cords = coordsEnd;
                if (playerTtas.length) req.playerTTA = playerTtas;
                if (players.length) req.players = players;
                if (that.get('startDate')) req.startDate = that.get('startDate');
                if (that.get('endDate')) req.endDate = that.get('endDate');
                if (games.length) req.games = games;
                if (teamIds.length) req.teams = teamIds;
                if (modelId.length) req[that.get('modelName')] = modelId;
                if (!!that.get("currentCompetition.name") && that.get("currentCompetition.name") !== 'OVERALL') req.competition = that.get('currentCompetition.id');
                if (!coordsStart.length && !playerTtas.length && !coordsEnd.length) {
                    that.set("episodes", { ep: 0, t: 0 });
                    $('.episodeLoading').addClass('none');
                    return false;
                };

                that.get('ajax').request('/' + that.get('query'), { method: 'POST', data: JSON.stringify(req), contentType: 'application/json', dataType: 'json'
                }).then(function (playerEpisodesCount) {
                    var time = moment.utc(playerEpisodesCount * 10000).format('HH:mm:ss'); //add checking playerEpisodesCountArray[0] later
                    if (time === '00:00:00') {
                        that.set("episodes", { ep: 0, t: 0 });
                        $('.episodeLoading').addClass('none');
                        return false;
                    };
                    that.set("episodes", { ep: playerEpisodesCount, t: time });
                    $('.episodeLoading').addClass('none');
                });
            }
        }

    });
});