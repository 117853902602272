define('msa/controllers/videolab', ['exports', 'ember', 'msa/config/environment', 'npm:lodash'], function (exports, _ember, _msaConfigEnvironment, _npmLodash) {
    exports['default'] = _ember['default'].Controller.extend({

        currentUser: _ember['default'].inject.service('current-user'), //авторизованный пользователь
        ajax: _ember['default'].inject.service(),
        jstreeActionReceiver: null,
        jstreeModalActionReceiver: null,
        newFolderName: "",
        listVisible: true,
        fileListVisible: true,
        currentNodeId: null,
        currentVideo: null,
        isMerging: false,
        showFolderInput: false,
        directoryModalStatus: false,
        videoContainer: _ember['default'].A(),
        editMode: false,
        selectedFilesContainer: _ember['default'].A(),
        videoModalStatus: false,
        emailModalStatus: false,
        total: null,
        skip: 0,
        limit: 10,
        emailAddress: '',
        dragulaconfig: {
            options: {
                copy: false,
                revertOnSpill: false,
                removeOnSpill: false
                // Other options from the dragula source page.
            },
            enabledEvents: ['drag', 'drop']
        },
        buildPages: _ember['default'].computed('total,skip', function () {
            var sk = this.get('skip');
            var pagesCount = Math.ceil(this.get('total') / 10);
            var skip = sk / 10 - 1 > 3 ? sk / 10 - 3 : 0;
            var arr = [];
            //строим массив со страницами
            for (var i = skip; i < pagesCount; i++) {
                var cl = sk / 10 === i ? 'page active' : 'page';
                arr.push({
                    page: i + 1,
                    cl: cl
                });
            }
            //добавляем первую, если улистали далеко
            if (skip) {
                arr.unshift({
                    page: 1,
                    cl: 'page'
                }, {
                    page: '...',
                    cl: 'dot'
                });
            }
            //добавляем последнюю, если листаем в начале
            if (pagesCount > 6) {
                if (sk / 10 < arr[arr.length - 3].page) arr.splice(7, arr.length - 8, {
                    page: '...',
                    cl: 'dot'
                });
            }
            return arr;
        }),
        init: function init() {
            this._super();
            var that = this;
            $(document).on('removeAddedSnippet', function (e, snippetHash) {
                console.log("this.get('currentFileList')", that.get('currentFileList'));
                $('#' + snippetHash).remove();
            });
        },

        actions: {
            handleJstreeEventDidMoveNode: function handleJstreeEventDidMoveNode(data) {
                var updatedNode = { parent: data.node.parent };
                var requestAddress = "/videofolder/" + data.node.id;
                this.get('ajax').request(requestAddress, {
                    method: 'PUT',
                    data: JSON.stringify(updatedNode),
                    contentType: 'application/json',
                    dataType: 'json'
                });
            },
            handleJstreeEventDidSelectNode: function handleJstreeEventDidSelectNode(data) {
                this.set('currentNodeId', data.id);
                var selectedFilesContainer = this.get('selectedFilesContainer');
                selectedFilesContainer.clear();
                this.set('editMode', false);
                this.set('selectedFilesContainer', selectedFilesContainer);
                this.send('getCurrentFileList');
            },
            showInputFolder: function showInputFolder() {
                this.set('showFolderInput', true);
                _ember['default'].run.later(function () {
                    $('#example').focus();
                }, 100);
            },
            addFolder: function addFolder() {
                var _this2 = this;

                if (event.keyCode === 13) {
                    (function () {
                        var that = _this2;
                        var newNode = { name: _this2.get('newFolderName') };
                        if (_this2.get('currentNodeId')) {
                            newNode.parent = _this2.get('currentNodeId');
                        }
                        _this2.get('ajax').request("/videofolder", {
                            method: 'POST',
                            data: JSON.stringify(newNode),
                            contentType: 'application/json',
                            dataType: 'json'
                        }).then(function (json) {
                            that.get('jstreeActionReceiver').send('redraw');
                            that.get('jstreeModalActionReceiver').send('redraw');
                            that.set('newFolderName', "");
                            that.set('showFolderInput', false);
                        });
                    })();
                }
            },
            inputFolderFocusOut: function inputFolderFocusOut() {
                this.set('newFolderName', "");
                this.set('showFolderInput', false);
            },
            removeFolder: function removeFolder() {
                var that = this;
                if (!this.get('currentNodeId')) {
                    return;
                }
                var requestAddress = "/videofolder/" + this.get('currentNodeId');
                this.get('ajax').request(requestAddress, {
                    method: 'DELETE',
                    data: JSON.stringify({}),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    that.get('jstreeActionReceiver').send('redraw');
                    that.get('jstreeModalActionReceiver').send('redraw');
                    that.set('currentNodeId', null);
                });
            },
            renewFileList: function renewFileList() {
                this.set('fileListVisible', false);
                $('.video_lab_table_line').remove();
                var _this = this;
                _ember['default'].run.next(function () {
                    _this.set('fileListVisible', true);
                });
            },
            nextPage: function nextPage(m) {
                this.set('skip', (m - 1) * 10);
                this.send('getCurrentFileList');
            },
            getCurrentFileList: function getCurrentFileList() {
                var that = this;
                var currentNodeId = this.get('currentNodeId');
                currentNodeId = currentNodeId === null ? 'null' : currentNodeId;
                var skip = this.get('skip');
                var limit = this.get('limit');
                var fileListQuery = '/videofolder/extended?parent=' + currentNodeId + '&startTime[$exists]=true&skip=' + skip + '&limit=' + limit;
                that.get('ajax').request(fileListQuery).then(function (json) {
                    var currentFileList = _ember['default'].A();
                    json.videofolder.forEach(function (item) {
                        var videoTime = item.endTime - item.startTime;
                        var videoTimeFormatted = new Date(videoTime * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
                        that.set('total', json.count);
                        var fileObj = _ember['default'].Object.create().setProperties({
                            _id: item._id,
                            active: false,
                            selected: false,
                            actionName: item.action && item.action.tta ? item.action.tta.name : item.name,
                            time: videoTimeFormatted,
                            away: item.action && item.action.game && item.action.game.away_team ? item.action.game.away_team.name : "",
                            home: item.action && item.action.game && item.action.game.home_team ? item.action.game.home_team.name : "",
                            date: item.action && item.action.game ? moment.utc(item.action.game.date).format('L') : "",
                            video: item.url + '?start=' + item.startTime + '&end=' + item.endTime
                        });
                        currentFileList.push(fileObj);
                    });
                    //console.log('currentFileList', currentFileList);
                    that.set('currentFileList', currentFileList);
                    that.send('renewFileList');
                });
            },
            onElementDrop: function onElementDrop(el, target, source, sibling, drake) {
                var currentFileList = this.get('currentFileList');
                var copiedFilelist = JSON.parse(JSON.stringify(currentFileList));
                var videoSnippet = _npmLodash['default'].find(copiedFilelist, { _id: el.id });
                //drake.cancel(true);

                if (el.className.includes('video_lab_table_line') && target.className.includes('video_mometns_line')) {
                    drake.cancel(true);
                    videoSnippet.actionName = videoSnippet.actionName.length < 5 ? videoSnippet.actionName : videoSnippet.actionName.substr(0, 4) + "...";
                    var elemHash = Math.random().toString(36).substring(7);
                    var onClickTemplate = 'onclick="$(document).trigger(`removeAddedSnippet`, `' + elemHash + '`);"';
                    var template = '<div class="vedio_moment" id="' + elemHash + '" data-id="' + videoSnippet._id + '" data-video="' + videoSnippet.video + '" > <img class="stub_img" src="/assets/img/video_lab/video-player.png" alt=""> <span class="video_stub_first_line">' + videoSnippet.actionName + '</span><span class="video_stub_second_line">' + videoSnippet.time + '</span><a ' + onClickTemplate + '  class="remove_stub"><svg fill="#9c361a" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg" class="remove_icon"> <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/> </svg> </a></div>';
                    if (sibling === null) {
                        $(".video_mometns_line").append(template);
                    } else if (sibling !== null) {
                        $(template).insertBefore("#" + sibling.id);
                    }
                } else if (el.className.includes('video_lab_table_line') && target.className.includes('video_lab_item_table')) {
                    drake.cancel(true);
                } else if (el.className.includes('vedio_moment') && target.className.includes('video_lab_item_table')) {
                    drake.cancel(true);
                }
                console.log($('.vedio_moment').toArray().length);
            },
            playClickedFile: function playClickedFile(currentFile) {
                var _this3 = this;

                if (!event.target.className.animVal) {
                    (function () {
                        var currentFileList = _this3.get('currentFileList');
                        currentFileList.forEach(function (fileItem, index) {
                            _ember['default'].set(currentFileList[index], 'active', false);
                        });
                        var elemIndex = currentFileList.indexOf(currentFile);
                        _ember['default'].set(currentFileList[elemIndex], 'active', true);
                        _this3.set('currentFileList', currentFileList);
                        var container = _this3.get('videoContainer');
                        container.clear();
                        var playerId = $('#player1').closest('.mejs__container').attr('id');
                        var player = mejs.players[playerId];
                        console.log('mejs.players', mejs.players);
                        player.setSrc(currentFile.video);
                        container.addObject(currentFile.video);
                        _this3.set('videoContainer', container);
                        _this3.set('currentVideo', currentFile.video);
                        player.media.play();
                    })();
                }
            },
            playCurrentSnippets: function playCurrentSnippets() {
                var container = this.get('videoContainer');
                var currentFileList = this.get('currentFileList');
                var videoElements = $('.vedio_moment').toArray();
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                container.clear();
                currentFileList.forEach(function (fileItem, index) {
                    _ember['default'].set(currentFileList[index], 'active', false);
                });
                this.set('currentFileList', currentFileList);
                videoElements.forEach(function (element) {
                    container.addObject(element.dataset.video);
                });
                this.set('videoContainer', container);
                this.set('currentVideo', container.objectAt(0));
                player.setSrc(container.objectAt(0));
                player.media.play();
            },
            treeContainerClick: function treeContainerClick() {
                var element = $(event.target);
                if (element.hasClass("tree_container") || element.hasClass("jstree-node")) {
                    this.set('currentNodeId', null);
                    var selectedFilesContainer = this.get('selectedFilesContainer');
                    selectedFilesContainer.clear();
                    this.set('selectedFilesContainer', selectedFilesContainer);
                    this.send('getCurrentFileList');
                    this.get('jstreeActionReceiver').send('deselectAll');
                }
            },
            mergeSnippets: function mergeSnippets() {
                var that = this;
                if (this.get('isMerging') === true) {
                    return;
                }
                var videoElements = $('.vedio_moment').toArray();
                if (!videoElements.length) {
                    this.set('noVideoModalStatus', true);
                    return;
                }

                this.set('videoModalStatus', true);

                var currentFileList = this.get('currentFileList');
                var linksArray = [];
                var query = "/getMergedVideo";
                this.set('isMerging', true);
                videoElements.forEach(function (element) {
                    linksArray.push({ link: element.dataset.video });
                });
                if (this.get('emailAddress').length > 0) {
                    query += '?email=' + this.get('emailAddress');
                }
                this.get('ajax').request(query, {
                    method: 'POST',
                    host: 'https://videolab.msa.football',
                    data: JSON.stringify({ data: linksArray }),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    that.set('isMerging', false);
                    that.set('currentNodeId', null);
                    that.set('emailAddress', '');
                    that.send('getCurrentFileList');
                    that.get('jstreeActionReceiver').send('deselectAll');
                    console.log('req ended');
                    console.log('LINK TO VIDEO', json);
                });
            },
            openDirectoryModal: function openDirectoryModal() {
                this.set('directoryModalStatus', true);
            },
            closeDirectoryModal: function closeDirectoryModal() {
                this.get('jstreeModalActionReceiver').send('deselectAll');
                this.set('directoryModalStatus', false);
            },
            moveSelectedFilesIntoFolder: function moveSelectedFilesIntoFolder(data) {
                var that = this;
                var newParent = data.id;
                var preparedItems = [];
                var currentFileList = this.get('currentFileList');
                var filteredList = currentFileList.filter(function (file) {
                    return file.selected === true;
                });
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                this.set('directoryModalStatus', false);
                if (filteredList.length == 0) {
                    flashMessages.warning("You must select at least one file");
                    return;
                }
                filteredList.forEach(function (fileItem) {
                    preparedItems.push({ _id: fileItem._id, parent: newParent });
                });
                var requestAddress = "/videofolder/updateArr";
                this.get('ajax').request(requestAddress, {
                    method: 'POST',
                    data: JSON.stringify({ array: preparedItems }),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    that.send('getCurrentFileList');
                    that.set('editMode', false);
                    var selectedFilesContainer = that.get('selectedFilesContainer');
                    selectedFilesContainer.clear();
                    that.set('selectedFilesContainer', selectedFilesContainer);
                    flashMessages.success("Items was successfully moved");
                });
            },
            removeSelectedFiles: function removeSelectedFiles() {
                var that = this;
                var preparedItems = [];
                var currentFileList = this.get('currentFileList');
                var filteredList = currentFileList.filter(function (file) {
                    return file.selected === true;
                });
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                if (filteredList.length == 0) {
                    flashMessages.warning("You must select at least one file");
                    return;
                }
                filteredList.forEach(function (fileItem) {
                    preparedItems.push(fileItem._id);
                });
                var requestAddress = "/videofolder/removeArr";
                this.get('ajax').request(requestAddress, {
                    method: 'POST',
                    data: JSON.stringify({ array: preparedItems }),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (json) {
                    that.send('getCurrentFileList');
                    that.set('editMode', false);
                    var selectedFilesContainer = that.get('selectedFilesContainer');
                    selectedFilesContainer.clear();
                    that.set('selectedFilesContainer', selectedFilesContainer);
                    flashMessages.success("Items was successfully removed");
                });
            },
            addToSelected: function addToSelected(currentFile) {
                var currentFileList = this.get('currentFileList');
                var selectedFilesContainer = this.get('selectedFilesContainer');
                var elemIndex = currentFileList.indexOf(currentFile);
                if (currentFile.selected === true) {
                    _ember['default'].set(currentFileList[elemIndex], 'selected', false);
                    selectedFilesContainer.removeObject(currentFile._id);
                } else {
                    _ember['default'].set(currentFileList[elemIndex], 'selected', true);
                    selectedFilesContainer.pushObject(currentFile._id);
                }
                this.set('selectedFilesContainer', selectedFilesContainer);
                this.set('currentFileList', currentFileList);
            },
            enableEditMode: function enableEditMode() {
                this.set('editMode', true);
            },
            disableEditMode: function disableEditMode() {
                this.set('editMode', false);
                var selectedFilesContainer = this.get('selectedFilesContainer');
                var currentFileList = this.get('currentFileList');
                currentFileList.forEach(function (fileItem, index) {
                    _ember['default'].set(currentFileList[index], 'selected', false);
                });
                selectedFilesContainer.clear();
                this.set('selectedFilesContainer', selectedFilesContainer);
                this.set('currentFileList', currentFileList);
            },
            closeVideoStatusModal: function closeVideoStatusModal() {
                this.set('videoModalStatus', false);
                //window.location.reload(true);
            },
            closeNoVideoStatusModal: function closeNoVideoStatusModal() {
                this.set('noVideoModalStatus', false);
            },
            reloadPage: function reloadPage() {
                window.location.reload(true);
            },
            openMailModal: function openMailModal() {
                this.set('emailModalStatus', true);
            },
            closeEmailStatusModal: function closeEmailStatusModal() {
                var that = this;
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                if (this.get('emailAddress').length == 0) {
                    flashMessages.warning("Please enter email");
                    return;
                } else {
                    this.set('emailModalStatus', false);
                    _ember['default'].run.later(function () {
                        that.send('mergeSnippets');
                    }, 500);
                }
            }
        }
    });
});