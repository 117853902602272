define('msa/components/set-piece-players-table', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didRender: function didRender() {
            console.log('set-piece-players-table');
        },
        actions: {
            openModal: function openModal(acts) {
                if (!$(event.target).hasClass('table_box')) {
                    return;
                }
                if (acts.length > 0) {
                    this.set('actionsArray', acts);
                    this.set('showVideoPlayer', true);
                }
            },
            closePlayer: function closePlayer() {
                this.set('showVideoPlayer', false);
            },

            goToPlayerPage: function goToPlayerPage(player) {
                console.log(player._id, this.get('matchId'));
                this.get('router').transitionTo('post-match-report.player', player._id, this.get('matchId'));
            }
        }
    });
});