define('msa/components/login-header', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({
        session: _ember['default'].inject.service(),
        currentUser: _ember['default'].inject.service(),
        didInsertElement: function didInsertElement() {
            var that = this;
            $('header').removeClass('fixed');

            that.set('header_state', '');
            $(window).scroll(function () {
                if ($(window).scrollTop() > 0) {
                    if ($('header')) {
                        $('header').addClass('fixed');
                        that.set('header_state', 'fixed');
                        console.log('some text is here');
                    }
                } else {
                    if ($('header')) {
                        that.set('header_state', '');
                        $('header').removeClass('fixed');
                        console.log('some text not here');
                    }
                }
            });
        },
        beforeModel: function beforeModel() {
            if (this.get("session.data.authenticated.access_token")) this.transitionTo('index');
        },

        actions: {
            showForm: function showForm() {

                if (!this.get('showLogin') || this.get('showLogin').length === 0) this.set('showLogin', 'display: grid;');else this.set('showLogin', '');
                console.log('clicked');
            },
            authenticate: function authenticate() {
                var that = this;
                console.log('login-hearer');

                var _getProperties = this.getProperties('email', 'password');

                var email = _getProperties.email;
                var password = _getProperties.password;

                this.sendAction('authenticate', email, password, function (err) {
                    that.set('errmessage', err);
                });
            },
            updateMessage: function updateMessage() {
                console.log('updddddd');
            }
        }
    });
});