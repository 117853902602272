define('msa/routes/video-player', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    var service = _ember['default'].inject.service;
    var isEmpty = _ember['default'].isEmpty;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        currentUser: service(),
        ajax: service(),
        episodesPlayer: service(),
        /*    queryParams: ['query', 'type'],
            category: null,
        
            beforeModel: function(transition) {
                const that = this;
                that.controllerFor('video-player').set('q', transition.query);
                that.controllerFor('video-player').set('t', transition.type);
            },*/

        model: function model() {},

        setupController: function setupController(controller, model) {
            controller.set('columns', [{
                "propertyName": "action",
                "title": "Action"
            }, {
                "propertyName": "time",
                "title": "Time"
            }, {
                "propertyName": "home",
                "title": "Home Team",
                "className": "team"
            }, {
                "title": "vs"
            }, {
                "propertyName": "away",
                "title": "Away Team",
                "className": "team"
            }, {
                "propertyName": "date",
                "title": "Date",
                "className": "text-right"
            }, {
                "propertyName": "half",
                "title": "Half",
                "className": "text-right"
            }]);

            var that = this;
            var q = {
                "player": "5831b42b6e1985fc16002b5f",
                "HSize": 6,
                "VSize": 3,
                "coords": [{
                    "x": 1,
                    "y": 1
                }, {
                    "x": 2,
                    "y": 2
                }],
                "tta": ["5831b4c3b1843ffcd3fbf6b1", "5831b4c3b1843ffcd3fbf6b2", "5831b4c3b1843ffcd3fbf6b3", "5831b4c3b1843ffcd3fbf6b4"]
            };

            that.get('ajax').request('/playerGetVideos', {
                method: 'POST',
                data: JSON.stringify(q),
                contentType: 'application/json',
                dataType: 'json'
            }).then(function (json) {
                var ar = json.playerEpisodeSearch.array;
                var newArr = [];
                for (var i = 0; i < ar.length; i++) {
                    for (var b = 0; b < ar[i].actions.length; b++) {
                        var gameTime = ar[i].actions[b].game_time;
                        newArr.push({
                            action: ar[i].actions[b].tta.name,
                            time: moment.utc(gameTime).format('HH:mm:ss'),
                            timeTech: gameTime,
                            gameId: ar[i].game._id,
                            half: ar[i].half,
                            video: ar[i].server.url + ar[i].video,
                            away: ar[i].game.away_team.name,
                            awayId: ar[i].game.away_team._id,
                            home: ar[i].game.home_team.name,
                            homeId: ar[i].game.home_team._id,
                            date: moment.utc(ar[i].game.date).format('L'),
                            videoURL: that.get('episodesPlayer').getVideoURL(ar[i].server.url, ar[i].video, gameTime)
                        });
                    }
                }
                controller.set('list', newArr);
            });

            /*const that = this;
            var q = controller.get('q');
            var t = '/' + controller.get('t');*/

            /*that.get('ajax').request(t, {
                method: 'POST',
                data: q,
                contentType: 'application/json',
                dataType: 'json'
            }).then(function(json) {
                var ar = json[t];
                var newArr = [];
                for (var i = 0; i < ar.length; i++) {
                    for (var b = 0; b < ar[i].actions.length; b++) {
                        newArr.push({
                            action: ar[i].actions[b].tta.name,
                            time: moment.utc(ar[i].actions[b].game_time).format('HH:mm:ss'),
                            timeTech: ar[i].actions[b].game_time,
                            gameId: ar[i].game._id,
                            half: ar[i].half,
                            video: ar[i].video,
                            away: ar[i].game.away_team.name,
                            awayId: ar[i].game.away_team._id,
                            home: ar[i].game.home_team.name,
                            homeId: ar[i].game.home_team._id,
                            date: moment.utc(ar[i].game.date).format('L')
                        });
                    }
                }
                controller.set('list', newArr);});*/
        },

        actions: {
            downloadVideo: function downloadVideo(m) {
                var link = document.createElement("a");
                // Set file name and extension
                link.download = m.home + '-' + m.away + ' ' + m.action + ' at ' + m.time;
                link.download.replace(':', '-');
                link.download += m.video.substring(m.video.lastIndexOf('.'));
                link.filename = link.download;
                link.href = m.videoURL;
                link.click();
            },
            getVideo: function getVideo(m) {
                var model = m.get('selectedItems')[0];
                if (!model.video) return;
                $('.episodeLoading').removeClass('none');
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                var src = model.videoURL;
                player.setSrc(src);
                player.media.load();
                $('.episodeLoading').addClass('none');
            },

            getFullVideo: function getFullVideo(model) {
                var playerId = $('#player1').closest('.mejs__container').attr('id');
                var player = mejs.players[playerId];
                player.setSrc(this.get(model.video));
                player.media.load();
            }
        }

    });
});

//import LoadingIndicator       from 'uti-ui/mixins/loading-indicator';