define('msa/components/single-attack-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            var elem = this.$('canvas.my_new_line')[0];
            var table = this.$('.margin__table')[0];
            var ctx = elem.getContext('2d');
            var combinations = this.get('combinations');
            elem.width = this.$().width();
            elem.height = this.$().height();
            ctx.beginPath();
            ctx.lineWidth = 3;
            for (var i = 0; i < combinations.length; i++) {
                var comb = combinations[i];
                var fromx = comb.loc_x * elem.width * 0.01,
                    fromy = comb.loc_y * elem.height * 0.01,
                    tox = comb.target_x * elem.width * 0.01,
                    toy = comb.target_y * elem.height * 0.01;
                var angle = Math.atan2(toy - fromy, tox - fromx);
                var headlen = 4;
                ctx.moveTo(fromx, fromy);
                ctx.lineTo(tox, toy);
                ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
                ctx.moveTo(tox, toy);
                ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
            }

            ctx.strokeStyle = '#bace4d';
            ctx.stroke();
        },

        actions: {
            foo: function foo() {
                var route = this;
                // Element does not exist.
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    // If the models are already cached, the element exists.
                    var elem = this.$('canvas.my_new_line')[0];
                    var elem2 = this.get('canvas.my_new_line');
                    var ctx = elem.getContext('2d');
                    ctx.beginPath();
                    ctx.moveTo(0, 0);
                });
            },
            openPlayer: function openPlayer(element) {
                this.sendAction('openActionPlayer', element.actionId);
            }
        }
    });
});