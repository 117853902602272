define('msa/routes/post-match-report/summary/attacks', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            var gameModel = that.controllerFor('post-match-report.summary').get("model");
            controller.set('model', gameModel);
            var requiredTttas = ["Attacks", "Counter attacks", "Positional attacks", "Set piece attacks", "Attacks with shot", "Center attacks", "Left flank attacks", "Right flank attacks"];
            var ttasWithPercents = [];
            var playerTtas = ["Participation in attacks", "Participation in counter attacks", "Participation in position attacks", "Participation in set piece attacks"];
            that.store.findAll('teamTTA').then(function (ttas) {
                var filteredTtas = ttas.filter(function (item) {
                    return requiredTttas.indexOf(item.get('plural')) > -1;
                });
                var query = '';
                filteredTtas.forEach(function (tta, index) {
                    query += '&teamTTA=' + tta.get('id');
                });
                var homeTeamId = gameModel.get('home_team.id');
                var awayTeamId = gameModel.get('away_team.id');
                that.get('ajax').request('/gameReportCountBars?game=' + gameModel.get('id') + query).then(function (json) {
                    var challengesActions = that.get('postMatchService').prepareCountBarsData(json, homeTeamId, awayTeamId);
                    controller.set('challengesActions', challengesActions);
                });
                var totalTtaQuery = '';
                filteredTtas.forEach(function (tta, index) {
                    if (tta.get('plural') === 'Attacks') {
                        totalTtaQuery += '&teamTTA=' + tta.get('id');
                    }
                });

                that.get('ajax').request('/gameReportTimeBars?game=' + gameModel.get('id') + totalTtaQuery).then(function (json) {
                    var homeTeamItem = null;
                    var awayTeamItem = null;
                    var mergedValues = json[0].row;
                    if (json.length > 1) mergedValues = json[0].row.concat(json[1].row);
                    var maxNumber = _npmLodash['default'].max(mergedValues);
                    maxNumber = maxNumber + Math.round(maxNumber * 0.1);
                    maxNumber = Math.ceil(maxNumber / 10) * 10;
                    var singleScaleValue = maxNumber / 4;
                    var scaleValues = [];
                    for (var i = 0; i < 5; i++) {
                        var scaleValue = null;
                        if (i == 0) {
                            scaleValue = 0;
                        } else {
                            scaleValue = scaleValues[i - 1] + singleScaleValue;
                        }
                        scaleValues.push(scaleValue);
                    }
                    controller.set('summaryScaleValues', scaleValues.reverse());
                    json.forEach(function (item, index) {
                        if (item.team._id === homeTeamId) {
                            homeTeamItem = item;
                        }
                        if (item.team._id === awayTeamId) {
                            awayTeamItem = item;
                        }
                    });
                    var timesArray = ['0-15', '15-30', '30-45', '45-60', '60-75', '75-90'];
                    var preparedData = [];
                    timesArray.forEach(function (item, index) {
                        var homeHeight = homeTeamItem.row[index] / maxNumber * 80;
                        var awayHeight = awayTeamItem.row[index] / maxNumber * 80;
                        preparedData.push({ period: item, homeTeamHeight: homeHeight, awayTeamHeight: awayHeight });
                    });
                    controller.set('summaryHistogramData', preparedData);
                });

                that.store.findAll('playerTTA').then(function (pTtas) {
                    var filteredPlayersTtas = pTtas.filter(function (item) {
                        return playerTtas.indexOf(item.get('plural')) > -1;
                    });
                    var ttaPlayersListQuery = '';
                    filteredPlayersTtas.forEach(function (tta, index) {
                        ttaPlayersListQuery += '&playerTTA=' + tta.get('id');
                    });
                    var homePlayersListQuery = ttaPlayersListQuery + '&team=' + homeTeamId;
                    that.get('ajax').request('/gameReportActionsByPlayers?game=' + gameModel.get('id') + homePlayersListQuery).then(function (json) {
                        json.header = that.get('postMatchService').changeHeaderAttacks(json.header);
                        var homePlayersList = that.get('postMatchService').prepareReportActionsByPlayers(json);
                        controller.set('homePlayersList', homePlayersList);
                    });
                    var awayPlayersListQuery = ttaPlayersListQuery + '&team=' + awayTeamId;
                    that.get('ajax').request('/gameReportActionsByPlayers?game=' + gameModel.get('id') + awayPlayersListQuery).then(function (json) {
                        json.header = that.get('postMatchService').changeHeaderAttacks(json.header);
                        var awayPlayersList = that.get('postMatchService').prepareReportActionsByPlayers(json);
                        controller.set('awayPlayersList', awayPlayersList);
                    });
                });
            });
        }
    });
});