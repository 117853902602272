define('msa/routes/teams/show/overview', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        setupController: function setupController(controller, model) {
            var that = this;
            controller.set('model', that.controllerFor('teams.show').get("model"));
            // We should update goals scored and half selected due to our tabs are routes
            controller.send('setHalf', 1);
        }
    });
});