define('msa/helpers/expm1', ['exports', 'ember-math-helpers/helpers/expm1'], function (exports, _emberMathHelpersHelpersExpm1) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersExpm1['default'];
    }
  });
  Object.defineProperty(exports, 'expm1', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersExpm1.expm1;
    }
  });
});