define('msa/services/referee-data-transform', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        refereeDataEnrich: function refereeDataEnrich(data) {
            if (data.actions.FoulCalled && data.actions.Challenge && data.actions.Challenge) {
                data.actions.FoulsToChallenge = Math.round(data.actions.FoulCalled * 100 / data.actions.Challenge) / 100;
            }
            if (data.actions.TimeOnField) {
                data.actions.MinutesOnField = Math.round(data.actions.TimeOnField / 6) / 10;
                data.actions.AverageSpeed = Math.round(data.actions.Distance * 100 / data.actions.TimeOnField) / 100;
            }

            if (data.actions.WalkDistance || data.actions.JogDistance) {
                data.actions.WalkJogDistance = 0;
                if (data.actions.WalkDistance) data.actions.WalkJogDistance += data.actions.WalkDistance;
                if (data.actions.JogDistance) data.actions.WalkJogDistance += data.actions.JogDistance;
            } else {
                data.actions.WalkJogDistance = '-';
            }
            if (data.actions.Distance) {
                if (data.actions.WalkDistance || data.actions.JogDistance) {
                    data.actions.WalkJogDistancePrc = Math.round(data.actions.WalkJogDistance * 100 / data.actions.Distance) + '%';
                }
                if (data.actions.RunDistance) {
                    data.actions.RunDistancePrc = Math.round(data.actions.RunDistance * 100 / data.actions.Distance) + '%';
                }
                if (data.actions.AccelerationDistance) {
                    data.actions.AccelerationDistancePrc = Math.round(data.actions.AccelerationDistance * 100 / data.actions.Distance) + '%';
                }
                if (data.actions.SpeedOfTheBall) {
                    data.actions.GSC = Math.round(data.actions.AverageSpeed * 100 / data.actions.SpeedOfTheBall) / 100;
                }
                if (data.actions.SprintDistance) {
                    data.actions.SprintDistancePrc = Math.round(data.actions.SprintDistance * 100 / data.actions.Distance) + '%';
                }
                if (data.actions.YellowCard || data.actions.RedCard) {
                    data.actions.Cards = 0;
                    if (data.actions.YellowCard) data.actions.Cards += data.actions.YellowCard;
                    if (data.actions.RedCard) data.actions.Cards += data.actions.RedCard;
                }
                if (data.actions.FoulCalled && data.actions.Cards) {
                    data.actions.CardsToFouls = Math.round(data.actions.Cards * 10 / data.actions.FoulCalled) / 10;
                }
            }

            return data;
        }

    });
});