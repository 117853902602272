define('msa/components/cards-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            console.log('didInsertElement1111');
            var fieldData = this.get('fieldData');
            var currentCards = _ember['default'].A();
            this.set('activeHalf', 1);
            var filteredData = _npmLodash['default'].filter(fieldData, { 'half': 1 });
            currentCards.addObjects(filteredData);
            this.set('currentCards', currentCards);
            console.log('fieldData1', fieldData);
            /*const breakdownActions = this.get('breakdownActions');
            this.set('firstRow', breakdownActions[0]);
            this.set('secondRow', breakdownActions[1]);
            this.set('thirdRow', breakdownActions[2]); */
        },
        actions: {
            changeHalf: function changeHalf(half) {
                console.log('half', half);
                this.set('activeHalf', half);
                var fieldData = this.get('fieldData');
                var filteredData = null;
                if (half === 0) {
                    filteredData = fieldData;
                } else {
                    filteredData = _npmLodash['default'].filter(fieldData, { 'half': half });
                }
                var currentCards = _ember['default'].A();
                currentCards.addObjects(filteredData);
                this.set('currentCards', currentCards);
            },
            openPlayer: function openPlayer(element) {
                console.log('openPlayer');
                this.sendAction('openActionPlayer', element.actionId);
            }
        }
    });
});