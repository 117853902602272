define('msa/helpers/sort-icon', ['exports', 'ember-data-table/helpers/sort-icon'], function (exports, _emberDataTableHelpersSortIcon) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDataTableHelpersSortIcon['default'];
    }
  });
  Object.defineProperty(exports, 'sortIcon', {
    enumerable: true,
    get: function get() {
      return _emberDataTableHelpersSortIcon.sortIcon;
    }
  });
});