define('msa/components/small-players-table', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        breakdownActions: null,
        didInsertElement: function didInsertElement() {
            /*const breakdownActions = this.get('breakdownActions');
            this.set('firstRow', breakdownActions[0]);
            this.set('secondRow', breakdownActions[1]);
            this.set('thirdRow', breakdownActions[2]); */
        },
        actions: {
            openModal: function openModal(acts, e) {
                if (!$(event.target).hasClass('table_box')) {
                    return;
                }

                if (acts.length > 0) {
                    this.set('actionsArray', acts);
                    this.set('showVideoPlayer', true);
                }
            },
            closePlayer: function closePlayer() {
                this.set('showVideoPlayer', false);
            },

            goToPlayerPage: function goToPlayerPage(player) {
                console.log(player._id, this.get('matchId'));
                this.get('router').transitionTo('post-match-report.player', player._id, this.get('matchId'));
            }
        }
    });
});