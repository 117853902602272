define('msa/services/team-service', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Service.extend({
        prepareSquadData: function prepareSquadData(json) {
            var lastGameArr = [];
            var bestPositionsArr = [];
            var worthPositionsArr = [];
            var lastFormation = json.formation.lastGame && json.formation.lastGame.formation ? json.formation.lastGame.formation.name : '';
            var bestFormation = json.formation.best && json.formation.best.formation ? json.formation.best.formation.name : '';
            var worthFormation = json.formation.worth && json.formation.worth.formation ? json.formation.worth.formation.name : '';
            console.log('names', lastFormation, bestFormation, worthFormation);
            if (json.formation && json.formation.lastGame) {
                var ob = json.formation.lastGame;
                for (var ps = 0; ps < ob.players.length; ps++) {
                    var _name = ob.players[ps].amplua.name === 'Goalkeeper' ? ob.players[ps].amplua.name : ob.players[ps].position.name + ' ' + ob.players[ps].amplua.name;
                    lastGameArr.push({
                        name: _name,
                        pName: ob.players[ps].player.first_name + ' ' + ob.players[ps].player.last_name,
                        pNumber: ob.players[ps].player.number,
                        pId: ob.players[ps].player._id,
                        hash: Math.random().toString(36).substring(7),
                        pPhoto: ob.players[ps].player.photo,
                        shName: ob.players[ps].amplua.short_name,
                        x: ob.players[ps].amp * 100 / (ob.fieldMatrix.ampluas - 1),
                        y: ob.players[ps].pos * 100 / (ob.fieldMatrix.positions - 1)
                    });
                }
            }
            if (json.formation && json.formation.best) {
                var bestPositions = json.formation.best;
                for (var ps = 0; ps < bestPositions.positions.length; ps++) {
                    var _name2 = bestPositions.positions[ps].amplua.name === 'Goalkeeper' ? bestPositions.positions[ps].amplua.name : bestPositions.positions[ps].position.name + ' ' + bestPositions.positions[ps].amplua.name;
                    bestPositionsArr.push({
                        name: _name2,
                        pName: bestPositions.positions[ps].player.first_name + ' ' + bestPositions.positions[ps].player.last_name,
                        pNumber: bestPositions.positions[ps].player.number,
                        pId: bestPositions.positions[ps].player._id,
                        pPhoto: bestPositions.positions[ps].player.photo,
                        hash: Math.random().toString(36).substring(7),
                        shName: bestPositions.positions[ps].amplua.short_name,
                        x: bestPositions.positions[ps].amp * 100 / (bestPositions.fieldMatrix.ampluas - 1),
                        y: bestPositions.positions[ps].pos * 100 / (bestPositions.fieldMatrix.positions - 1)
                    });
                }
            }
            if (json.formation && json.formation.worth) {
                var worthPositions = json.formation.worth;
                for (var ps = 0; ps < worthPositions.positions.length; ps++) {
                    var _name3 = worthPositions.positions[ps].amplua.name === 'Goalkeeper' ? worthPositions.positions[ps].amplua.name : worthPositions.positions[ps].position.name + ' ' + worthPositions.positions[ps].amplua.name;
                    worthPositionsArr.push({
                        name: _name3,
                        pName: worthPositions.positions[ps].player.first_name + ' ' + worthPositions.positions[ps].player.last_name,
                        pNumber: worthPositions.positions[ps].player.number,
                        pId: worthPositions.positions[ps].player._id,
                        pPhoto: worthPositions.positions[ps].player.photo,
                        hash: Math.random().toString(36).substring(7),
                        shName: worthPositions.positions[ps].amplua.short_name,
                        x: worthPositions.positions[ps].amp * 100 / (worthPositions.fieldMatrix.ampluas - 1),
                        y: worthPositions.positions[ps].pos * 100 / (worthPositions.fieldMatrix.positions - 1)
                    });
                }
            }
            var rez = { lastGameArr: lastGameArr, bestPositionsArr: bestPositionsArr, worthPositionsArr: worthPositionsArr, lastFormation: lastFormation, bestFormation: bestFormation, worthFormation: worthFormation };
            return rez;
        }
    });
});