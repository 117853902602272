define('msa/models/user', ['exports', 'ember-data', 'msa/libs/type-shims'], function (exports, _emberData, _msaLibsTypeShims) {
    exports['default'] = _emberData['default'].Model.extend({
        email: _msaLibsTypeShims.string,
        first_name: _msaLibsTypeShims.string,
        last_name: _msaLibsTypeShims.string,
        date_birth: _msaLibsTypeShims.string,
        job: _msaLibsTypeShims.string,
        company: _msaLibsTypeShims.string,
        avatar: _msaLibsTypeShims.string,
        home_competition_page: _msaLibsTypeShims.string,
        isAdmin: _emberData['default'].attr('boolean', { readOnly: true }),
        raw_data_access: _emberData['default'].attr('boolean', { readOnly: true }),

        country: _emberData['default'].belongsTo('country', { async: true }),
        team: _emberData['default'].belongsTo('team', { async: true }),

        fav_players: _emberData['default'].hasMany('player', { async: true }),
        fav_teams: _emberData['default'].hasMany('team', { async: true }),
        fav_competitions: _emberData['default'].hasMany('competition', { async: true })
    });
});