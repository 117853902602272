define('msa/controllers/outlets/select/team', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		text: '',
		init: function init() {
			this.set('text', '');
		},
		sortedModel: _ember['default'].computed('model.[]', 'text', function () {
			var text = this.get('text');

			if (!this.get('model.length')) {
				$('.loading').addClass('none');
				$('.noData').removeClass('none');
				return _ember['default'].A();
			}
			if (!text) {
				$('.loading').addClass('none');
				$('.noData').removeClass('none');
				return this.get('model').sortBy('name');
			}

			var m = this.get('model').map(function (i) {
				if (i.get('name').toLowerCase().indexOf(text.toLowerCase()) !== -1) return i;
			});

			$('.loading').addClass('none');
			return m.sortBy('name');;
		})
	});
});