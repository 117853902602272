define('msa/components/dynamics-chart', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        store: _ember['default'].inject.service(),

        didInsertElement: function didInsertElement() {
            var index = this.get('dynamicsParams').graphicIndex;
            var params = this.get('dynamicsParams');
            var has_won = this.get('dynamicsParams').has_won;
            var has_success = this.get('dynamicsParams').has_success;
            var tta = this.get('dynamicsParams').tta;

            var ctx = document.getElementById('dynamicsChart' + index);
            var chartData = {};

            if (has_success && has_won) {
                chartData = {
                    labels: params.labels,

                    datasets: [{
                        label: params.team + ' won',
                        backgroundColor: 'green',
                        borderColor: 'green',
                        //stack: "Stack 0",
                        data: params.teamSuccessValues,
                        fill: false
                    }, {
                        label: 'Opponent team won',
                        backgroundColor: '#b2c93a',
                        borderColor: '#b2c93a',
                        //stack: "Stack 0",
                        data: params.enemySuccessValues,
                        fill: false

                    }, {
                        label: 'total',
                        backgroundColor: '#CCCCCC',
                        borderColor: '#CCCCCC',
                        //stack: "Stack 0",
                        data: params.enemyValues,
                        fill: false

                    }]
                };
            } else if (has_success && !has_won) {
                chartData = {
                    labels: params.labels,

                    datasets: [{
                        label: params.team + ' total',
                        backgroundColor: '#AAAAAA',
                        borderColor: '#AAAAAA',
                        //stack: "Stack 0",
                        data: params.teamValues,
                        fill: false
                    }, {
                        label: 'Opponent team total',
                        backgroundColor: '#DDDDDD',
                        borderColor: '#DDDDDD',
                        //stack: "Stack 0",
                        data: params.enemyValues,
                        fill: false
                    }, {

                        label: params.team + ' success',
                        backgroundColor: 'green',
                        borderColor: 'green',
                        //stack: "Stack 0",
                        data: params.teamSuccessValues,
                        fill: false
                    }, {
                        label: 'Opponent team success',
                        backgroundColor: '#b2c93a',
                        borderColor: '#b2c93a',
                        //stack: "Stack 0",
                        data: params.enemySuccessValues,
                        fill: false
                    }]
                };
            } else {
                chartData = {
                    labels: params.labels,

                    datasets: [{
                        label: params.team,
                        backgroundColor: 'green',
                        borderColor: 'green',
                        //stack: "Stack 0",
                        data: has_won ? params.teamSuccessValues : params.teamValues,
                        fill: false
                    }, {
                        label: 'Opponent team',
                        backgroundColor: '#b2c93a',
                        borderColor: '#b2c93a',
                        //stack: "Stack 0",
                        data: has_won ? params.enemySuccessValues : params.enemyValues,
                        fill: false

                    }]
                };
            }
            var chartParams = {
                type: "line",
                data: chartData,
                barThickness: 5,
                options: {
                    responsive: true,
                    scales: {
                        xAxes: [{
                            display: true,
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    title: {
                        display: true,
                        text: tta.toUpperCase(),
                        fontSize: 16,
                        fontFamily: "ProximaNovaSemiBold",
                        fontColor: '#003f30'
                    }
                }
            };

            var myChart = new Chart(ctx, chartParams);
            this.set('dynamicsChart' + index, myChart);
        },

        didUpdate: function didUpdate() {
            var index = this.get('dynamicsParams').graphicIndex;

            console.log('dynamicsParams', this.get('dynamicsParams'));
            console.log(this.get('dynamicsChart'));
            var has_won = this.get('dynamicsParams').has_won;
            if (!this.get('dynamicsChart' + index) || !this.get('dynamicsParams')) return;
            this.get('dynamicsChart' + index).config.data.labels = this.get('dynamicsParams').labels;
            if (!has_won) {
                this.get('dynamicsChart' + index).config.data.datasets.data[0] = this.get('dynamicsParams').teamValues;
                this.get('dynamicsChart' + index).config.data.datasets.data[1] = this.get('dynamicsParams').enemyValues;
            } else {
                this.get('dynamicsChart' + index).config.data.datasets.data[0] = this.get('dynamicsParams').teamSuccessValues;
                this.get('dynamicsChart' + index).config.data.datasets.data[1] = this.get('dynamicsParams').enemySuccessValues;
            }
            //this.get('barChart').config.type = this.get('dynamicsParams').type;
            this.get('dynamicsChart' + index).update();
        }
    });
});