define('msa/routes/post-match-report/summary/passes', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'msa/mixins/is-avatar-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _msaMixinsIsAvatarMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_msaMixinsIsAvatarMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service(),

        setupController: function setupController(controller, model) {
            var that = this;
            var gameModel = that.controllerFor('post-match-report.summary').get("model");
            controller.set('model', gameModel);
            var requiredTttas = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
            that.store.findAll('teamTTA').then(function (ttas) {
                var filteredTtas = ttas.filter(function (item) {
                    return requiredTttas.indexOf(item.get('orderIndex')) > -1;
                });
                var query = '';
                filteredTtas.forEach(function (tta, index) {
                    if (tta.get('orderIndex') !== 5) {
                        query += '&teamTTA=' + tta.get('id');
                    }
                });
                var homeTeamId = gameModel.get('home_team.id');
                var awayTeamId = gameModel.get('away_team.id');
                var totalTtaQuery = '';
                filteredTtas.forEach(function (tta, index) {
                    if (tta.get('orderIndex') === 5) {
                        totalTtaQuery = '&teamTTA=' + tta.get('id');
                    }
                });
                that.get('ajax').request('/gameReportCountBars?game=' + gameModel.get('id') + totalTtaQuery).then(function (json) {
                    var totalPassActions = that.get('postMatchService').prepareCountBarsData(json, homeTeamId, awayTeamId);
                    console.log('totalPassActions', totalPassActions);
                    controller.set('passesTotalActions', totalPassActions);
                });
                that.get('ajax').request('/gameReportCountBars?game=' + gameModel.get('id') + query).then(function (json) {
                    var setPieceActions = that.get('postMatchService').prepareCountBarsData(json, homeTeamId, awayTeamId);
                    controller.set('passesActions', setPieceActions);
                });
            });
        }
    });
});