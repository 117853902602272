define('msa/routes/post-match-report/defensive', ['exports', 'ember', 'msa/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:lodash'], function (exports, _ember, _msaMixinsLoadingIndicator, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmLodash) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _msaMixinsLoadingIndicator['default'], {
        model: function model(params) {
            return this.store.findRecord('game', params.m_id);
        },

        ajax: _ember['default'].inject.service(),
        postMatchService: _ember['default'].inject.service()

    });
});
/*setupController: function(controller, model) {
    const that = this;
    controller.set('model', model);
    const teamTtas = ["Yellow cards", "Red cards"];
    const homeTeamId = model.get('home_team.id');
    const awayTeamId = model.get('away_team.id');
    that.get('ajax').request(`/stadium/${model.get("stadium.id")}`).then(function(stadium) {
        that.store.findAll('teamTTA').then((pTtas) => {
            const filteredPlayersTtas = pTtas.filter(function(item) {
                return teamTtas.indexOf(item.get('plural')) > -1;
            });
            let ttaPlayersListQuery = '';
            filteredPlayersTtas.forEach((tta, index) => {
                ttaPlayersListQuery += '&teamTTA=' + tta.get('id');
            });
            const homePlayersListQuery = ttaPlayersListQuery + '&team=' + homeTeamId;
            that.get('ajax').request(`/gameReportActions?game=${model.get('id')}${homePlayersListQuery}`).then(function(json) {
                const groupedByTeam = _.groupBy(json, 'team');
                if(groupedByTeam[homeTeamId]){
                    const homeCardsData = that.get('postMatchService').preparePlayerCardsList(groupedByTeam[homeTeamId]);
                    controller.set('homePlayerCardsData', homeCardsData);
                }
                if(groupedByTeam[awayTeamId]){
                    const awayCardsData = that.get('postMatchService').preparePlayerCardsList(groupedByTeam[awayTeamId]);
                    controller.set('awayPlayerCardsData', awayCardsData);
                }
                
            });
        });
    });
    
},*/