define('msa/controllers/search-players', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Controller.extend({

        ajax: _ember['default'].inject.service(),

        startAge: [20, 40],
        rangeAge: {
            'min': [10],
            'max': [50]
        },
        seasons: _ember['default'].A([{ name: 2015, startDate: "2015-01-01T00:00:00.000Z", endDate: "2015-12-31T00:00:00.000Z" }, { name: 2016, startDate: "2016-01-01T00:00:00.000Z", endDate: "2016-12-31T00:00:00.000Z" }]),
        startDate: "2016-01-01T00:00:00.000Z",
        endDate: "2016-12-31T00:00:00.000Z",
        selectedCountry: null,
        selectedCompetition: null,
        competitionDisabled: true,
        selectedTeam: null,
        teamDisabled: true,
        selectedPosition: null,
        positionDisabled: true,
        selectedAge: null,
        searchDisabled: true,
        ageChanged: false,
        minAge: 20,
        maxAge: 40,
        competitions: _ember['default'].A(),
        teams: _ember['default'].A(),
        leftColumnTtas: _ember['default'].A(),
        rightColumnTtas: _ember['default'].A(),
        changedTtas: [],
        playersCount: null,
        players: _ember['default'].A(),

        criterias: _ember['default'].A([{ name: 'Countries', contentNumber: 1 }, { name: 'Competition', contentNumber: 2 }, { name: 'Teams', contentNumber: 3 }, { name: 'Position', contentNumber: 4 }]),

        actions: {
            selectCountry: function selectCountry(country) {
                if (country.get('name').length > 6) {
                    _ember['default'].set(country, 'shortName', country.get('name').substr(0, 5) + '...');
                } else {
                    _ember['default'].set(country, 'shortName', country.get('name'));
                }
                var leftColumnTtas = this.get('leftColumnTtas');
                leftColumnTtas.clear();
                this.set('leftColumnTtas', leftColumnTtas);
                this.set('selectedCountry', country);
                this.set('competitionDisabled', false);
                this.set('selectedTeam', null);
                this.set('teamDisabled', true);
                this.set('selectedCompetition', null);
                this.set('selectedPosition', null);
                this.set('positionDisabled', true);
                this.set('searchDisabled', true);
                this.set('playersCount', null);
                this.send('getCompetitions');
            },
            selectTeam: function selectTeam(team) {
                if (team.name.length > 6) {
                    team.shortName = team.name.substr(0, 5) + '...';
                } else {
                    team.shortName = team.name;
                }
                this.set('selectedTeam', team);
                this.send('getAttributes');
                this.send('findCountPlayers');
            },
            selectPosition: function selectPosition(position) {
                if (position.get('name').length > 6) {
                    _ember['default'].set(position, 'shortName', position.get('name').substr(0, 5) + '...');
                } else {
                    _ember['default'].set(position, 'shortName', position.get('name'));
                }
                this.set('selectedPosition', position);
                this.send('getAttributes');
                this.send('findCountPlayers');
            },
            getCompetitions: function getCompetitions() {
                var that = this;
                var competitions = this.get('competitions');
                competitions.clear();
                var country = this.get('selectedCountry');
                this.get('ajax').request('/selectCompetition?country=' + country.id).then(function (competitionResults) {
                    var newCont = [];
                    traverse(competitionResults.team, newCont);
                    competitions.addObjects(newCont);
                    that.set('competitions', competitions);
                });
            },
            setCompetition: function setCompetition(model) {
                this.set('selectedCompetition', model);
                this.set('teamDisabled', false);
                this.set('positionDisabled', false);
                this.set('searchDisabled', false);
                this.set('selectedTeam', null);
                this.send('getTeams');
                this.send('getAttributes');
                this.send('findCountPlayers');
            },
            getTeams: function getTeams() {
                var that = this;
                var teams = this.get('teams');
                var competition = this.get('selectedCompetition');
                teams.clear();
                that.get('ajax').request('/team?competition=' + competition._id).then(function (teamResults) {
                    teams.addObjects(teamResults.team);
                    teams = teams.sortBy('name');
                    that.set('teams', teams);
                });
            },
            changeSeason: function changeSeason(m) {
                $('.season').removeClass('active');
                $('.season' + m.name).addClass('active');
                this.set('startDate', m.startDate);
                this.set('endDate', m.endDate);
                this.set('start', '');
                this.set('end', '');
                this.send('findCountPlayers');
            },

            findCustomDates: function findCustomDates() {
                $('.season').removeClass('active');
                var s = moment.utc(this.get('start')).format();
                var e = moment.utc(this.get('end')).format();
                if (!s || !e) return;
                this.set('startDate', s);
                this.set('endDate', e);
            },
            onSlideEnd: function onSlideEnd(value, modelId) {
                var changedTtas = this.get('changedTtas');
                var leftColumnTtas = this.get('leftColumnTtas');
                var rightColumnTtas = this.get('rightColumnTtas');
                changedTtas.forEach(function (item, index) {
                    if (item._id == modelId) {
                        changedTtas[index].min = value[0];
                        changedTtas[index].max = value[1];
                    }
                });
                leftColumnTtas.forEach(function (item, index) {
                    if (item._id == modelId) {
                        _ember['default'].set(leftColumnTtas[index], 'startValues', value);
                    }
                });
                rightColumnTtas.forEach(function (item, index) {
                    if (item._id == modelId) {
                        _ember['default'].set(rightColumnTtas[index], 'startValues', value);
                    }
                });
                this.set('changedTtas', changedTtas);
                this.set('leftColumnTtas', leftColumnTtas);
                this.set('rightColumnTtas', rightColumnTtas);
                this.send('findCountPlayers');
            },
            onAgeSlideEnd: function onAgeSlideEnd(value) {
                this.set('minAge', value[0]);
                this.set('maxAge', value[1]);
                this.set('ageChanged', true);
                this.send('findCountPlayers');
            },
            getAttributes: function getAttributes() {
                var that = this;
                var competition = this.get('selectedCompetition');
                var team = this.get('selectedTeam');
                var position = this.get('selectedPosition');
                var ttas = this.get('ttas');
                var leftColumnTtas = this.get('leftColumnTtas');
                var changedTtas = this.get('changedTtas');
                var startDate = this.get('startDate');
                var endDate = this.get('endDate');
                var start = this.get('start');
                var end = this.get('end');
                leftColumnTtas.clear();
                changedTtas.clear();
                var query = '/playerTTAsMinMax';
                var formStartDate = start ? moment.utc(start).format() : startDate;
                var formEndDate = end ? moment.utc(end).format() : endDate;
                if (competition) {
                    query += '?competition=' + competition._id;
                }
                if (team) {
                    query += '&team=' + team._id;
                }
                if (position) {
                    query += '&position=' + position.id;
                }
                if (formStartDate && formEndDate) {
                    query += '&startDate=' + formStartDate + '&endDate=' + formEndDate;
                }
                this.get('ajax').request(query).then(function (minMaxResults) {
                    if (minMaxResults.length > 0) {
                        (function () {
                            var cleanedResults = [];
                            minMaxResults.forEach(function (item) {
                                if (item.max_actions != item.min_actions) {
                                    var preparedTta = {};
                                    var fittedTta = _npmLodash['default'].find(ttas, { '_id': item._id });
                                    preparedTta.startValues = [item.min_actions, item.max_actions];
                                    preparedTta.rangeValues = { 'min': [item.min_actions], 'max': [item.max_actions] };
                                    preparedTta._id = fittedTta._id;
                                    preparedTta.plural = fittedTta.plural;
                                    var mean = (item.max_actions - item.min_actions) / 2;
                                    preparedTta.mean = Math.round(mean * 10) / 10;
                                    preparedTta.max = item.max_actions;
                                    preparedTta.min = item.min_actions;
                                    preparedTta.disabled = true;
                                    preparedTta.checked = false;
                                    cleanedResults.push(preparedTta);
                                }
                            });
                            leftColumnTtas.addObjects(cleanedResults);
                            leftColumnTtas = leftColumnTtas.sortBy('plural');
                            that.set('leftColumnTtas', leftColumnTtas);
                            that.set('changedTtas', changedTtas);
                        })();
                    }
                });
            },
            findCountPlayers: function findCountPlayers() {
                var that = this;
                var requestAddress = "/filterPlayers";
                var requestData = {};
                var competition = this.get('selectedCompetition');
                var team = this.get('selectedTeam');
                var position = this.get('selectedPosition');
                var changedTtas = this.get('changedTtas');
                var selectedAge = this.get('selectedAge');
                var startDate = this.get('startDate');
                var endDate = this.get('endDate');
                var start = this.get('start');
                var end = this.get('end');
                if (!competition) return;
                requestData.competition = competition._id;
                if (team) {
                    requestData.team = team._id;
                }
                if (position) {
                    requestData.position = position.id;
                }
                if (changedTtas.length > 0) {
                    requestData.playerTTAs = [];
                    changedTtas.forEach(function (item) {
                        requestData.playerTTAs.push({ playerTTA: item._id, $gte: item.min, $lte: item.max });
                    });
                }
                if (startDate && endDate) {
                    requestData.startDate = startDate;
                    requestData.endDate = endDate;
                }
                if (start && end) {
                    requestData.startDate = moment.utc(start).format();
                    requestData.endDate = moment.utc(end).format();
                }
                if (this.get('ageChanged')) {
                    var age = {};
                    age.$gte = this.get('minAge');
                    age.$lte = this.get('maxAge');
                    requestData.age = age;
                }
                requestData.count = true;
                this.get('ajax').request(requestAddress, {
                    method: 'POST',
                    data: JSON.stringify(requestData),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (countResult) {
                    that.set('playersCount', countResult);
                });
            },
            findPlayers: function findPlayers() {
                var that = this;
                var requestAddress = "/filterPlayers";
                var requestData = {};
                var competition = this.get('selectedCompetition');
                var team = this.get('selectedTeam');
                var position = this.get('selectedPosition');
                var changedTtas = this.get('changedTtas');
                var selectedAge = this.get('selectedAge');
                var startDate = this.get('startDate');
                var endDate = this.get('endDate');
                var start = this.get('start');
                var end = this.get('end');
                var players = this.get('players');
                var searchDisabled = this.get('searchDisabled');
                if (searchDisabled) return;
                players.clear();
                if (!competition) return;
                requestData.competition = competition._id;
                if (team) {
                    requestData.team = team._id;
                }
                if (position) {
                    requestData.position = position.id;
                }
                if (changedTtas.length > 0) {
                    requestData.playerTTAs = [];
                    changedTtas.forEach(function (item) {
                        requestData.playerTTAs.push({ playerTTA: item._id, $gte: item.min, $lte: item.max });
                    });
                }
                if (startDate && endDate) {
                    requestData.startDate = startDate;
                    requestData.endDate = endDate;
                }
                if (start && end) {
                    requestData.startDate = moment.utc(start).format();
                    requestData.endDate = moment.utc(end).format();
                }
                if (this.get('ageChanged')) {
                    var age = {};
                    age.$gte = this.get('minAge');
                    age.$lte = this.get('maxAge');
                    requestData.age = age;
                }
                this.get('ajax').request(requestAddress, {
                    method: 'POST',
                    data: JSON.stringify(requestData),
                    contentType: 'application/json',
                    dataType: 'json'
                }).then(function (playersResult) {
                    playersResult.forEach(function (item) {
                        if (item._id != null) {
                            var birthDate = item._id.date_birth ? moment(item._id.date_birth).format('L') : '-';
                            players.addObject({ _id: item._id._id, first_name: item._id.first_name, last_name: item._id.last_name, photo: item._id.photo, date_birth: birthDate });
                        }
                    });
                    that.set('players', players);
                });
            },
            changeCheck: function changeCheck(tta, type) {
                var leftColumnTtas = this.get('leftColumnTtas');
                var changedTtas = this.get('changedTtas');
                var leftElemIndex = leftColumnTtas.indexOf(tta);
                if (tta.checked === true) {
                    _ember['default'].set(leftColumnTtas[leftElemIndex], 'checked', false);
                    _ember['default'].set(leftColumnTtas[leftElemIndex], 'disabled', true);
                    _npmLodash['default'].remove(changedTtas, { _id: tta._id });
                } else {
                    _ember['default'].set(leftColumnTtas[leftElemIndex], 'checked', true);
                    _ember['default'].set(leftColumnTtas[leftElemIndex], 'disabled', false);
                    changedTtas.push({ _id: tta._id, min: tta.startValues[0], max: tta.startValues[1] });
                }
                this.set('leftColumnTtas', leftColumnTtas);
                this.set('changedTtas', changedTtas);
                this.send('findCountPlayers');
            },
            changeAge: function changeAge(value) {
                this.set('selectedAge', value);
                this.send('findCountPlayers');
            }
        }
    });

    function traverse(tree, container) {
        //console.log(container);
        tree.forEach(function (item, index) {
            if (item.child.length > 0) {
                var newItem = { _id: item._id, groupName: item.name };
                newItem.options = traverse(item.child, []);
                container.push(newItem);
            } else {
                container.push({ _id: item._id, name: item.name });
            }
        });
        return container;
    }
});