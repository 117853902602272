define('msa/components/possesions-field', ['exports', 'ember', 'npm:lodash'], function (exports, _ember, _npmLodash) {
    exports['default'] = _ember['default'].Component.extend({

        didInsertElement: function didInsertElement() {
            var action = this.get('action');
            var totalActions = action.awayTeamActions + action.homeTeamActions;
            var homeTeamPercents = action.homeTeamActions / totalActions * 100;
            homeTeamPercents = Math.round(homeTeamPercents * 10) / 10;
            var awayTeamPercents = action.awayTeamActions / totalActions * 100;
            awayTeamPercents = Math.round(awayTeamPercents * 10) / 10;
            var homeTeamWidth = 92.4 / 100 * homeTeamPercents;
            var awayTeamWidth = 92.4 / 100 * awayTeamPercents;
            var awayTeamOffset = homeTeamWidth;
            this.set('homeTeamWidth', homeTeamWidth);
            this.set('awayTeamWidth', awayTeamWidth);
            this.set('homeTeamPercents', homeTeamPercents);
            this.set('awayTeamPercents', awayTeamPercents);
            this.set('awayTeamOffset', awayTeamOffset);
            console.log('action', action);
            /* 
             100 - 92
             */
        }
    });
});