define('msa/components/player-map-tabs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    activeTab: 0,
    changeData: _ember['default'].observer('playerActionMaps', function () {
      var that = this;
      var playerActionMaps = this.get('playerActionMaps');
      if (!playerActionMaps) return;
      var heatmapInstance = this.get('heatmapInstance');
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (!heatmapInstance) {
          (function () {
            heatmapInstance = h337.create({
              opacity: 0.6,
              radius: 12,
              container: document.querySelector('.heatmap')
            });
            var points = [];
            var max = 0;
            var width = 408;
            var height = 271;
            playerActionMaps.forEach(function (item, index) {
              var point = {
                x: Math.floor(item.x * width),
                y: Math.floor(item.y * height),
                value: 20
              };
              points.push(point);
            });
            var data = {
              max: max,
              data: points
            };
            heatmapInstance.setData(data);
            that.set('heatmapInstance', heatmapInstance);
          })();
        } else {
          (function () {
            heatmapInstance.repaint();
            var points = [];
            var max = 0;
            var width = 408;
            var height = 271;
            playerActionMaps.forEach(function (item, index) {
              var point = {
                x: Math.floor(item.x * width),
                y: Math.floor(item.y * height),
                value: 20
              };
              points.push(point);
            });
            var data = {
              max: max,
              data: points
            };
            heatmapInstance.setData(data);
          })();
        }
      });
    }),

    didInsertElement: function didInsertElement() {
      var that = this;
      console.log('playerActionMaps', this.get('playerActionMaps'));
      var playerActionMaps = this.get('playerActionMaps');
      if (!playerActionMaps) return;
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var heatmapInstance = h337.create({
          opacity: 0.6,
          radius: 12,
          container: document.querySelector('.heatmap')
        });

        var points = [];
        var max = 0;
        var width = 408;
        var height = 271;
        playerActionMaps.forEach(function (item, index) {
          var point = {
            x: Math.floor(item.x * width),
            y: Math.floor(item.y * height),
            value: 20
          };
          points.push(point);
        });
        var data = {
          max: max,
          data: points
        };
        heatmapInstance.setData(data);
        that.set('heatmapInstance', heatmapInstance);
      });
    },

    actions: {
      changeTab: function changeTab(tabId) {
        console.log('tabId', tabId);
        if (tabId === 'firstPlayerActionTab') {
          $('#secondPlayerActionTab').hide();
          $('#firstPlayerActionTab').show();
          $('#firstPlayerActionPane').addClass('title_inner_action_tabs_box_active');
          $('#secondPlayerActionPane').removeClass('title_inner_action_tabs_box_active');
        } else {
          $('#firstPlayerActionTab').hide();
          $('#secondPlayerActionTab').show();
          $('#secondPlayerActionPane').addClass('title_inner_action_tabs_box_active');
          $('#firstPlayerActionPane').removeClass('title_inner_action_tabs_box_active');
        }
      },
      setTabActionMap: function setTabActionMap(model, index) {
        this.set('activeTab', index);
        this.sendAction('setActionMap', model);
      },
      activeTab: function activeTab(index) {
        this.set('activeTab', index);
      }
    }
  });
});