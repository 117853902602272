define('msa/controllers/teams/show/dynamics', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        ctrl: _ember['default'].inject.controller('teams.show'),
        startDate: _ember['default'].computed.alias('ctrl.startDate'),
        endDate: _ember['default'].computed.alias('ctrl.endDate'),
        ajax: _ember['default'].inject.service(),
        currentCompetition: _ember['default'].computed.alias('ctrl.currentCompetition'),
        playerPerformance: _ember['default'].observer('ttaToSearch.[]', function () {
            this.send('getsAttributes');
        }),
        actions: {
            addTta: function addTta(t) {
                var l = this.get('ttaToSearch');
                if (l.includes(t)) {
                    l.removeObject(t);
                    t.set('inQuery', false);
                } else {
                    l.addObject(t);
                    t.set('inQuery', true);
                }
                this.set('comparePerf', null);
                this.send('getsAttributes');
            },
            getsAttributes: function getsAttributes() {
                var arr = this.get('ttaToSearch').mapBy('id');
                var q = {
                    team: this.get("model.id"),
                    startDate: this.get('startDate'),
                    endDate: this.get('endDate')
                };
                var teamName = this.get('model.name');
                $('.squadLoading').removeClass('none');
                var that = this;
                //const qStr = `?team=${q.team}&startDate=${q.startDate}&endDate=${q.endDate}`
                var qStr = '?team=' + q.team + '&startDate=' + q.startDate + '&endDate=' + q.endDate + arr.map(function (val) {
                    return '&teamTTA=' + val;
                }).join('');

                that.get('ajax').request('/teamDynamics' + qStr).then(function (response) {

                    var graphics = response.data.map(function (oneGraph, index) {
                        var teamValues = [];
                        var teamSuccessValues = [];
                        var enemyValues = [];
                        var enemySuccessValues = [];
                        oneGraph.games.forEach(function (value) {
                            if (value.values && value.values.length > 0) {
                                if (value.values.length == 2) {
                                    value.values.forEach(function (oneTeam) {
                                        if (oneTeam.team._id == q.team) {
                                            teamValues.push(oneTeam.actions);
                                            teamSuccessValues.push(oneTeam.success_actions);
                                        } else {
                                            enemyValues.push(oneTeam.actions);
                                            enemySuccessValues.push(oneTeam.success_actions);
                                        }
                                    });
                                } else {
                                    var oneTeam = value.values[0];
                                    if (oneTeam.team._id == q.team) {
                                        teamValues.push(oneTeam.actions);
                                        teamSuccessValues.push(oneTeam.success_actions);
                                        enemyValues.push(0);
                                        enemySuccessValues.push(0);
                                    } else {
                                        enemyValues.push(oneTeam.actions);
                                        enemySuccessValues.push(oneTeam.actions);
                                        teamValues.push(0);
                                        teamSuccessValues.push(0);
                                    }
                                }
                            } else {
                                teamValues.push(0);
                                enemyValues.push(0);
                                teamSuccessValues.push(0);
                                enemySuccessValues.push(0);
                            }
                        });
                        return {
                            graphicIndex: index,
                            labels: oneGraph.games.map(function (value) {
                                return moment.utc(value.game.date).format('MM.DD.YY');
                            }),
                            teamValues: teamValues,
                            enemyValues: enemyValues,
                            teamSuccessValues: teamSuccessValues,
                            enemySuccessValues: enemySuccessValues,
                            has_won: oneGraph.teamTTA.has_won,
                            has_success: oneGraph.teamTTA.has_success,
                            tta: oneGraph.teamTTA.plural,
                            team: teamName
                        };
                    });
                    that.set('graphics', graphics);
                });
            }
        }
    });
});