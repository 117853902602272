define('msa/controllers/referees/show', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        ajax: _ember['default'].inject.service(),

        //следим за датами
        getCustomDates: _ember['default'].observer('endDate', function () {
            this.send('getStat');
        }),

        //следим за типами пасов
        getrightType: _ember['default'].observer('rightType', function () {}),

        rightType: 'Passes received',

        actions: {

            changeSeason: function changeSeason(m) {
                var that = this;
                this.set('currentSeason', m);
                this.set('start', false);
                this.set('end', false);
                this.set('startDate', m.startDate);
                this.set('endDate', m.endDate);
                $('.currentSeasonId').addClass('active');
            },

            findCustomDates: function findCustomDates() {
                var that = this;
                $('.currentSeasonId').removeClass('active');
                var s = moment(that.get('start')).format('YYYY-MM-DD[T]00:00:00');
                var e = moment(that.get('end')).format('YYYY-MM-DD[T]23:59:59');
                if (!s || !e) return;
                that.set('startDate', s);
                that.set('endDate', e);
            },
            getStat: function getStat() {
                $('.getStatLoading').removeClass('none');

                var that = this,
                    qo = {
                    referee: that.get('model.id'),
                    startDate: that.get('startDate'),
                    endDate: that.get('endDate')
                };

                if (!qo.referee || !qo.startDate || !qo.endDate) return;
                var q = '?referee=' + qo.referee + '&startDate=' + qo.startDate + '&endDate=' + qo.endDate;

                that.get('ajax').request('/refereeStatistics' + q).then(function (json) {
                    console.log(json);
                    that.set('statistic', json);
                    $('.getStatLoading').addClass('none');
                });
            }

        }
    });
});