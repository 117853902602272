define('msa/routes/login', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        session: _ember['default'].inject.service(),
        beforeModel: function beforeModel() {
            if (this.get("session.data.authenticated.access_token")) this.transitionTo('index');
        },
        actions: {
            alreadyLogged: function alreadyLogged() {
                that.transitionToRoute('players.show.overview', user.get('avatar'));
            },
            loggedNoAvatar: function loggedNoAvatar() {
                that.transitionToRoute('players.show.overview');
            }
        }
    });
});