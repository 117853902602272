define('msa/models/team-tta', ['exports', 'ember-data', 'msa/libs/type-shims'], function (exports, _emberData, _msaLibsTypeShims) {
	exports['default'] = _emberData['default'].Model.extend({
		singular: _msaLibsTypeShims.string,
		orderIndex: _msaLibsTypeShims.number,
		teamTTAGroup: _msaLibsTypeShims.string,
		plural: _msaLibsTypeShims.string,
		visible: _msaLibsTypeShims.boolean,
		parameter: _msaLibsTypeShims.boolean,
		episode: _msaLibsTypeShims.boolean
	});
});