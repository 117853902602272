define('msa/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax', 'msa/config/environment'], function (exports, _ember, _emberAjaxServicesAjax, _msaConfigEnvironment) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    host: _msaConfigEnvironment['default'].host + '/ember',
    session: _ember['default'].inject.service(),
    headers: _ember['default'].computed('session.authToken', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session.data.authenticated.access_token');
        if (authToken) {
          headers["x-access-token"] = authToken;
        }
        return headers;
      }
    })
  });
});